
import Vue from "vue";

export default Vue.extend({
  name: "HarvestAutocomplete",
  data() {
    return {
      localValue: undefined as number | undefined,
    };
  },
  props: {
    value: {
      required: true,
    },
    items: {
      type: [],
    },
    label: {
      type: String,
      default: "Safra*",
    },
    rules: {
      type: [],
    },
    color: {
      type: String,
      default: "primary",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.localValue = newValue;
      },
    },
  },
});
