import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface IUnit extends IModelBase {
  name: string;
  symbol: string;
}

export class UnitDTO extends ModelBase implements IUnit {
  name = "";
  symbol = "";

  constructor(dto?: IUnit) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.symbol = dto.symbol;
    }
  }
}

export default class Unit extends UnitDTO {
  constructor();
  constructor(unit: IUnit);
  constructor(unit?: IUnit) {
    super(unit);
  }
}
