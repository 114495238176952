import Vue from 'vue'
import Vuex from 'vuex'

import auth from "./modules/auth"
import crud from "./modules/crud"
import statics from "./modules/statics"
import searches from "./modules/searches"
import app from "./modules/app"
import calculations from "./modules/calculations"
import profit from "./modules/profit"
import tradeslip from "./modules/tradeslip"
import admin from "./modules/admin"
import analytics from "./modules/analytics"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    calculations,
    crud,
    searches,
    statics,
    profit,
    tradeslip,
    admin,
    analytics
  }
})
