import {
  SET_VALUE,
  SET_DOLLAR,
  SET_PRODUCTION_COST,
  SET_PRODUCTIVITY,
  SET_FOBBINGS,
  SET_LOGISTIC,
  SET_FLATPRICE,
  SET_EXPECTED_PRODUCTIVITY,
  SET_MARGIN,
  SET_CONSTANTS,
  SET_BASIS,
  SET_STUFFING,
  SET_GROWING,
  SET_PRODUCTIVITY_FIBER,
  SET_PRODUCTIVITY_SEED,
  SET_CATTLE_AMOUNT,
  SET_CATTLE_TOTAL_COST,
  SET_FLATPRICE_FOB,
  SET_FLATPRICE_EXW_CBOT,
  SET_FLATPRICE_EXW_B3,
  SET_BASIS_FOB,
  SET_BASIS_EXW_CBOT,
  SET_BASIS_EXW_B3,
  SET_CBOT,
  SET_NYBOT,
  SET_B3,
  SET_CARCASS_PRODUCTIVITY,
  SET_B3_CATLLE,
  SET_BASIS_CATTLE
} from "@/store/mutations-types";

import {
  UPDATE_STATE_SOY_CORN,
  UPDATE_STATE_COTTON,
  UPDATE_STATE_CATTLE,
  UPDATE_STATE_SPOT,
  EXTRACT_QUOTATIONS
} from "@/store/actions-types";

import { CalculationGetterTypes, ICalculationModule, IRootState } from "@/types/store-types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Constants } from "@/constants";
import Growing from "@/models/growing";
import constants from "@/constants";

// Shorthands for easier type building
type S = ICalculationModule;
type R = IRootState;

interface ISpotState {
  flatprice: number;
  basis: number;
  flatpriceFob: number;
  flatpriceExwCbot: number;
  flatpriceExwB3: number;
  basisFob: number;
  basisExwCbot: number;
  basisExwB3: number;
  dollar: number;
  cbot: number;
  nybot: number;
  b3: number;
  logistic: number;
  constants: Constants;
}

class State implements S {
  value = undefined;
  basis = undefined;
  dollar = undefined;
  constants = undefined;
  productionCost = undefined;
  productivity = undefined;
  logistic = undefined;
  flatprice = undefined;
  expectedProductivity = undefined;
  margin = undefined;
  // default = filled when soybean/corn selecte;
  fobbings = undefined;
  // filled when cotton selected
  stuffing = undefined;
  productivityFiber = undefined;
  productivitySeed = undefined;
  growingSelected = undefined;
  cbot = undefined;
  nybot = undefined;
  b3 = undefined;
  flatpriceFob = undefined;
  flatpriceExwCbot = undefined;
  flatpriceExwB3 = undefined;
  basisFob = undefined;
  basisExwCbot = undefined;
  basisExwB3 = undefined;

  // cattle stuff
  b3Cattle = undefined;
  basisCattle = undefined;
  cattleAmount = undefined;
  cattleTotalCost = undefined;
  carcassProductivity = undefined;
}

const mutations: MutationTree<S> = {
  [SET_VALUE]: (state, value: number) => {
    state.value = value;
  },
  [SET_DOLLAR]: (state, value: number) => {
    state.dollar = value;
  },
  [SET_PRODUCTION_COST]: (state, value: number) => {
    state.productionCost = value;
  },
  [SET_PRODUCTIVITY]: (state, value: number) => {
    state.productivity = value;
  },
  [SET_FOBBINGS]: (state, value: number) => {
    state.fobbings = value;
  },
  [SET_LOGISTIC]: (state, value: number) => {
    state.logistic = value;
  },
  [SET_FLATPRICE]: (state, value: number) => {
    state.flatprice = value;
  },
  [SET_EXPECTED_PRODUCTIVITY]: (state, value: number) => {
    state.expectedProductivity = value;
  },
  [SET_MARGIN]: (state, value: number) => {
    state.margin = value;
  },
  [SET_CONSTANTS]: (state, value: Constants) => {
    state.constants = value;
  },
  [SET_BASIS]: (state, value: number) => {
    state.basis = value;
  },
  [SET_GROWING]: (state, value: Growing) => {
    state.growingSelected = value;
  },
  [SET_STUFFING]: (state, value: number) => {
    state.stuffing = value;
  },
  [SET_PRODUCTIVITY_FIBER]: (state, value: number) => {
    state.productivityFiber = value;
  },
  [SET_PRODUCTIVITY_SEED]: (state, value: number) => {
    state.productivitySeed = value;
  },
  [SET_CATTLE_AMOUNT]: (state, value: number) => {
    state.cattleAmount = value;
  },
  [SET_CBOT]: (state, value: number) => {
    state.cbot = value;
  },
  [SET_NYBOT]: (state, value: number) => {
    state.nybot = value;
  },
  [SET_B3]: (state, value: number) => {
    state.b3 = value;
  },
  [SET_CATTLE_TOTAL_COST]: (state, value: number) => {
    state.cattleTotalCost = value;
  },
  [SET_FLATPRICE_FOB]: (state, value: number) => {
    state.flatpriceFob = value;
  },
  [SET_FLATPRICE_EXW_CBOT]: (state, value: number) => {
    state.flatpriceExwCbot = value;
  },
  [SET_FLATPRICE_EXW_B3]: (state, value: number) => {
    state.flatpriceExwB3 = value;
  },
  [SET_BASIS_FOB]: (state, value: number) => {
    state.basisFob = value;
  },
  [SET_BASIS_EXW_CBOT]: (state, value: number) => {
    state.basisExwCbot = value;
  },
  [SET_BASIS_EXW_B3]: (state, value: number) => {
    state.basisExwB3 = value;
  },
  [SET_CARCASS_PRODUCTIVITY]: (state, value: number) => {
    state.carcassProductivity = value;
  },
  [SET_B3_CATLLE]: (state, value: number) => {
    state.b3Cattle = value;
  },
  [SET_BASIS_CATTLE]: (state, value: number) => {
    state.basisCattle = value;
  },
};
const actions: ActionTree<S, R> = {
  [UPDATE_STATE_SOY_CORN]: ({ dispatch, commit, rootGetters }, growing: Growing) => {
    return new Promise<void>((resolve) => {
      const profitOwnership = rootGetters.getProfitOwnershipSelected;
      const pricing = rootGetters.getPrincing;
      const constant = constants.get(growing.symbol) || new Constants();

      commit(SET_PRODUCTION_COST, profitOwnership.production_cost);
      commit(SET_PRODUCTIVITY, profitOwnership.productivity);
      commit(SET_LOGISTIC, profitOwnership.logistic);
      commit(
        SET_EXPECTED_PRODUCTIVITY,
        profitOwnership.productivity
      );
      commit(SET_CONSTANTS, constant);
      commit(SET_FOBBINGS, profitOwnership.fobbings);
      commit(SET_FLATPRICE_FOB, pricing.cbot_flat_price_ppe);
      commit(SET_FLATPRICE_EXW_CBOT, pricing.cbot_flat_price_local_basis);
      commit(SET_FLATPRICE_EXW_B3, pricing?.b3_flat_price_local_basis);

      dispatch(EXTRACT_QUOTATIONS);

      resolve();
    });
  },
  [UPDATE_STATE_COTTON]: ({ dispatch, commit, rootGetters }) => {
    return new Promise<void>((resolve) => {
      const profitOwnership = rootGetters.getProfitOwnershipSelected;
      const pricing = rootGetters.getPrincing;
      const constant = constants.get('CT') || new Constants();

      commit(SET_PRODUCTION_COST, profitOwnership.production_cost);
      commit(SET_PRODUCTIVITY, profitOwnership.productivity);
      commit(SET_LOGISTIC, profitOwnership.logistic);
      commit(
        SET_EXPECTED_PRODUCTIVITY,
        profitOwnership.productivity
      );
      commit(SET_FLATPRICE_FOB, pricing.nybot_flat_price);
      commit(SET_CONSTANTS, constant);
      commit(SET_STUFFING, profitOwnership.stuffing);
      commit(SET_PRODUCTIVITY_FIBER, profitOwnership.productivity_fiber);
      commit(SET_PRODUCTIVITY_SEED, profitOwnership.productivity_seed);

      dispatch(EXTRACT_QUOTATIONS);

      resolve();
    });
  },
  [UPDATE_STATE_CATTLE]: ({ commit, rootGetters }) => {
    return new Promise<void>((resolve) => {
      const lotProfit = rootGetters.getProfitCattleLotSelected;
      const priceCattleLot = rootGetters.getProfitCattleLotPriceSelected;
      const quotations = rootGetters.getProQuotations;

      const b3_costs = priceCattleLot.b3_costs || 0;
      let basis = priceCattleLot.basis || 0;
      const basis_fit = priceCattleLot.basis_fit || 0;
      const bonus = priceCattleLot.bonus || 0;
      // verifica se é contrato a termo
      // se sim, pega o preço informado da b3
      const b3 = priceCattleLot.is_forward_contract ? priceCattleLot.b3_price : quotations.se.value;
      basis = b3_costs + basis + basis_fit + bonus;

      commit(SET_B3_CATLLE, b3);
      commit(SET_PRODUCTION_COST, lotProfit.arroba_total_cost);
      commit(SET_CARCASS_PRODUCTIVITY, lotProfit.carcass_weight);
      commit(SET_BASIS_CATTLE, basis);
      commit(SET_CATTLE_TOTAL_COST, lotProfit.lot_total_cost);
      commit(SET_CATTLE_AMOUNT, lotProfit.animal_amount);

      resolve();
    });
  },
  [UPDATE_STATE_SPOT]: ({ commit, dispatch }, payload) => {
    return new Promise<void>((resolve) => {
      commit(SET_CONSTANTS, payload.constants);
      commit(SET_GROWING, payload.growingSelected);
      commit(SET_LOGISTIC, payload?.ownership?.logistic);
      if(payload.nybot_flat_price) {
        commit(SET_FLATPRICE_FOB, payload.nybot_flat_price);
      }else {
        commit(SET_FLATPRICE_FOB, payload.cbot_flat_price_ppe);
        commit(SET_FLATPRICE_EXW_CBOT, payload.cbot_flat_price_local_basis);
        commit(SET_FLATPRICE_EXW_B3, payload?.b3_flat_price_local_basis);
      }
      dispatch(EXTRACT_QUOTATIONS);

      resolve();
    });
  },
  [EXTRACT_QUOTATIONS]: ({ rootState, commit }) => {
    if(Array.isArray(rootState.profit.quotations) && rootState.profit.quotations?.length) {
      rootState.profit.quotations.forEach((el) => {
        switch (el.identifier) {
          case "dollar":
            commit(SET_DOLLAR, el.value);
            break;
          case "cbot":
            commit(SET_CBOT, el.value);
            break;
          case "nybot":
            commit(SET_NYBOT, el.value);
            break;
          case "b3":
            commit(SET_B3, el.value);
            break;
          case "basis":
            commit(SET_BASIS, el.value);
            break;
          case "basisCbot":
            commit(SET_BASIS_EXW_CBOT, el.value);
            break;
          case "basisB3":
            commit(SET_BASIS_EXW_B3, el.value);
            break;
          case "basisFob":
            commit(SET_BASIS_FOB, el.value);
            break;

          default:
            break;
        }
      });
    }
  },
};
/* Mapping of our getters */
const getters: GetterTree<S, R> = {
  [CalculationGetterTypes.getCalculationState](state, getters, rootState) {
    const payload: S = {
      value: state.value,
      basis: state.basis,
      dollar: state.dollar,
      constants: state.constants,
      flatpriceFob: state.flatpriceFob || 0,
      flatpriceExwCbot: state.flatpriceExwCbot || 0,
      flatpriceExwB3: state.flatpriceExwB3 || 0,
      basisFob: state.basisFob || 0,
      basisExwCbot: state.basisExwCbot || 0,
      basisExwB3: state.basisExwB3 || 0,
      cbot: state.cbot || 0,
      nybot: state.nybot || 0,
      b3: state.b3 || 0,
      productionCost: state.productionCost,
      logistic: state.logistic,
      margin: state.margin,
    };
    if(state.growingSelected?.symbol === 'CT') {
      payload.flatprice = state.flatpriceFob || 0;
      payload.basis = state.basisFob || 0;
    }else {
      if(rootState.profit.pricingSelected === 'basisCbot'){
        payload.flatprice = state.flatpriceExwCbot || 0;
        payload.basis = state.basisExwCbot || 0;
      }else if(rootState.profit.pricingSelected === 'basisB3') {
        payload.flatprice = state.flatpriceExwB3 || 0;
        payload.basis = state.basisExwB3 || 0;
      }else {
        payload.flatprice = state.flatpriceFob || 0;
        payload.basis = state.basisFob || 0;
      }
    }
    if (state.productivity) {
      payload.productivity = state.productivity;
    } else {
      payload.productivity = state.expectedProductivity;
    }
    if (state.fobbings) {
      payload.fobbings = state.fobbings;
    }
    if (state.stuffing) {
      payload.stuffing = state.stuffing;
    }
    if (state.productivityFiber) {
      payload.productivityFiber = state.productivityFiber;
    }
    if (state.productivitySeed) {
      payload.productivitySeed = state.productivitySeed;
    }

    return payload;
  },
  [CalculationGetterTypes.getCalculationStateCattle](state) {
    const payload: S = {
      value: state.value,
      basis: state.basisCattle,
      b3: state.b3Cattle || 0,
      cattleAmount: state.cattleAmount,
      cattleTotalCost: state.cattleTotalCost,
      productionCost: state.productionCost,
      carcassProductivity: state.carcassProductivity
    };
    return payload;
  },
  [CalculationGetterTypes.getCalculationStateSPOT](state, getters, rootState) {
    const calcs: ISpotState = {
      flatprice: 0,
      basis: 0,
      flatpriceFob: state.flatpriceFob || 0,
      flatpriceExwCbot: state.flatpriceExwCbot || 0,
      flatpriceExwB3: state.flatpriceExwB3 || 0,
      basisFob: state.basisFob || 0,
      basisExwCbot: state.basisExwCbot || 0,
      basisExwB3: state.basisExwB3 || 0,
      dollar: state.dollar || 0,
      cbot: state.cbot || 0,
      nybot: state.nybot || 0,
      b3: state.b3 || 0,
      logistic: state.logistic || 0,
      constants: state.constants || new Constants({
        x: 0,
        t: 0,
        basis: "",
        price: "",
        months: new Map([["", ""]]),
      })
    };
    if(state.growingSelected?.symbol === 'CT') {
      calcs.flatprice = state.flatpriceFob || 0;
      calcs.basis = state.basisFob || 0;
    }else {
      if(rootState.profit.pricingSelected === 'basisCbot'){
        calcs.flatprice = state.flatpriceExwCbot || 0;
        calcs.basis = state.basisExwCbot || 0;
      }else if(rootState.profit.pricingSelected === 'basisB3') {
        calcs.flatprice = state.flatpriceExwB3 || 0;
        calcs.basis = state.basisExwB3 || 0;
      }else {
        calcs.flatprice = state.flatpriceFob || 0;
        calcs.basis = state.basisFob || 0;
      }
    }

    return calcs;
  }
};

export default {
  state: new State(),
  mutations,
  actions,
  getters,
};
