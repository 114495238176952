import Field from "@/models/field";
import Income from "@/models/income";
import { isEmptyObject } from "@/utilsObject";

import { OperationIncome } from "./OperationIncome";
import { Pricing } from "./Pricing";
import { Quotes } from "./Quotations";

export interface ISpot {
  id: number;
  name: string;
  deal_corn_reference: string;
  deal_cotton_reference: string;
  deal_soybean_reference: string;
  logistic: number;
  pricing: Pricing;
  op_income: OperationIncome;
  quotations: Array<Quotes>;
  fields: Array<Field>;
  incomes: Array<Income>;
}

export class SpotDTO implements ISpot {
  id = 0;
  name = "";
  deal_corn_reference = "09";
  deal_cotton_reference = "12";
  deal_soybean_reference = "03";
  logistic = 0;
  pricing = new Pricing();
  op_income = new OperationIncome();
  quotations: Quotes[] = [];
  fields: Field[] = [];
  incomes: Income[] = [];

  constructor(dto?: ISpot) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.deal_corn_reference = dto.deal_corn_reference;
      this.deal_cotton_reference = dto.deal_cotton_reference;
      this.deal_soybean_reference = dto.deal_soybean_reference;
      this.logistic = dto.logistic;
      this.pricing = new Pricing(dto.pricing);
      this.op_income = new OperationIncome(dto.op_income);
      this.quotations = dto.quotations.map(el => new Quotes(el));
      this.fields = dto.fields;
      this.incomes = dto.incomes;
    }
  }
}

export class Spot extends SpotDTO {
  constructor();
  constructor(spot: ISpot);
  constructor(spot?: ISpot) {
    super(spot);
  }
  /* getters */
  get emptyProfit(): boolean {
    return this.name ? false : true;
  }
}