import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface ICurrency extends IModelBase {
  name: string;
  symbol: string;
  identifier: string;
}

export class CurrencyDTO extends ModelBase implements ICurrency {
  name = "";
  symbol = "";
  identifier = "";

  constructor(dto?: ICurrency) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.symbol = dto.symbol;
      this.identifier = dto.identifier;
    }
  }
}

export default class Currency extends CurrencyDTO {
  constructor();
  constructor(currency: ICurrency);
  constructor(currency?: ICurrency) {
    super(currency);
  }
}
