import { RouteConfig } from "vue-router";
import utils from "./utils";

const admin: RouteConfig = {
  name: 'Admin',
  path: '/admin',
  component: () => import('@/views/Admin.vue'),
  children: [
    {
      name: 'Usuários N',
      path: 'users',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/admin/UserPicker.vue'),
      meta: {
        admin: true,
      },
    },
    {
      name: 'Cidades Basis',
      path: 'basis-cities',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/admin/BasisCities.vue'),
      meta: {
        admin: true,
      },
    },
    {
      name: 'Administração',
      path: 'sysmanager',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/admin/SysAdmin.vue'),
      meta: {
        admin: true,
      },
      children: [
        {
          name: 'Virada de safra',
          path: 'harvest-turn',
          beforeEnter: utils.checkAuth,
          component: () => import('@/components/admin/HarvestTurn.vue'),
          meta: {
            admin: true,
          },
        },
        {
          name: 'Safra',
          path: 'harvest',
          beforeEnter: utils.checkAuth,
          component: () => import('@/components/admin/Harvest.vue'),
          meta: {
            admin: true,
          },
        },
        {
          name: 'Cultura',
          path: 'growing',
          beforeEnter: utils.checkAuth,
          component: () => import('@/components/admin/Growing.vue'),
          meta: {
            admin: true,
          },
        },
      ]
    },
  ]
}

export default admin;