/*!

=========================================================
 * Vue Paper Dashboard - v2.0.0
=========================================================

 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard/blob/master/LICENSE.md)

=========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from './plugins/vuetify';
import store from './store';
import i18n from './plugins/i18n';
import VueGtag from "vue-gtag";
import GlobalFunctions from "./plugins/global-functions";

import './plugins/base';
import './plugins/common';
import './plugins/chartist';
import './plugins/vue-sequential-entrance';
import './plugins/luxon';
import './plugins/flagIcon';
import './plugins/currency-field';
import './plugins/toasted';
import './plugins/cookies'
import './plugins/vue-marquee'
import "./plugins/echarts";
import './plugins/vue-socketio';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import './registerServiceWorker';
import './filters';


// CONFIG GOOGLE ANALYTICS
// TODO: Get value from cookies
// Vue.use(VueGtag, {
//   config: { id: process.env.VUE_APP_ANALYTICS },
//   enabled: process.env.VUE_APP_ANALYTICS_ACTIVE,
// }, router);
/* install global functions */
Vue.use(GlobalFunctions)

/* eslint-disable no-new */
new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");



// create the Event Bus
export const eventBus = new Vue();