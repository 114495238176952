
import Vue from "vue";

export default Vue.extend({
  name: "CorfirmDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Deseja continuar?",
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirmed(): void {
      this.show = false;
      this.$emit('confirmed');
    }
  },
});
