import {
  SEARCH_CRUD,
  SIMPLE_SEARCH,
  SEARCH_BY_ID,
  RESET_SEARCH,
  MY_OWNERSHIPS,
  MY_LOTS,
  OWNERSHIP_VALID_CATTLE_LOT,
  OWNERSHIPS_CLIENT_PROFIT,
} from "@/store/actions-types";

import {
  SET_LOADING,
  SET_PAGE,
  SET_NUM_RESULTS,
  SET_PAGE_TOTAL,
  SET_RESULT,
} from "@/store/mutations-types";

import Ownership from "@/models/ownership";
import CattleLot from "@/models/cattleLot";
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IParams,
  IPayloadSearch,
  IRootState,
  ISearchModule,
  SearchGetterTypes,
} from "@/types/store-types";
import { arrayJsonToClass } from "@/utilsObject";
import { crudService } from "@/services/crud";

// Shorthands for easier type building
type S = ISearchModule;
type R = IRootState;

class State implements ISearchModule {
  loading = false;
  results: Array<unknown> = [];
  page = 0;
  num_results = 0;
  total_pages = 0;
}

const mutations: MutationTree<S> = {
  [SET_LOADING]: (state, value: boolean) => {
    state.loading = value;
  },
  [SET_RESULT]: <T>(state: S, value: Array<T>) => {
    state.results = value;
  },
  [SET_PAGE]: (state, value: number) => {
    state.page = value;
  },
  [SET_NUM_RESULTS]: (state, value: number) => {
    state.num_results = value;
  },
  [SET_PAGE_TOTAL]: (state, value: number) => {
    state.total_pages = value;
  },
};

const actions: ActionTree<S, R> = {
  [SEARCH_CRUD]: ({ commit, dispatch }, payload: IPayloadSearch) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(RESET_SEARCH);

      commit(SET_LOADING, true);
      payload.params = Object.assign({}, payload.params, { limit: 0 });
      crudService.getResource(payload.resource, payload.params)
        .then((response) => {
          commit(SET_RESULT, response);
          commit(SET_PAGE, response.page);
          commit(SET_NUM_RESULTS, response.num_results);
          commit(SET_PAGE_TOTAL, response.total_pages);

          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit(SET_LOADING, false);
        });
    });
  },
  [SIMPLE_SEARCH]: <T>(
    _: ActionContext<ISearchModule, IRootState>,
    payload: IPayloadSearch
  ) => {
    return new Promise<T[]>((resolve, reject) => {
      payload.params = Object.assign({}, payload.params, { limit: 0 });
      crudService.getSimpleResource<T>(payload.resource, payload.params)
        .then((response) => {
          resolve(response.objects);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEARCH_BY_ID]: <T>(
    _: ActionContext<ISearchModule, IRootState>,
    payload: IPayloadSearch
  ) => {
    return new Promise<T>((resolve, reject) => {
      if (payload.params.id)
        crudService.getResourceByID<T>(payload.resource, payload.params.id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  [RESET_SEARCH]: ({ state }) => {
    state.loading = false;
    state.results = [];
    state.page = 0;
    state.num_results = 0;
    state.total_pages = 0;
  },
  [MY_OWNERSHIPS]: () => {
    return new Promise<Ownership[]>((resolve, reject) => {
      const params: IParams = {
        my: true,
        checkDelete: true,
      };
      crudService.getSimpleResource<Ownership>("ownership", params)
        .then((response) => {
          resolve(arrayJsonToClass(Ownership, response.objects));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [OWNERSHIPS_CLIENT_PROFIT]: (_, client) => {
    return new Promise<Ownership[]>((resolve, reject) => {
      const params: IParams = {
        checkDelete: true,
      };
      if(client) {
        params.user_id = client.id;
      }else {
        params.my = true;
      }
      crudService.getSimpleResource<Ownership>("ownership", params)
        .then((response) => {
          let ownerships:Ownership[] = [];
          if(response.objects.length > 0) {
            ownerships = arrayJsonToClass(Ownership, response.objects);
          }
          resolve(ownerships);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [MY_LOTS]: (_, valid_lots = false) => {
    return new Promise<CattleLot[]>((resolve, reject) => {
      const params: IParams = {
        my: true,
        checkDelete: true,
        valid_lots: valid_lots,
      };
      crudService.getSimpleResource<CattleLot>("cattle_lot", params)
        .then((response) => {
          resolve(arrayJsonToClass(CattleLot, response.objects));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [OWNERSHIP_VALID_CATTLE_LOT]: (_, ownership_id) => {
    return new Promise<CattleLot[]>((resolve, reject) => {
      const params: IParams = {
        ownership_id: ownership_id,
        checkDelete: true,
        valid_lots: true,
      };
      crudService.getSimpleResource<CattleLot>("cattle_lot", params)
        .then((response) => {
          resolve(arrayJsonToClass(CattleLot, response.objects));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters: GetterTree<S, R> = {
  [SearchGetterTypes.getResults](state) {
    return state.results;
  },
  [SearchGetterTypes.getLoading](state) {
    return state.loading;
  },
  [SearchGetterTypes.getPage](state) {
    return state.page;
  },
  [SearchGetterTypes.getNumResults](state) {
    return state.num_results;
  },
  [SearchGetterTypes.getTotalPages](state) {
    return state.total_pages;
  },
};

export default {
  state: new State(),
  mutations,
  actions,
  getters,
};
