import {
  AUTH,
  CHECK_AUTH,
  LOGOUT,
  USER_OR_ANONYMOUS,
  LOAD_ANONYMOUS,
  LOAD_USER,
  UPDATE_TOKEN,
  SAVE_USER,
} from "@/store/actions-types";

import {
  SET_TOKEN,
  SET_BITZENTOKEN,
  SET_LOGGEDUSER,
} from "@/store/mutations-types";

import axios from "@/plugins/axios";
import router from "@/router";

import User, { UserDTO } from "@/models/user";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  AuthGetterTypes,
  IAuthModule,
  IRootState,
  IUserMeResponse,
} from "@/types/store-types";
import { userService } from "@/services/user";

// Shorthands for easier type building
type S = IAuthModule;
type R = IRootState;

class State implements IAuthModule {
  token = "";
  bitzenToken = "";
  loggedUser = new User(new UserDTO());
}

const mutations: MutationTree<S> = {
  [SET_TOKEN]: (state, token: string) => {
    state.token = token;
    localStorage.setItem("USER_TOKEN", token);
    axios.httpApi.defaults.headers.common["Authentication-Token"] = token;
  },
  [SET_LOGGEDUSER]: (state, user: UserDTO) => {
    state.loggedUser = new User(user);
    localStorage.setItem("USER", JSON.stringify(user));
  },
  [SET_BITZENTOKEN]: (state, token: string) => {
    state.bitzenToken = token;
    localStorage.setItem("BITZEN_TOKEN", JSON.stringify(token));
    console.log("Token GCM registrado para usuário atual");
  },
};

const actions: ActionTree<S, R> = {
  [AUTH]: ({ commit, dispatch }, token: string) => {
    return new Promise<void>((resolve, reject) => {
      if (token) {
        // Token com agrinvestauth user
        commit(SET_TOKEN, token);
        userService.registerUser(token)
          .then(() => {
            userService.getUserMe()
              .then((response: IUserMeResponse) => {
                // Token com intelsuporte user
                commit(SET_TOKEN, response.token);
                commit(SET_LOGGEDUSER, response.user);
                resolve();
              })
              .catch(() => {
                dispatch(LOGOUT);
                reject();
              });
          })
          .catch(() => {
            reject("Problema ao registrar o user");
          });
      } else {
        console.log("Autenticação sem token");
        window.location = process.env.VUE_APP_AUTH;
      }
    });
  },
  [UPDATE_TOKEN]: ({ commit, dispatch }) => {
    return new Promise<void>((resolve, reject) => {
      userService.getMeInternal()
        .then((response: IUserMeResponse) => {
          // Token com intelsuporte user
          commit(SET_TOKEN, response.token);
          commit(SET_LOGGEDUSER, response.user);
          resolve();
        })
        .catch(() => {
          dispatch(LOGOUT);
          reject();
        });
    });
  },
  [SAVE_USER]: (_, data: User) => {
    return new Promise<User>((resolve, reject) => {
      userService.saveUser(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  [LOGOUT]: ({ state }) => {
    state.loggedUser = new User(new UserDTO());
    state.token = "";
    localStorage.removeItem("USER_TOKEN"); // if the request fails, remove any possible user token if possible
    localStorage.removeItem("BITZEN_TOKEN");
    localStorage.removeItem("USER");
    router.push("/authenticate");
  },
  [CHECK_AUTH]: ({ state }) => {
    if (state.loggedUser && state.token) {
      return true;
    }
    const token = localStorage.getItem("USER_TOKEN") || "";
    const bitzenToken = localStorage.getItem("USER_TOKEN") || "";
    const user = localStorage.getItem("USER");
    if (token && user) {
      state.token = token;
      state.bitzenToken = bitzenToken;
      state.loggedUser = new User(JSON.parse(user));
      axios.httpApi.defaults.headers.common["Authentication-Token"] = token;
      return true;
    }
    return false;
  },
  [USER_OR_ANONYMOUS]: ({ state }) => {
    if (state.loggedUser && state.token) {
      return true;
    }
    const token = localStorage.getItem("USER_TOKEN") || "";
    const bitzenToken = localStorage.getItem("USER_TOKEN") || "";
    const user = localStorage.getItem("USER");
    if (token && user) {
      state.token = token;
      state.bitzenToken = bitzenToken;
      state.loggedUser = new User(JSON.parse(user));
      axios.httpApi.defaults.headers.common["Authentication-Token"] = token;
    } else {
      const aUser = new UserDTO();
      aUser.name = "Anônimo";
      aUser.isAnonymous = true;
      aUser.roles = [];
      state.loggedUser = new User(aUser);
      return false;
    }
    return true;
  },
  [LOAD_ANONYMOUS]: ({ state }) => {
    const aUser = new UserDTO();
    aUser.name = "Anônimo";
    aUser.isAnonymous = true;
    aUser.roles = [];
    state.loggedUser = new User(aUser);
    return;
  },
  [LOAD_USER]: ({ commit }) => {
    return new Promise<void>((resolve) => {
      userService.getMeInternal().then((response: IUserMeResponse) => {
        // Token com intelsuporte user
        commit(SET_TOKEN, response.token);
        commit(SET_LOGGEDUSER, response.user);

        resolve();
      });
    });
  },
};

/* Mapping of our getters */
const getters: GetterTree<S, R> = {
  [AuthGetterTypes.getLoggedUser](state) {
    return state.loggedUser;
  },
  [AuthGetterTypes.getLoggedUserRole](state) {
    return state.loggedUser.userRole;
  },
};

export default {
  state: new State(),
  mutations,
  actions,
  getters,
};
