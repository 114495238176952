import { RouteConfig } from "vue-router";
import utils from "./utils";

const crud: RouteConfig = {
  name: 'Crud',
  path: '/crud',
  component: () => import('@/views/Crud.vue'),
  children: [
    // CRUD
    {
      name: 'Fazendas',
      path: 'ownership',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/crud/Ownership.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      }
    },
    {
      name: 'Talhão',
      path: 'field',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/crud/Field.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      }
    },
    {
      name: 'Lote',
      path: 'cattle',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/crud/Cattle.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      }
    },
    {
      name: 'Receitas',
      path: 'income',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/crud/Income.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      }
    },
    // {
    //   name: 'Despesas',
    //   path: 'outgoing',
    //   beforeEnter: utils.checkAuth,
    //   component: () => import('@/components/crud/Outgoing.vue'),
    //   meta: {
    //     admin: true,
    //     broker: true,
    //     N2: true,
    //     N3: true
    //   }
    // },
    {
      name: 'Fretes',
      path: 'shipping',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/crud/Shipping.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      }
    },
    {
      name: 'Previsão Orçamentária',
      path: 'budget',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/crud/Budget.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      }
    },
  ]
}

export default crud;