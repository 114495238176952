import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface IOutgoingCategory extends IModelBase {
  name: string;
  growing_id: number;
}

export class OutgoingCategoryDTO extends ModelBase implements IOutgoingCategory {
  name = "";
  growing_id = 0;

  constructor(dto?: IOutgoingCategory) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.growing_id = dto.growing_id;
    }
  }
}

export class OutgoingCategory extends OutgoingCategoryDTO {
  constructor();
  constructor(outgoingCategory: IOutgoingCategory);
  constructor(outgoingCategory?: IOutgoingCategory) {
    super(outgoingCategory);
  }
}

export interface ISubOutgoingType extends IModelBase {
  name: string;
  outgoing_category_id: number;

  /**Relationship classes */
  outgoing_category?: OutgoingCategory;
}

export class SubOutgoingTypeDTO extends ModelBase implements ISubOutgoingType {
  name = "";
  outgoing_category_id = 0;

  outgoing_category = new OutgoingCategory();

  constructor(dto?: ISubOutgoingType) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.outgoing_category_id = dto.outgoing_category_id;

      if(dto.outgoing_category)
        this.outgoing_category = new OutgoingCategory(dto.outgoing_category);
    }
  }
}

export class SubOutgoingType extends SubOutgoingTypeDTO {
  constructor();
  constructor(subOutgoingType: ISubOutgoingType);
  constructor(subOutgoingType?: ISubOutgoingType) {
    super(subOutgoingType);
  }
}
