
import Vue from "vue";

export default Vue.extend({
  name: "App",

  created() {
    console.log("Versao:", this.$store.getters.appVersion);
  },
});
