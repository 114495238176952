import { RouteConfig } from "vue-router";
import utils from "./utils";

const tradeslip: RouteConfig = {
  name: 'TradeSlip',
  path: '/tradeslip',
  component: () => import('@/views/TradeSlip.vue'),
  children: [
    {
      name: 'Fornecedor',
      path: 'provider',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/tradeslip/Provider.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      },
    },
    {
      name: 'Silo',
      path: 'silo',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/tradeslip/Silo.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      },
    },
    {
      name: 'Conta Corretora',
      path: 'brokerage_account',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/tradeslip/BrokerageAccount.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      },
    },
    {
      name: 'Contrato',
      path: 'contract',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/tradeslip/Contract.vue'),
      meta: {
        admin: true,
        broker: true,
        N2: true,
        N3: true
      },
    }
  ]
}

export default tradeslip;