
import Growing from "@/models/growing";
import Vue from "vue";

export default Vue.extend({
  props: {
    selected: {
      type: [Growing, Object],
      default: new Growing(),
    },
    desableCard: {
      type: Boolean,
      default: false,
    },
    onlySoy: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Cultura",
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    notShowComingSoon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    soybeanSelected(): boolean {
      return this.selected.symbol === "S";
    },
    cornSelected(): boolean {
      return this.selected.symbol === "C";
    },
    cottonSelected(): boolean {
      return this.selected.symbol === "CT";
    },
    cattleSelected(): boolean {
      return this.selected.symbol === "BC";
    },
  },
});
