import Income from "@/models/income";
import { isEmptyObject, stringToNumber } from "@/utilsObject";
import { OperationIncome } from "./OperationIncome";
import { OperationMargin } from "./OperationMargin";
import { CattlePricing } from "./Pricing";
import { CattleQuotations } from "./Quotations";

export interface ILotInfoProfit {
  animal_amount: number;
  animal_total_cost: number;
  arroba_total_cost: number;
  carcass_weight: number;
  lot_total_cost: number;
  slaughter_date: string;
  slaughter_weight: number;
  yield_carcass: number;
}

export class LotInfoProfitDTO implements ILotInfoProfit {
  [keys: string]: number | string | boolean;

  animal_amount = 0;
  animal_total_cost = 0;
  arroba_total_cost = 0;
  carcass_weight = 0;
  lot_total_cost = 0;
  slaughter_date = "";
  slaughter_weight = 0;
  yield_carcass = 0;

  constructor(dto?: ILotInfoProfit) {
    if(dto && !isEmptyObject(dto)) {
      this.animal_amount = stringToNumber(dto.animal_amount);
      this.animal_total_cost = stringToNumber(dto.animal_total_cost);
      this.arroba_total_cost = stringToNumber(dto.arroba_total_cost);
      this.carcass_weight = stringToNumber(dto.carcass_weight);
      this.lot_total_cost = stringToNumber(dto.lot_total_cost);
      this.slaughter_date = dto.slaughter_date;
      this.slaughter_weight = stringToNumber(dto.slaughter_weight);
      this.yield_carcass = stringToNumber(dto.yield_carcass);
    }
  }
}

export class LotInfoProfit extends LotInfoProfitDTO {
  constructor();
  constructor(lotInfoProfit: ILotInfoProfit);
  constructor(lotInfoProfit?: ILotInfoProfit) {
    super(lotInfoProfit);
  }
}

export interface ILotProfit {
  id: number;
  name: string;
  lot_id: number
  price: CattlePricing;
  profit: LotInfoProfit;
  op_income: OperationIncome;
  op_margin: OperationMargin;
  incomes: Array<Income>
  quotations: CattleQuotations;
}

export class LotProfitDTO implements ILotProfit {
  id = 0;
  name = ""
  lot_id = 0;
  price = new CattlePricing();
  profit = new LotInfoProfit();
  op_income = new OperationIncome();
  op_margin = new OperationMargin();
  incomes = [] as Income[];
  quotations = new CattleQuotations();

  constructor(dto?: ILotProfit) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.lot_id = dto.lot_id;
      this.price = new CattlePricing(dto.price);
      this.profit = new LotInfoProfit(dto.profit);
      this.op_income = new OperationIncome(dto.op_income);
      this.op_margin = new OperationMargin(dto.op_margin);
      this.incomes = dto.incomes.map(el => new Income(el));
      this.quotations = new CattleQuotations(dto.quotations);
    }
  }
}

export class LotProfit extends LotProfitDTO {
  constructor();
  constructor(lotProfit: ILotProfit);
  constructor(lotProfit?: ILotProfit) {
    super(lotProfit);
  }
}

export interface ICattleProfit {
  id: number;
  name: string;
  lots: Array<LotProfit>;
  barQuotes: Array<CattleQuotations>
}

export class CattleProfitDTO implements ICattleProfit {
  id = 0;
  name = "";
  lots = [] as LotProfit[];
  barQuotes = [] as CattleQuotations[];

  constructor(dto?: ICattleProfit) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.lots = dto.lots.map(el => new LotProfit(el));
      this.barQuotes = dto.barQuotes.map(el => new CattleQuotations(el));
    }
  }
}

export class CattleProfit extends CattleProfitDTO {
  constructor();
  constructor(cattleProfit: ICattleProfit);
  constructor(cattleProfit?: ICattleProfit) {
    super(cattleProfit);
  }
}

export interface IBasisCattle {
  [keys: string]: string;
}