import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/plugins/i18n'
import '@/sass/overrides.sass'
import { organizationThemes } from "@/plugins/theme/agrinvest";

import ProMarketIcon from '@/components/Icons/ProMarketIcon.vue';
import Soybean from '@/components/Icons/SoybeanIcon.vue';
import CottonIcon from '@/components/Icons/CottonIcon.vue';
import FertilizerIcon from '@/components/Icons/FertilizerIcon.vue';
import CattleIcon from '@/components/Icons/CattleIcon.vue';
import CornIcon from '@/components/Icons/CornIcon.vue';
import AgrinvestIcon from '@/components/Icons/AgrinvestIcon.vue';
import AgrinvestColorIcon from '@/components/Icons/AgrinvestColorIcon.vue';
import AgrinvestFullColorIcon from '@/components/Icons/AgrinvestFullColorIcon.vue';

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: organizationThemes.agrinvest[0].light,
      dark: organizationThemes.agrinvest[0].dark
    },
    options: {
      customProperties: true,
    },
    dark: localStorage.getItem('theme') === 'dark',
  },
  icons: {
    iconfont: 'mdi',
    values: {
      promarket_icon: {
        component: ProMarketIcon
      },
      soybean_icon: {
        component: Soybean
      },
      cotton_icon: {
        component: CottonIcon
      },
      fertilizer_icon: {
        component: FertilizerIcon
      },
      cattle_icon: {
        component: CattleIcon
      },
      corn_icon: {
        component: CornIcon
      },
      agr_icon: {
        component: AgrinvestIcon
      },
      agr_color_icon: {
        component: AgrinvestColorIcon
      },
      agr_full_color_icon: {
        component: AgrinvestFullColorIcon
      }
    }
  }
})
