import SaleTrigger from "@/models/saleTrigger";
import axios from "@/plugins/axios";
import {
  IParams,
  ISearchResponse,
  ISearchResponseSimple,
} from "@/types/store-types";
import { isValidResponse, arrayJsonToClass, preperaSerializable } from "@/utilsObject";

interface ICommonProps {
  id?: number;
}

const save = async <T extends ICommonProps>(
  resource: string,
  data: T
): Promise<T> => {
  let response = undefined;
  if (data?.id) {
    response = await axios.httpApi.patch(`${resource}/${data.id}`, data);
    if (!isValidResponse(response.status))
      console.error(
        `Error to path resource ${resource}: ${response.statusText}`
      );
  } else {
    response = await axios.httpApi.post(resource, data);
    if (!isValidResponse(response.status))
      console.error(
        `Error to post resource ${resource}: ${response.statusText}`
      );
  }
  return response.data;
};

const saveClass = async <T extends ICommonProps>(
  model: new (...args: any) => T,
  resource: string,
  data: T
): Promise<T> => {
  let response = undefined;
  let method = "";
  data = preperaSerializable(data);
  if (data.id) {
    response = await axios.httpApi.patch(
      `${resource}/${data.id}`,
      data
    );
    method = "patch";
  } else {
    response = await axios.httpApi.post(resource, data);
    method = "post";
  }
  if (isValidResponse(response.status)) {
    return new model(response.data);
  }
  console.error(
    `Error to ${method} resource ${resource}: ${response.statusText
    }`
  );
  return response.data;
};

const patch = async <T extends ICommonProps>(
  model: new (...args: any) => T,
  resource: string,
  id: number,
  data: any
): Promise<T> => {
  const response = await axios.httpApi.patch(`${resource}/${id}`, data);
  if (isValidResponse(response.status))
    return new model(response.data);

  console.error(
    `Error to path resource ${resource}: ${response.statusText}`
  );
  return response.data;
};

const remove = async <T>(resource: string, id: number): Promise<T> => {
  const response = await axios.httpApi.delete(`${resource}/${id}`);
  if (!isValidResponse(response.status))
    console.error(`Error to path resource ${resource}: ${response.statusText}`);
  return response.data;
};

const deleteClass = async <T>(model: new (...args: any) => T, resource: string, id: number): Promise<T> => {
  const response = await axios.httpApi.delete(`${resource}/${id}`);
  if (isValidResponse(response.status)) {
    return new model(response.data);
  }
  console.error(`Error to delete resource ${resource}: ${response.statusText}`);
  return response.data;
};

const getResource = async <T>(
  resource: string,
  params: IParams
): Promise<ISearchResponse<T>> => {
  params.limit = 0;
  const response = await axios.httpApi.get(resource, { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get resource ${resource}: ${response.statusText}`);
  return response.data;
};

const getResourceClass = async <T>(
  model: new () => T,
  resource: string,
  params: IParams
): Promise<ISearchResponse<T>> => {
  params.limit = 0;
  const response = await axios.httpApi.get<ISearchResponse<T>>(resource, {
    params,
  });
  if (isValidResponse(response.status)) {
    response.data.objects = arrayJsonToClass(model, response.data.objects);
  } else {
    console.error(`Error to get resource ${resource}: ${response.statusText}`);
  }
  return response.data;
};

const getSimpleResourceClass = async <T>(
  model: new () => T,
  resource: string,
  params: IParams
): Promise<ISearchResponseSimple<T>> => {
  params.limit = 0;
  const response = await axios.httpApi.get<ISearchResponse<T>>(resource, {
    params,
  });
  if (isValidResponse(response.status)) {
    response.data.objects = arrayJsonToClass(model, response.data.objects);
  } else {
    console.error(`Error to get resource ${resource}: ${response.statusText}`);
  }
  return response.data;
};

const getSimpleResource = async <T>(
  resource: string,
  params: IParams
): Promise<ISearchResponseSimple<T>> => {
  params.limit = 0;
  const response = await axios.httpApi.get(resource, { params });
  if (!isValidResponse(response.status))
    console.error(
      `Error to get simple resource ${resource}: ${response.statusText}`
    );
  return response.data;
};

const getResourceByID = async <T>(resource: string, id: number): Promise<T> => {
  const response = await axios.httpApi.get(`${resource}/${id}`);
  if (!isValidResponse(response.status))
    console.error(
      `Error to get resource by id ${resource}: ${response.statusText}`
    );
  return response.data;
};

export const crudService = {
  save,
  saveClass,
  patch,
  remove,
  deleteClass,
  getResource,
  getResourceClass,
  getSimpleResourceClass,
  getSimpleResource,
  getResourceByID,
};
