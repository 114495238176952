import { isEmptyObject } from "@/utilsObject";
import { CattleSimpleProfit } from "./CattleSimpleProfit";
import { ProfitCosts } from "./Profit";
import { Quotes } from "./Quotations";
import { Spot } from "./Spot";
import { Pricing } from "./Pricing";


export interface IOwershipSimpleProfit {
  id: number;
  name: string;
  deal_corn_reference: string;
  deal_cotton_reference: string;
  deal_soybean_reference: string;
  profit: ProfitCosts;
  pricing: Pricing;
  quotations: Array<Quotes>;
}

export class OwershipSimpleProfitDTO implements IOwershipSimpleProfit {
  id = 0;
  name = "";
  deal_corn_reference = "09";
  deal_cotton_reference = "12";
  deal_soybean_reference = "03";
  profit = new ProfitCosts();
  pricing = new Pricing();
  quotations: Quotes[] = [];

  constructor(dto?: IOwershipSimpleProfit) {
    if (dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.deal_corn_reference = dto.deal_corn_reference;
      this.deal_cotton_reference = dto.deal_cotton_reference;
      this.deal_soybean_reference = dto.deal_soybean_reference;
      this.profit = new ProfitCosts(dto.profit);
      this.pricing = new Pricing(dto.pricing);
      this.quotations = dto.quotations.map(el => new Quotes(el));
    }
  }
}

export class OwershipSimpleProfit extends OwershipSimpleProfitDTO {
  constructor();
  constructor(profitOwnership: IOwershipSimpleProfit);
  constructor(profitOwnership?: IOwershipSimpleProfit) {
    super(profitOwnership);
  }
  /* getters */
  get emptyProfit(): boolean {
    return this.name ? false : true;
  }
}

export interface IGrainSimpleProfit {
  actual?: OwershipSimpleProfit;
  future?: OwershipSimpleProfit;
  spot?: Spot;
}

export class GrainSimpleProfitDTO implements IGrainSimpleProfit {
  actual = new OwershipSimpleProfit();
  future = new OwershipSimpleProfit();
  spot = new Spot();

  constructor(dto?: IGrainSimpleProfit) {
    if (dto && !isEmptyObject(dto)) {
      if (dto.actual)
        this.actual = new OwershipSimpleProfit(dto.actual);
      if (dto.future)
        this.future = new OwershipSimpleProfit(dto.future);
      if (dto.spot)
        this.spot = new Spot(dto.spot);
    }
  }
}

export class GrainSimpleProfit extends GrainSimpleProfitDTO {
  constructor();
  constructor(profitGrain: IGrainSimpleProfit);
  constructor(profitGrain?: IGrainSimpleProfit) {
    super(profitGrain);
  }
}

export interface ISimpleProfit {
  soybean?: GrainSimpleProfit;
  corn?: GrainSimpleProfit;
  cotton?: GrainSimpleProfit;
  cattle?: CattleSimpleProfit;
}

export class SimpleProfitDTO implements ISimpleProfit {
  soybean: undefined | GrainSimpleProfit = new GrainSimpleProfit();
  corn: undefined | GrainSimpleProfit = new GrainSimpleProfit();
  cotton: undefined | GrainSimpleProfit = new GrainSimpleProfit();
  cattle: undefined | CattleSimpleProfit = new CattleSimpleProfit();

  constructor(dto?: ISimpleProfit) {
    if (dto?.soybean) this.soybean = new GrainSimpleProfit(dto.soybean);
    if (dto?.corn) this.corn = new GrainSimpleProfit(dto.corn);
    if (dto?.cotton) this.cotton = new GrainSimpleProfit(dto.cotton);
    if (dto?.cattle)
      this.cattle = new CattleSimpleProfit(dto.cattle);
  }
}

export class SimpleProfit extends SimpleProfitDTO {
  constructor();
  constructor(profit: ISimpleProfit);
  constructor(profit?: ISimpleProfit) {
    super(profit);
  }
}
