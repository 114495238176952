import Budget from "@/models/budget";
import CottonConfigurations from "@/models/cottonConfigurations";
import Field from "@/models/field";
import Income from "@/models/income";

import { ShippingOwnership } from "@/models/shipping";
import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { CattleProfit } from "./CattleProfit";
import { OperationIncome } from "./OperationIncome";
import { OperationMargin } from "./OperationMargin";
import { Quotes } from "./Quotations";
import { Spot } from "./Spot";
import { Pricing } from "./Pricing";

export interface IProfitCosts {
  [keys: string]: number | CottonConfigurations | undefined;

  production_cost: number;
  productivity: number;
  total_area: number;
  logistic: number;
  profit_ownership_id: number;

  // Soybean and Corn
  fobbings?: number;
  exw_cost_rs_sc?: number;
  exw_cost_us_bs?: number;
  exw_cost_us_sc?: number;
  fob_price_rs_sc?: number;
  fob_price_us_bs?: number;
  fob_price_us_sc?: number;

  // Cotton
  cotton_config?: CottonConfigurations;
  exw_cost_rs_arroba?: number;
  exw_cost_us_arroba?: number;
  exw_cost_us_lb?: number;
  fob_price_rs_arroba?: number;
  fob_price_us_arroba?: number;
  fob_price_us_lb?: number;
  productivity_fiber?: number;
  productivity_seed?: number;
  stuffing?: number;
}

export class ProfitCostsDTO implements IProfitCosts {
  [keys: string]: number | CottonConfigurations | undefined;

  production_cost = 0;
  productivity = 0;
  total_area = 0;
  logistic = 0;
  profit_ownership_id = 0;
  flat_price = 0;

  // Soybean and Corn
  fobbings?: number | undefined = 0;
  exw_cost_rs_sc?: number | undefined = 0;
  exw_cost_us_bs?: number | undefined = 0;
  exw_cost_us_sc?: number | undefined = 0;
  fob_price_rs_sc?: number | undefined = 0;
  fob_price_us_bs?: number | undefined = 0;
  fob_price_us_sc?: number | undefined = 0;

  // Cotton
  cotton_config?: CottonConfigurations | undefined = new CottonConfigurations();
  exw_cost_rs_arroba?: number | undefined = 0;
  exw_cost_us_arroba?: number | undefined = 0;
  exw_cost_us_lb?: number | undefined = 0;
  fob_price_rs_arroba?: number | undefined = 0;
  fob_price_us_arroba?: number | undefined = 0;
  fob_price_us_lb?: number | undefined = 0;
  productivity_fiber?: number | undefined = 0;
  productivity_seed?: number | undefined = 0;
  stuffing?: number | undefined = 0;

  constructor(dto?: IProfitCosts) {
    if (dto && !isEmptyObject(dto)) {
      this.production_cost = stringToNumber(dto.production_cost);
      this.productivity = stringToNumber(dto.productivity);
      this.total_area = stringToNumber(dto.total_area);
      this.logistic = stringToNumber(dto.logistic);
      this.profit_ownership_id = stringToNumber(dto.profit_ownership_id);

      this.fobbings = stringToNumberOrUndefined(dto.fobbings);
      this.exw_cost_rs_sc = stringToNumberOrUndefined(dto.exw_cost_rs_sc);
      this.exw_cost_us_bs = stringToNumberOrUndefined(dto.exw_cost_us_bs);
      this.exw_cost_us_sc = stringToNumberOrUndefined(dto.exw_cost_us_sc);
      this.fob_price_rs_sc = stringToNumberOrUndefined(dto.fob_price_rs_sc);
      this.fob_price_us_bs = stringToNumberOrUndefined(dto.fob_price_us_bs);
      this.fob_price_us_sc = stringToNumberOrUndefined(dto.fob_price_us_sc);

      this.exw_cost_rs_arroba = stringToNumberOrUndefined(dto.exw_cost_rs_arroba);
      this.exw_cost_us_arroba = stringToNumberOrUndefined(dto.exw_cost_us_arroba);
      this.exw_cost_us_lb = stringToNumberOrUndefined(dto.exw_cost_us_lb);
      this.fob_price_rs_arroba = stringToNumberOrUndefined(dto.fob_price_rs_arroba);
      this.fob_price_us_arroba = stringToNumberOrUndefined(dto.fob_price_us_arroba);
      this.fob_price_us_lb = stringToNumberOrUndefined(dto.fob_price_us_lb);
      this.productivity_fiber = stringToNumberOrUndefined(dto.productivity_fiber);
      this.productivity_seed = stringToNumberOrUndefined(dto.productivity_seed);
      this.stuffing = stringToNumberOrUndefined(dto.stuffing);
      if (dto.cotton_config)
        this.cotton_config = new CottonConfigurations(dto.cotton_config);
    }
  }
}

export class ProfitCosts extends ProfitCostsDTO {
  constructor();
  constructor(profitCosts: IProfitCosts);
  constructor(profitCosts?: IProfitCosts) {
    super(profitCosts);
  }
}

export interface IOwnershipProfit {
  id: number;
  name: string;
  deal_corn_reference: string;
  deal_cotton_reference: string;
  deal_soybean_reference: string;
  profit: ProfitCosts;
  pricing: Pricing;
  op_margin: OperationMargin;
  op_income: OperationIncome;
  quotations: Array<Quotes>;
  budgets: Array<Budget>;
  fields: Array<Field>;
  incomes: Array<Income>;
  shippings: Array<ShippingOwnership>;
}

export class OwnershipProfitDTO implements IOwnershipProfit {
  id = 0;
  name = "";
  deal_corn_reference = "09";
  deal_cotton_reference = "12";
  deal_soybean_reference = "03";
  profit = new ProfitCosts();
  pricing = new Pricing();
  op_margin = new OperationMargin();
  op_income = new OperationIncome();
  quotations: Quotes[] = [];
  budgets: Budget[] = [];
  fields: Field[] = [];
  incomes: Income[] = [];
  shippings: ShippingOwnership[] = [];

  constructor(dto?: IOwnershipProfit) {
    if (dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.deal_corn_reference = dto.deal_corn_reference;
      this.deal_cotton_reference = dto.deal_cotton_reference;
      this.deal_soybean_reference = dto.deal_soybean_reference;
      this.profit = new ProfitCosts(dto.profit);
      this.pricing = new Pricing(dto.pricing);
      this.op_margin = new OperationMargin(dto.op_margin);
      this.op_income = new OperationIncome(dto.op_income);
      this.quotations = dto.quotations.map(el => new Quotes(el));
      this.budgets = dto.budgets;
      this.fields = dto.fields;
      this.incomes = dto.incomes;
      this.shippings = dto.shippings;
    }
  }
}

export class OwnershipProfit extends OwnershipProfitDTO {
  constructor();
  constructor(profitOwnership: IOwnershipProfit);
  constructor(profitOwnership?: IOwnershipProfit) {
    super(profitOwnership);
  }
  /* getters */
  get emptyProfit(): boolean {
    return this.name ? false : true;
  }
}

export interface IGrainProfit {
  actual?: {
    ownerships: Array<OwnershipProfit>;
  };
  future?: {
    ownerships: Array<OwnershipProfit>;
  };
  spot?: {
    ownerships: Array<Spot>;
  };
}

export class GrainProfitDTO implements IGrainProfit {
  actual = {
    ownerships: [] as OwnershipProfit[],
  };
  future = {
    ownerships: [] as OwnershipProfit[],
  };
  spot = {
    ownerships: [] as Spot[],
  };

  constructor(dto?: IGrainProfit) {
    if (dto && !isEmptyObject(dto)) {
      if (dto.actual)
        this.actual.ownerships = dto.actual.ownerships.map(
          (el) => new OwnershipProfit(el)
        );
      if (dto.future)
        this.future.ownerships = dto.future.ownerships.map(
          (el) => new OwnershipProfit(el)
        );
      if (dto.spot)
        this.spot.ownerships = dto.spot.ownerships.map((el) => new Spot(el));
    }
  }
}

export class GrainProfit extends GrainProfitDTO {
  constructor();
  constructor(profitGrain: IGrainProfit);
  constructor(profitGrain?: IGrainProfit) {
    super(profitGrain);
  }
}

export interface IProfit {
  soybean?: GrainProfit;
  corn?: GrainProfit;
  cotton?: GrainProfit;
  cattle?: {
    ownerships: Array<CattleProfit>;
  };
}

export class ProfitDTO implements IProfit {
  soybean: undefined | GrainProfit = undefined;
  corn: undefined | GrainProfit = undefined;
  cotton: undefined | GrainProfit = undefined;
  cattle: undefined | { ownerships: CattleProfit[] } = undefined;

  constructor(dto?: IProfit) {
    if (dto?.soybean && !isEmptyObject(dto)) this.soybean = new GrainProfit(dto.soybean);
    if (dto?.corn && !isEmptyObject(dto)) this.corn = new GrainProfit(dto.corn);
    if (dto?.cotton && !isEmptyObject(dto)) this.cotton = new GrainProfit(dto.cotton);
    if (dto?.cattle && !isEmptyObject(dto))
      this.cattle = {
        ownerships: dto.cattle.ownerships.map((el) => new CattleProfit(el)),
      };
  }
}

export class Profit extends ProfitDTO {
  constructor();
  constructor(profit: IProfit);
  constructor(profit?: IProfit) {
    super(profit);
  }
}
