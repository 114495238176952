import axios, { AxiosInstance } from "axios";

const httpApi = axios.create({ baseURL: process.env.VUE_APP_API });
const httpAuth = axios.create({ baseURL: process.env.VUE_APP_AUTH });
const httpReport = axios.create({ baseURL: process.env.VUE_APP_REPORT });

function axiosBase(url: string): AxiosInstance {
  return axios.create({ baseURL: url });
}

httpApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status == 401) {
      window.location = process.env.VUE_APP_AUTH;
      return Promise.reject(error);
    } else if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.warn("Api Error");
      console.error("Status", error.response.status);
      return Promise.reject(error);
    }else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.warn("Network Error or API couldn't responde in time");
      console.error(error.request);
      return Promise.reject(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
      return Promise.reject(error);
    }
  }
);

export default {
  httpApi,
  httpAuth,
  httpReport,
  axiosBase,
};
