import store from "./store";
import CottonConfigurations from "./models/cottonConfigurations";
import Currency from "./models/currency";
import { Quotes } from "./business/Quotations";
import constants from "@/constants";

import {
  OperationIncome,
} from "./business/OperationIncome";
import {
  OwnershipProfit,
  OwnershipProfitCotton,
} from "./business/OwnershipProfit";
import { Constants } from "./constants";
import { bushelToSack, sackToBushel } from "./utilsUnit";
import { OperationMargin } from "./business/OperationMargin";
import { LotInfoProfit } from "./business/CattleProfit";
import Harvest from "./models/harvest";

export default {
  // ######################################
  // Calculos utilizados na rentabilidade
  // ######################################
  biggerPrice(
    ppe: number,
    localBasisCbot: number,
    localBasisB3: number | undefined = undefined
  ): string | undefined {
    if (localBasisB3) {
      const bigger = Math.max(ppe, localBasisCbot, localBasisB3);
      if (bigger === ppe) return "ppe";
      if (bigger === localBasisCbot) return "basisCbot";
      if (bigger === localBasisB3) return "basisB3";
    }
    const bigger = Math.max(ppe, localBasisCbot);
    if (bigger === ppe) return "ppe";
    if (bigger === localBasisCbot) return "basisCbot";

    return undefined;
  },
  exwDollarBU(
    productionCost: number,
    productivity: number,
    constants: Constants,
    dollar: number
  ): number {
    return productionCost / productivity / dollar / constants.x;
  },
  fobExwDollarBU(
    exw: number,
    constants: Constants,
    dollar: number,
    fobbings: number,
    logistic: number
  ): number {
    return exw + (logistic / dollar + fobbings) / constants.t;
  },
  // Função utilizada na matriz de retabilidade
  rentability(flatprice: number, productivity: number, formatter: string): number {
    const calc = store.getters.getCalculationState;
    const ownerProfit = store.getters.getProfitOwnershipSelected;
    const pricingSelected = store.getters.getPrincingSelected;
    const constants = calc.constants as Constants;

    // Must be diminished for every pricing
    let costs = 0;
    const exw = this.exwDollarBU(
      ownerProfit.production_cost,
      productivity,
      constants,
      calc.dollar
    );
    if(pricingSelected === 'ppe') {
      const fob = this.fobExwDollarBU(
        exw,
        constants,
        calc.dollar,
        ownerProfit.fobbings,
        ownerProfit.logistic
      );
      flatprice = flatprice - fob;
    } else {
      flatprice = flatprice - exw;
    }

    if (formatter === "_percent") {
      return (flatprice / exw) * 100;
    } else if (formatter === "_real") {
      return flatprice * productivity * constants.x * calc.dollar;
    } else if (formatter === "_dolar") {
      return flatprice * productivity * constants.x;
    } else return 0;
  },
  rentabilityB3(flatprice: number, productivity: number, formatter: string): number {
    const calc = store.getters.getCalculationState;
    if (formatter === "_percent") {
      return (flatprice / (calc.productionCost / productivity)) * 100;
    } else if (formatter === "_real") {
      return (flatprice  * productivity) - calc.productionCost;
    } else if (formatter === "_dolar") {
      return ((flatprice  * productivity) - calc.productionCost) / calc.dollar;
    } else return 0;
  },
  // Calcula a projeção do dolar
  // Quanto falta o dolar mexer para atingir o gatilho
  saleTriggerDollar(margin: number, flatprice: number): number {
    const calc = store.getters.getCalculationState;
    const rsPrice = (margin + calc.productionCost) / calc.productivity + calc.logistic * 0.06;
    const usPrice = flatprice * calc.constants.x - calc.fobbings * 0.06;
    const dolFuture = rsPrice / usPrice;
    return dolFuture - calc.dollar;
  },
  saleTriggerDollarBasisExw(margin: number, flatprice: number): number {
    const calc = store.getters.getCalculationState;
    const rsPrice = (margin + calc.productionCost) / calc.productivity;
    const usPrice = flatprice * calc.constants.x;
    const dolFuture = rsPrice / usPrice;
    return dolFuture - calc.dollar;
  },
  // Calcula a prjeção do PPE
  // Quanto falta o FLATPRICE mexer para atingir o gatilho
  saleTriggerPPE(margin: number, exwRSsc: number): number {
    const calc = store.getters.getCalculationState;
    let priceSackMargin = (margin + calc.productionCost) / calc.productivity;
    priceSackMargin = priceSackMargin - exwRSsc;
    priceSackMargin = priceSackMargin / calc.dollar / calc.constants.x;
    return priceSackMargin;
  },
  saleTriggerFlatPriceSPOT(
    price: number,
    flatpriceNow: number,
    basis: number,
    dollar: number,
    constants: Constants,
    logistic: number,
    pricing: string
  ): number {
    // basis para R$/sc
    // (((pricing + (logistic * 0,06)) / dollar) / constant.x) - flatpriceact
    if (pricing === "ppe")
      return (price + logistic * 0.06) / dollar / constants.x - flatpriceNow;

    const basisSc = basis * constants.x * dollar;
    // Soma basis no preço alvo e transforma em $/bu
    const flatprice = (price + basisSc) / dollar / constants.x;
    // flat price alvo menos atual
    return flatprice - flatpriceNow;
  },
  saleTriggerFlatPriceCottonSPOT(
    price: number,
    flatpriceNow: number,
    basis: number,
    dollar: number,
    constants: Constants,
    logistic: number
  ): number {
    // logistic para arroba
    // soma com o preço, convert para dollar e depois para ¢/lb
    // no final diminui do flatprice atual
    return (price + logistic * 0.015) / dollar / constants.t - flatpriceNow;
  },
  saleTriggerB3BasisExw(
    margin: number,
    productionCost: number,
    productivity: number,
    sackValueNow: number,
  ): number {
    return ((margin + productionCost) / productivity) - sackValueNow;
  },
  saleTriggerFlatPriceSPOTB3(
    price: number,
    flatprice: number,
    basis: number
  ): number {
    return price + basis - (flatprice + basis);
  },
  saleTriggerDollarSPOT(
    price: number,
    flatprice: number,
    dollar: number,
    constants: Constants,
    logistic: number,
    pricing: string
  ): number {
    const usPrice = flatprice * constants.x;
    let rsPrice = price;
    if (pricing === "ppe") rsPrice = rsPrice + logistic * 0.06;
    const dollarResult = rsPrice / usPrice;
    return dollarResult - dollar;
  },
  saleTriggerDollarCottonSPOT(
    price: number,
    flatprice: number,
    dollar: number,
    constants: Constants,
    logistic: number
  ): number {
    const usPrice = flatprice * constants.t;
    const rsPrice = price + logistic * 0.015;
    const dollarResult = rsPrice / usPrice;
    return dollarResult - dollar;
  },
  // Recalcula os valores dentro da rentabilidade da fazenda
  // Quando o usuário aperta insere um valor na tela
  reCalcOwnershipCostLog(
    profitOwnershipSelected: OwnershipProfit,
    constants: Constants,
    dollar: number
  ): OwnershipProfit {
    const exw_cost =
      profitOwnershipSelected.production_cost /
      profitOwnershipSelected.productivity;
    const exw_cost_us = exw_cost / dollar;
    const exw_us_bs = sackToBushel(exw_cost_us, constants);

    const fob_price_us_bs =
      exw_us_bs +
      (profitOwnershipSelected.logistic / dollar +
        profitOwnershipSelected.fobbings) /
      constants.t;
    const fob_price_us_sc = bushelToSack(fob_price_us_bs, constants);
    const fob_price_rs_sc = fob_price_us_sc * dollar;

    profitOwnershipSelected.exw_cost_rs_sc = exw_cost;
    profitOwnershipSelected.exw_cost_us_sc = exw_cost_us;
    profitOwnershipSelected.exw_cost_us_bs = exw_us_bs;

    profitOwnershipSelected.fob_price_us_bs = fob_price_us_bs;
    profitOwnershipSelected.fob_price_us_sc = fob_price_us_sc;
    profitOwnershipSelected.fob_price_rs_sc = fob_price_rs_sc;

    return profitOwnershipSelected;
  },
  // Recalcula os valores dentro da receita operacional
  // Quando o usuário aperta insere um valor na tela
  reCalcOperacionalIncome(
    profitIncomeSelected: OperationIncome,
    profitOwnershipSelected: OwnershipProfit
  ): OperationIncome {
    if (profitIncomeSelected.sold_amount) {
      const areaProd =
        profitOwnershipSelected.productivity * profitOwnershipSelected.total_area;
      // Recalcula a linha do Saldo
      profitIncomeSelected.statement_amount =
        areaProd - profitIncomeSelected.sold_amount;
      profitIncomeSelected.statement_sc_rs = profitOwnershipSelected.ppe_ex_rs_sc;
      profitIncomeSelected.statement_sc_us = profitOwnershipSelected.ppe_ex_us_sc;
      profitIncomeSelected.statement_value_rs =
        profitIncomeSelected.statement_amount *
        profitOwnershipSelected.ppe_ex_rs_sc;
      profitIncomeSelected.statement_value_us =
        profitIncomeSelected.statement_amount *
        profitOwnershipSelected.ppe_ex_us_sc;
      // Recalcula a linha do Faturamento
      profitIncomeSelected.revenue_amount =
        profitIncomeSelected.sold_amount + profitIncomeSelected.statement_amount;
      profitIncomeSelected.revenue_value_rs =
        profitIncomeSelected.sold_value_rs ?
          profitIncomeSelected.sold_value_rs +
          profitIncomeSelected.statement_value_rs :
          0 + profitIncomeSelected.statement_value_rs;
      profitIncomeSelected.revenue_value_us =
        profitIncomeSelected.sold_value_us ?
          profitIncomeSelected.sold_value_us +
          profitIncomeSelected.statement_value_us :
          0 + profitIncomeSelected.statement_value_us;
      profitIncomeSelected.revenue_sc_rs =
        profitIncomeSelected.revenue_value_rs /
        profitIncomeSelected.revenue_amount;
      profitIncomeSelected.revenue_sc_us =
        profitIncomeSelected.revenue_value_us /
        profitIncomeSelected.revenue_amount;
      // Recalcula porcentagem
      profitIncomeSelected.sold_pcent =
        (profitIncomeSelected.sold_amount / profitIncomeSelected.revenue_amount) *
        100;
      profitIncomeSelected.statement_pcent =
        (profitIncomeSelected.statement_amount /
          profitIncomeSelected.revenue_amount) *
        100;
    }

    return profitIncomeSelected;
  },
  // Recalcula os valores dentro da margem operacional
  // Quando o usuário aperta insere um valor na tela
  reCalcOperacionalMargin(
    profitMarginSelected: OperationMargin,
    profitIncomeSelected: OperationIncome,
    profitOwnershipSelected: OwnershipProfit,
    dollar: number
  ): OperationMargin {
    // Recalcula o custo total
    // e a receita bruta
    const cost_rs =
      profitOwnershipSelected.production_cost *
      profitOwnershipSelected.total_area;
    const cost_us = cost_rs / dollar;
    const revenue_amount_rs =
      profitIncomeSelected.revenue_value_rs ?
        profitIncomeSelected.revenue_value_rs - cost_rs :
        0 - cost_rs;
    const revenue_amount_us =
      profitIncomeSelected.revenue_value_us ?
        profitIncomeSelected.revenue_value_us - cost_us :
        0 - cost_us;
    const revenue_amount_rs_ha =
      revenue_amount_rs / profitOwnershipSelected.total_area;
    const revenue_amount_us_ha =
      revenue_amount_us / profitOwnershipSelected.total_area;
    // Recalcula a receita bruta por ha
    // e o ROI
    profitMarginSelected.revenue_amount_rs = revenue_amount_rs;
    profitMarginSelected.revenue_amount_us = revenue_amount_us;
    profitMarginSelected.revenue_amount_rs_ha = revenue_amount_rs_ha;
    profitMarginSelected.revenue_amount_us_ha = revenue_amount_us_ha;
    profitMarginSelected.op_roi_rs =
      cost_rs > 0 ? (revenue_amount_rs / cost_rs) * 100 : 0;
    profitMarginSelected.op_roi_us =
      cost_us > 0 ? (revenue_amount_us / cost_us) * 100 : 0;

    profitMarginSelected.total_cust_rs = cost_rs;
    profitMarginSelected.total_cust_us = cost_us;
    profitMarginSelected.revenue_amount_rs = revenue_amount_rs;
    profitMarginSelected.revenue_amount_us = revenue_amount_us;

    return profitMarginSelected;
  },
  // Calcula a margim para os gatilhos em porcentagem
  triggersMargin(margin: number, productivity: number, costs: number): number {
    return parseFloat(((margin / productivity / costs) * 100).toFixed(2));
  },
  ppeRealSack(
    flatprice: number,
    t: number,
    logistic: number,
    fobbings: number,
    dollar: number
  ): number {
    const fca_rs_sc = (flatprice * t - fobbings) * 0.06 * dollar;
    const ppe_rs_sc = fca_rs_sc - logistic * 0.06;

    return ppe_rs_sc;
  },
  // ##################################
  // # Algodão
  // ##################################
  rentability_cotton(
    flatprice: number,
    productivity: number,
    cottonConfigs: CottonConfigurations,
    formatter: string
  ): number {
    const calc = store.getters.getCalculationState;
    const dollar = calc.dollar;
    const constants = calc.constants;
    const productionCost = calc.productionCost;
    const productivity_fiber = productivity * cottonConfigs.fiber_efficiency;
    const productivity_seed = productivity * cottonConfigs.seed_efficiency;
    // Receita com o pluma
    const stuffing_lb = calc.stuffing / dollar / constants.x;
    const logistic_us_lb = calc.logistic / dollar / constants.x;
    const fiber_revenue =
      (flatprice - stuffing_lb - logistic_us_lb) *
      constants.t *
      (productivity_fiber / 15) *
      dollar;
    // Rendimento com o caroço
    // let seed_revenue = (productivity_seed / 1000) * dollar * (cottonConfigs.seed_price / dollar);
    const seed_revenue = this.calculateCottonSeedRevenue(
      productivity_seed,
      cottonConfigs.seed_price
    );
    if (formatter === "_percent") {
      return (
        ((fiber_revenue + seed_revenue - productionCost) / productionCost) * 100
      );
    } else if (formatter === "_real") {
      return fiber_revenue + seed_revenue - productionCost;
    } else if (formatter === "_dolar") {
      return (fiber_revenue + seed_revenue - productionCost) / dollar;
    } else return 0;
  },
  // Calcula a projeção do dolar
  // Quanto falta o dolar mexer para atingir o gatilho
  saleTriggerDollarCotton(margin: number): number {
    const calc = store.getters.getCalculationState;
    const ownerProfit = store.getters.getProfitOwnershipSelected;
    const productivityFiber = calc.productivityFiber;
    const seedPrice = ownerProfit.cotton_config.seed_price;

    const seed_revenue = this.calculateCottonSeedRevenue(
      calc.productivitySeed,
      seedPrice
    );
    const revenue_exw =
      margin +
      calc.productionCost -
      seed_revenue +
      ((calc.logistic + calc.stuffing) / 1000) * (productivityFiber * 15);
    const revenue_exw_arroba = revenue_exw / productivityFiber;

    const price_us = calc.flatprice * calc.constants.t;
    const dolFuture = revenue_exw_arroba / price_us;

    return dolFuture - calc.dollar;
  },
  // Calcula a prjeção do PPE
  // Quanto falta o FLATPRICE mexer para atingir o gatilho
  saleTriggerPPECotton(margin: number): number {
    const calc = store.getters.getCalculationState;
    const ownerProfit = store.getters.getProfitOwnershipSelected;
    const dollar = calc.dollar;
    const constants = calc.constants;

    const logistic_us_lb = (ownerProfit.logistic + calc.stuffing) / dollar / constants.x;

    const costs = margin + calc.productionCost - (calc.productivitySeed / 1000) *
      ownerProfit.cotton_config.seed_price;
    const costs_us = costs / dollar;
    const costs_fiber = costs_us / calc.productivityFiber;
    const costs_lb = costs_fiber / constants.t;
    const ppe = costs_lb + logistic_us_lb - calc.flatprice;

    return ppe;
  },
  calculateCottonSeedRevenue(
    seed_produtivity: number,
    seed_price: number,
    dollar: number | undefined = undefined
  ): number {
    if (dollar) seed_price = seed_price / dollar; // $/t
    return (seed_produtivity / 1000) * seed_price;
  },
  // Recalcula os valores dentro da rentabilidade da fazenda
  // Quando o usuário aperta insere um valor na tela
  reCalcOwnershipCostLogCotton(
    profitOwnershipSelected: OwnershipProfitCotton,
    constants: Constants,
    dollar: number
  ): OwnershipProfitCotton {
    profitOwnershipSelected.productivity_fiber =
      (profitOwnershipSelected.productivity *
        profitOwnershipSelected.cotton_config.fiber_efficiency) /
      15;
    profitOwnershipSelected.productivity_seed =
      profitOwnershipSelected.productivity *
      profitOwnershipSelected.cotton_config.seed_efficiency;

    const logistic_us =
      (profitOwnershipSelected.logistic + profitOwnershipSelected.stuffing) /
      dollar;
    const logistic_us_lb = logistic_us / constants.x; // $/lb;

    const seed_revenue_rs = this.calculateCottonSeedRevenue(
      profitOwnershipSelected.productivity_seed,
      profitOwnershipSelected.cotton_config.seed_price
    );

    const exw_cost_rs_arroba =
      (profitOwnershipSelected.production_cost - seed_revenue_rs) /
      profitOwnershipSelected.productivity_fiber;
    const exw_cost_us_arroba = exw_cost_rs_arroba / dollar;
    const exw_cost_us_lb = exw_cost_us_arroba / constants.t;
    // Receita com o caroço
    const seed_revenue = this.calculateCottonSeedRevenue(
      profitOwnershipSelected.productivity_seed,
      profitOwnershipSelected.cotton_config.seed_price,
      dollar
    );

    const fob_price_us_lb =
      (profitOwnershipSelected.production_cost / dollar - seed_revenue) /
      profitOwnershipSelected.productivity_fiber /
      constants.t +
      logistic_us / constants.x;
    const fob_price_us_arroba = fob_price_us_lb * constants.t;
    const fob_price_rs_arroba = fob_price_us_arroba * dollar;

    const fca_us_lb =
      profitOwnershipSelected.flat_price -
      profitOwnershipSelected.stuffing / dollar / constants.x;
    const fca_us_arroba = fca_us_lb * constants.t;
    const fca_rs_arroba = fca_us_arroba * dollar;
    const ppe_us_lb = profitOwnershipSelected.flat_price - logistic_us_lb;
    const ppe_us_arroba = ppe_us_lb * constants.t;
    const ppe_rs_arroba = ppe_us_arroba * dollar;

    profitOwnershipSelected.exw_cost_us_lb = exw_cost_us_lb;
    profitOwnershipSelected.exw_cost_us_arroba = exw_cost_us_arroba;
    profitOwnershipSelected.exw_cost_rs_arroba = exw_cost_rs_arroba;

    profitOwnershipSelected.fob_price_us_lb = fob_price_us_lb;
    profitOwnershipSelected.fob_price_us_arroba = fob_price_us_arroba;
    profitOwnershipSelected.fob_price_rs_arroba = fob_price_rs_arroba;

    profitOwnershipSelected.fca_rs_arroba = fca_rs_arroba;
    profitOwnershipSelected.fca_us_arroba = fca_us_arroba;
    profitOwnershipSelected.ppe_ex_rs_arroba = ppe_rs_arroba;
    profitOwnershipSelected.ppe_ex_us_arroba = ppe_us_arroba;

    return profitOwnershipSelected;
  },
  // Recalcula os valores dentro da receita operacional
  // Quando o usuário aperta insere um valor na tela
  reCalcOperacionalIncomeCotton(
    profitIncomeSelected: OperationIncome,
    profitOwnershipSelected: OwnershipProfitCotton,
    dollar: number
  ): OperationIncome {
    // CALCULO DA PLUMA
    let areaProd =
      profitOwnershipSelected.productivity_fiber *
      profitOwnershipSelected.total_area;
    // Recalcula a linha do Saldo
    if (profitIncomeSelected.fiber) {
      profitIncomeSelected.fiber.statement_amount =
        areaProd - profitIncomeSelected.fiber.sold_amount;
      profitIncomeSelected.fiber.statement_rs_arroba =
        profitOwnershipSelected.ppe_ex_rs_arroba;
      profitIncomeSelected.fiber.statement_us_arroba =
        profitOwnershipSelected.ppe_ex_us_arroba;
      profitIncomeSelected.fiber.statement_value_rs =
        profitIncomeSelected.fiber.statement_amount *
        profitOwnershipSelected.ppe_ex_rs_arroba;
      profitIncomeSelected.fiber.statement_value_us =
        profitIncomeSelected.fiber.statement_amount *
        profitOwnershipSelected.ppe_ex_us_arroba;
      // Recalcula a linha do Faturamento
      profitIncomeSelected.fiber.revenue_amount =
        profitIncomeSelected.fiber.sold_amount +
        profitIncomeSelected.fiber.statement_amount;
      profitIncomeSelected.fiber.revenue_value_rs =
        profitIncomeSelected.fiber.sold_value_rs +
        profitIncomeSelected.fiber.statement_value_rs;
      profitIncomeSelected.fiber.revenue_value_us =
        profitIncomeSelected.fiber.sold_value_us +
        profitIncomeSelected.fiber.statement_value_us;
      profitIncomeSelected.fiber.revenue_rs_arroba =
        profitIncomeSelected.fiber.revenue_value_rs /
        profitIncomeSelected.fiber.revenue_amount;
      profitIncomeSelected.fiber.revenue_us_arroba =
        profitIncomeSelected.fiber.revenue_value_us /
        profitIncomeSelected.fiber.revenue_amount;
      // Recalcula porcentagem
      profitIncomeSelected.fiber.sold_pcent =
        (profitIncomeSelected.fiber.sold_amount /
          profitIncomeSelected.fiber.revenue_amount) *
        100;
      profitIncomeSelected.fiber.statement_pcent =
        (profitIncomeSelected.fiber.statement_amount /
          profitIncomeSelected.fiber.revenue_amount) *
        100;
    }
    // CALCULO DO CAROÇO
    areaProd =
      profitOwnershipSelected.productivity_seed *
      profitOwnershipSelected.total_area;
    const seedPrice = profitOwnershipSelected.cotton_config.seed_price / 1000;
    const seedPriceUs = seedPrice / dollar;
    if (profitIncomeSelected.seed) {
      // Recalcula a linha do Saldo
      profitIncomeSelected.seed.statement_amount =
        areaProd - profitIncomeSelected.seed.sold_amount;
      profitIncomeSelected.seed.statement_rs_kg = seedPrice;
      profitIncomeSelected.seed.statement_us_kg = seedPriceUs;
      profitIncomeSelected.seed.statement_value_rs =
        profitIncomeSelected.seed.statement_amount * seedPrice;
      profitIncomeSelected.seed.statement_value_us =
        profitIncomeSelected.seed.statement_amount * seedPriceUs;
      // Recalcula a linha do Faturamento
      profitIncomeSelected.seed.revenue_amount =
        profitIncomeSelected.seed.sold_amount +
        profitIncomeSelected.seed.statement_amount;
      profitIncomeSelected.seed.revenue_value_rs =
        profitIncomeSelected.seed.sold_value_rs +
        profitIncomeSelected.seed.statement_value_rs;
      profitIncomeSelected.seed.revenue_value_us =
        profitIncomeSelected.seed.sold_value_us +
        profitIncomeSelected.seed.statement_value_us;
      profitIncomeSelected.seed.revenue_rs_kg =
        profitIncomeSelected.seed.revenue_value_rs /
        profitIncomeSelected.seed.revenue_amount;
      profitIncomeSelected.seed.revenue_us_kg =
        profitIncomeSelected.seed.revenue_value_us /
        profitIncomeSelected.seed.revenue_amount;
      // Recalcula porcentagem
      profitIncomeSelected.seed.sold_pcent =
        (profitIncomeSelected.seed.sold_amount /
          profitIncomeSelected.seed.revenue_amount) *
        100;
      profitIncomeSelected.seed.statement_pcent =
        (profitIncomeSelected.seed.statement_amount /
          profitIncomeSelected.seed.revenue_amount) *
        100;
    }

    return profitIncomeSelected;
  },
  // Recalcula os valores dentro da margem operacional
  // Quando o usuário aperta insere um valor na tela
  reCalcOperacionalMarginCotton(
    profitMarginSelected: OperationMargin,
    profitIncomeSelected: OperationIncome,
    profitOwnershipSelected: OwnershipProfitCotton,
    dollar: number
  ): OperationMargin {
    // Recalcula o custo total
    // e a receita bruta
    if (profitIncomeSelected.fiber && profitIncomeSelected.seed) {
      const cost_rs =
        profitOwnershipSelected.production_cost *
        profitOwnershipSelected.total_area;
      const cost_us = cost_rs / dollar;
      const revenue_amount_rs =
        profitIncomeSelected.fiber.revenue_value_rs +
        profitIncomeSelected.seed.revenue_value_rs -
        cost_rs;
      const revenue_amount_us =
        profitIncomeSelected.fiber.revenue_value_us +
        profitIncomeSelected.seed.revenue_value_us -
        cost_us;
      const revenue_amount_rs_ha =
        revenue_amount_rs / profitOwnershipSelected.total_area;
      const revenue_amount_us_ha =
        revenue_amount_us / profitOwnershipSelected.total_area;
      // Recalcula a receita bruta por ha
      // e o ROI
      profitMarginSelected.revenue_amount_rs = revenue_amount_rs;
      profitMarginSelected.revenue_amount_us = revenue_amount_us;
      profitMarginSelected.revenue_amount_rs_ha = revenue_amount_rs_ha;
      profitMarginSelected.revenue_amount_us_ha = revenue_amount_us_ha;
      profitMarginSelected.op_roi_rs =
        cost_rs > 0 ? (revenue_amount_rs / cost_rs) * 100 : 0;
      profitMarginSelected.op_roi_us =
        cost_us > 0 ? (revenue_amount_us / cost_us) * 100 : 0;

      profitMarginSelected.total_cust_rs = cost_rs;
      profitMarginSelected.total_cust_us = cost_us;
      profitMarginSelected.revenue_amount_rs = revenue_amount_rs;
      profitMarginSelected.revenue_amount_us = revenue_amount_us;
    }

    return profitMarginSelected;
  },
  ppeRealArroba(
    flatprice: number,
    constants: Constants,
    logistic: number,
    stuffing: number,
    dollar: number
  ): number {
    const fcaRsArroba =
      (flatprice * constants.t - stuffing / dollar / constants.x) * dollar;
    return fcaRsArroba - (logistic / 1000) * 15;
  },
  // Calcula a margim para os gatilhos em porcentagem
  triggersMarginCotton(margin: number, productionCost: number): number {
    return (margin / productionCost) * 100;
  },
  // ##################################
  // # Boi gordo
  // ##################################
  saleTriggerCattleB3(margin: number): number {
    const calc = store.getters.getCalculationStateCattle;
    return margin + calc.productionCost - (calc.b3 + calc.basis);
  },
  cattlePricing(se: number, basis: number): number {
    return se + basis;
  },
  cattleRentability(
    pricing: number,
    productivity: number,
    formatter: string
  ): number {
    const calc = store.getters.getCalculationStateCattle;
    const cost = calc.cattleTotalCost / (productivity * calc.cattleAmount);

    if (formatter === "_arroba") {
      return pricing - cost;
    } else if (formatter === "_kilo") {
      return (pricing - cost) / 15;
    } else if (formatter === "_cab") {
      return (pricing - cost) * productivity;
    } else if (formatter === "_percent") {
      return ((pricing - cost) / cost) * 100;
    } else return 0;
  },
  // Recalcula os valores dentro da rentabilidade da fazenda
  // Quando o usuário aperta insere um valor na tela
  reCalcProfitCattleLots(profitCattleLotSelected: LotInfoProfit): LotInfoProfit {
    const slaughter_weight = profitCattleLotSelected.slaughter_weight
    const yield_carcass = profitCattleLotSelected.yield_carcass;
    // Recalc peso final em arroba
    const final_weight = (slaughter_weight * (yield_carcass / 100)) / 15;
    // Recalc custo por cabeça
    const animal_total_cost =
      profitCattleLotSelected.arroba_total_cost * final_weight;
    // Recalc custo do lote
    const lot_total_cost =
      animal_total_cost * profitCattleLotSelected.animal_amount;
    // atribui ao obj
    profitCattleLotSelected.carcass_weight = parseFloat(final_weight.toFixed(2));
    profitCattleLotSelected.animal_total_cost = parseFloat(animal_total_cost.toFixed(2));
    profitCattleLotSelected.lot_total_cost = parseFloat(lot_total_cost.toFixed(2));

    return profitCattleLotSelected;
  },
  // Calcula a margim para os gatilhos em porcentagem
  triggersMarginCattle(margin: number, costs: number): number {
    return parseFloat(((margin / costs) * 100).toFixed(2));
  },

  spotPricing(
    flatprice: number,
    dollar: number,
    logistic: number,
    constants: Constants,
    growingSymbol: string,
    pricing: string
  ): number {
    if (pricing === "ppe") {
      if (growingSymbol === "CT")
        return flatprice * constants.t * dollar - logistic * 0.015;
      else return flatprice * constants.x * dollar - logistic * 0.06;
    } else {
      if (pricing === "basisB3") return flatprice;
      else return flatprice * constants.x * dollar;
    }
  },

  getCurrencyById(id: number): Currency {
    const cunrrencies = store.getters.getCurrencies;
    return cunrrencies.find((c: Currency) => c.id === id);
  },

  getConstants(growingSymbol: string): Constants | undefined {
    return constants.get(growingSymbol);
  },

  growingEnglishName(symbol: string): string {
    if (symbol === "S") return "soybean";
    else if (symbol === "C") return "corn";
    else if (symbol === "CT") return "cotton";
    else return "soybean";
  },

  getHarvestPeriod(harvest: Harvest): string {
    if (harvest.is_current)
      return 'actual'
    else if (harvest.is_future)
      return 'future'
    else
      return 'spot'
  },

  b3QuoteByDate(date: string, b3Quotes: Quotes[]): Quotes | undefined {
    const ship_date = date.split("-");
    const ship_year = parseInt(ship_date[0]);
    let ship_month = parseInt(ship_date[1]);
    const ship_day = parseInt(ship_date[2]);

    let month = "";
    if (ship_day >= 15) ship_month = ship_month + 1;
    month = ship_month >= 10 ? ship_month.toString() : "0" + ship_month.toString();
    return b3Quotes.filter((se) => {
      if (se.date) {
        if (
          [ship_year.toString(), month].join("-") ===
          se.date.split("-").slice(0, 2).join("-")
        ) {
          return se;
        }
      }
    })[0];
  }
};
