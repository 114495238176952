<template>
  <v-text-field
    v-mask="config"
    :label="label"
    :value="display"
    :rules="rules"
    :color="color"
    :filled="filled"
    :outlined="outlined"
    :dense="dense"
    :disabled="disabled"
    :clearable="clearable"
    validate-on-blur
    @input="onInput"/>
</template>

<script>
import mask from '@/directives/mask.js'
import tokens from '@/directives/tokens'
import masker from '@/directives/masker'

export default {
  name: 'MaskInput',
  props: {
    value: [String, Number],
    mask: {
      type: [String, Array],
      required: true
    },
    masked: { // by default emits the value unformatted, change to true to format with the mask
      type: Boolean,
      default: false // raw
    },
    tokens: {
      type: Object,
      default: () => tokens
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: Array
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
  },
  directives: {mask},
  data () {
    return {
      maskRules: null,
      lastValue: null, // avoid unecessary emit when has no change
      display: this.value
    }
  },
  watch : {
    value (newValue) {
      if (newValue !== this.lastValue) {
        this.display = newValue
      }
    },
    masked () {
      this.refresh(this.display)
    }
  },
  computed: {
    config () {
      return {
        mask: this.mask,
        tokens: this.tokens,
        masked: this.masked
      }
    }
  },
  methods: {
    onInput (e) {
      // if (e.isTrusted) return // ignore native event
      // this.refresh(e.target.value)
      this.refresh(e)
    },

    refresh (value) {
      this.display = value
      var emitValue = masker(value, this.mask, this.masked, this.tokens)
      if (emitValue !== this.lastValue) {
        this.lastValue = emitValue
        this.$emit('input', emitValue)
      }
    }
  },
}
</script>

<style>

</style>