import axios from "@/plugins/axios";

import User, { DisplayUsers } from "@/models/user";
import { IUserMeResponse } from "@/types/store-types";
import { intanciateApiData, isValidResponse } from "@/utilsObject";

const getUserMe = async (): Promise<IUserMeResponse> => {
  const response = await axios.httpApi.get("user/me");
  if (!isValidResponse(response.status))
    console.error(`Error to get User Me: ${response.statusText}`);
  return response.data;
};

const registerUser = async (token: string): Promise<void> => {
  const params = { token: token };
  const response = await axios.httpApi.get("user/register", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to register user: ${response.statusText}`);
  return response.data;
};

const getNusers = async (): Promise<DisplayUsers[]> => {
  const response = await axios.httpApi.get("user/n-users");
  if (!isValidResponse(response.status))
    console.error(`Error to get N users: ${response.statusText}`);
  return response.data;
};

const getMeInternal = async (): Promise<IUserMeResponse> => {
  const response = await axios.httpApi.get("user/me-update");
  if (!isValidResponse(response.status))
    console.error(`Error to get me internal: ${response.statusText}`);
  return response.data;
};

const saveUser = async (data: User): Promise<User> => {
  const response = await axios.httpApi.patch(`user/${data.id}`, data);
  if (isValidResponse(response.status)) {
    return intanciateApiData(User, response.data);
  }
  console.error(`Error to save user: ${response.statusText}`);
  return response.data;
};


export const userService = {
  getUserMe,
  registerUser,
  getNusers,
  getMeInternal,
  saveUser,
};
