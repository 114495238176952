import {
  LOAD_ADDRESSES,
  LOAD_GROWINGS,
  LOAD_HARVESTS,
  LOAD_CURRENCIES,
  LOAD_MATURATION_GROUPS,
  LOAD_OUTGOING_CATEGORIES,
  LOAD_SUBOUTGOING_TYPE,
  LOAD_SALE_REASON,
  LOAD_UNITS,
  LOAD_CACHE_RESOURCES,
  GET_BEEF_CATTLE_ID
} from "@/store/actions-types";

import {
  SET_COUNTRIES,
  SET_STATES,
  SET_CITIES,
  SET_GROWINGS,
  SET_HARVESTS,
  SET_SPOT_HARVESTS,
  SET_CURRENCIES,
  SET_MATURATION_GROUPS,
  SET_OUTGOING_CATEGORIES,
  SET_SUBOUTGOING_TYPE,
  SET_SALE_REASON,
  SET_UNITS,
} from "@/store/mutations-types";

import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IRootState,
  IStaticsModule,
  StaticsGetterTypes,
} from "@/types/store-types";
import { OutgoingCategory, SubOutgoingType } from "@/models/outgoingCategory";
import { arrayJsonToClass } from "@/utilsObject";
import { utilsService } from "@/services/utils";

import Country from "@/models/country";
import City from "@/models/city";
import State from "@/models/state";
import Growing from "@/models/growing";
import Harvest from "@/models/harvest";
import Currency from "@/models/currency";
import MaturationGroup from "@/models/maturationGroup";
import SaleReason from "@/models/saleReason";
import Unit from "@/models/unit";
import { checkCacheExpiryVersion, setCacheItem } from "@/utilsCache";

// Shorthands for easier type building
type S = IStaticsModule;
type R = IRootState;

class StateModule implements IStaticsModule {
  states: Array<State> = [];
  cities: Array<City> = [];
  countries: Array<Country> = [];
  growings: Array<Growing> = [];
  harvests: Array<Harvest> = [];
  spotHarvests: Harvest = new Harvest();
  currencies: Array<Currency> = [];
  maturationGroups: Array<MaturationGroup> = [];
  outgoingCategories: Array<OutgoingCategory> = [];
  subOutgoingTypes: Array<SubOutgoingType> = [];
  saleReasons: Array<SaleReason> = [];
  units: Array<Unit> = [];
}

const mutations: MutationTree<S> = {
  [SET_COUNTRIES]: (state, countries: Array<Country>) => {
    state.countries = countries;
  },
  [SET_STATES]: (state, states: Array<State>) => {
    state.states = states;
  },
  [SET_CITIES]: (state, cities: Array<City>) => {
    state.cities = cities;
  },
  [SET_GROWINGS]: (state, value: Array<Growing>) => {
    state.growings = value;
  },
  [SET_HARVESTS]: (state, value: Array<Harvest>) => {
    state.harvests = value.sort((a, b) =>
      a.name && b.name && a.name < b.name ? 1 : -1
    );
  },
  [SET_SPOT_HARVESTS]: (state, value: Harvest) => {
    state.spotHarvests = value;
  },
  [SET_CURRENCIES]: (state, value: Array<Currency>) => {
    state.currencies = value;
  },
  [SET_MATURATION_GROUPS]: (state, value: Array<MaturationGroup>) => {
    state.maturationGroups = value;
  },
  [SET_OUTGOING_CATEGORIES]: (state, value: Array<OutgoingCategory>) => {
    state.outgoingCategories = value;
  },
  [SET_SUBOUTGOING_TYPE]: (state, value: Array<SubOutgoingType>) => {
    state.subOutgoingTypes = value;
  },
  [SET_SALE_REASON]: (state, value: Array<SaleReason>) => {
    state.saleReasons = value;
  },
  [SET_UNITS]: (state, value: Array<Unit>) => {
    state.units = value;
  },
};

const actions: ActionTree<S, R> = {
  [LOAD_ADDRESSES]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheAddresses = checkCacheExpiryVersion(
        "cache_addresses",
        getters.appVersion
      );

      if (cacheAddresses) {
        commit(SET_COUNTRIES, arrayJsonToClass(Country, cacheAddresses.countries));
        commit(SET_STATES, arrayJsonToClass(State, cacheAddresses.states));
        commit(SET_CITIES, arrayJsonToClass(City, cacheAddresses.cities));
        resolve();
      } else {
        utilsService.getAllCountries()
          .then((rcountries) => {
            commit(SET_COUNTRIES, arrayJsonToClass(Country, rcountries.objects));
            utilsService.getAllStates().then((rstates) => {
              commit(SET_STATES, arrayJsonToClass(State, rstates.objects));
              utilsService.getAllCities().then((rcities) => {
                commit(SET_CITIES, arrayJsonToClass(City, rcities.objects));
                //Faz o cache dos valores estaticos
                setCacheItem(
                  "addresses",
                  {
                    countries: rcountries.objects,
                    states: rstates.objects,
                    cities: rcities.objects,
                  },
                  getters.appVersion
                );
                resolve();
              });
            });
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_GROWINGS]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheGrowings = checkCacheExpiryVersion(
        "cache_growings",
        getters.appVersion
      );

      if (cacheGrowings) {
        commit(SET_GROWINGS, arrayJsonToClass(Growing, cacheGrowings));
        resolve();
      } else {
        utilsService.getGrowings()
          .then((response) => {
            commit(SET_GROWINGS, arrayJsonToClass(Growing, response.objects));
            setCacheItem(
              "growings",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_HARVESTS]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheHarvests = checkCacheExpiryVersion(
        "cache_harvests",
        getters.appVersion
      );

      if (cacheHarvests) {
        const spotIds = cacheHarvests
          .filter((el: Harvest) => !el.is_current && !el.is_future)
          .map((el: Harvest) => el.id);
        const harvestSpot = new Harvest({
          id: 0,
          created_at: "",
          updated_at: "",
          name: "Spot",
          is_current: false,
          is_future: false,
          is_deleted: false,
          begin_date: "",
          end_date: "",
          ids: spotIds,
        });
        commit(SET_HARVESTS, arrayJsonToClass(Harvest, cacheHarvests));
        commit(SET_SPOT_HARVESTS, harvestSpot);
        resolve();
      } else {
        utilsService.getHarvests()
          .then((response) => {
            const spotIds = response.objects
              .filter((el: Harvest) => !el.is_current && !el.is_future)
              .map((el: Harvest) => (el.id ? el.id : 0));
            const harvestSpot = new Harvest({
              id: 0,
              created_at: "",
              updated_at: "",
              name: "Spot",
              is_current: false,
              is_future: false,
              is_deleted: false,
              begin_date: "",
              end_date: "",
              ids: spotIds,
            });
            commit(SET_HARVESTS, arrayJsonToClass(Harvest, response.objects));
            commit(SET_SPOT_HARVESTS, harvestSpot);
            setCacheItem(
              "harvests",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_CURRENCIES]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheCurrencies = checkCacheExpiryVersion(
        "cache_currencies",
        getters.appVersion
      );

      if (cacheCurrencies) {
        commit(SET_CURRENCIES, arrayJsonToClass(Currency, cacheCurrencies));
        resolve();
      } else {
        utilsService.getCurrencies()
          .then((response) => {
            commit(SET_CURRENCIES, arrayJsonToClass(Currency, response.objects));
            setCacheItem(
              "currencies",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_MATURATION_GROUPS]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheMaturation = checkCacheExpiryVersion(
        "cache_maturations",
        getters.appVersion
      );

      if (cacheMaturation) {
        commit(
          SET_MATURATION_GROUPS,
          arrayJsonToClass(MaturationGroup, cacheMaturation)
        );
        resolve();
      } else {
        utilsService.getMaturationGroups()
          .then((response) => {
            commit(
              SET_MATURATION_GROUPS,
              arrayJsonToClass(MaturationGroup, response.objects)
            );
            setCacheItem(
              "maturations",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_OUTGOING_CATEGORIES]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheOutCategories = checkCacheExpiryVersion(
        "cache_out_categories",
        getters.appVersion
      );

      if (cacheOutCategories) {
        commit(
          SET_OUTGOING_CATEGORIES,
          arrayJsonToClass(MaturationGroup, cacheOutCategories)
        );
        resolve();
      } else {
        utilsService.getOutgoingCategories()
          .then((response) => {
            commit(
              SET_OUTGOING_CATEGORIES,
              arrayJsonToClass(MaturationGroup, response.objects)
            );
            setCacheItem(
              "out_categories",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_SUBOUTGOING_TYPE]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheSuboutType = checkCacheExpiryVersion(
        "cache_subout_type",
        getters.appVersion
      );

      if (cacheSuboutType) {
        commit(
          SET_SUBOUTGOING_TYPE,
          arrayJsonToClass(SubOutgoingType, cacheSuboutType)
        );
        resolve();
      } else {
        utilsService.getSubOutgoingType()
          .then((response) => {
            commit(
              SET_SUBOUTGOING_TYPE,
              arrayJsonToClass(SubOutgoingType, response.objects)
            );
            setCacheItem(
              "subout_type",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_SALE_REASON]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheSaleReason = checkCacheExpiryVersion(
        "cache_sale_reason",
        getters.appVersion
      );

      if (cacheSaleReason) {
        commit(SET_SALE_REASON, arrayJsonToClass(SaleReason, cacheSaleReason));
      } else {
        utilsService.getSaleReason()
          .then((response) => {
            commit(SET_SALE_REASON, arrayJsonToClass(SaleReason, response.objects));
            setCacheItem(
              "sale_reason",
              response.objects,
              getters.appVersion
            );
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_UNITS]: ({ getters, commit }) => {
    return new Promise<void>((resolve, reject) => {
      const cacheUnits = checkCacheExpiryVersion(
        "cache_units",
        getters.appVersion
      );

      if (cacheUnits) {
        commit(SET_UNITS, arrayJsonToClass(Unit, cacheUnits));
      } else {
        utilsService.getUnits()
          .then((response) => {
            commit(SET_UNITS, arrayJsonToClass(Unit, response.objects));
            setCacheItem("units", response.objects, getters.appVersion);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  },
  [LOAD_CACHE_RESOURCES]: ({ dispatch }) => {
    return new Promise<void>((resolve, reject) => {
      try {
        dispatch(LOAD_ADDRESSES);
        dispatch(LOAD_GROWINGS);
        dispatch(LOAD_HARVESTS);
        dispatch(LOAD_CURRENCIES);
        dispatch(LOAD_MATURATION_GROUPS);
        dispatch(LOAD_OUTGOING_CATEGORIES);
        dispatch(LOAD_SUBOUTGOING_TYPE);
        dispatch(LOAD_SALE_REASON);
        dispatch(LOAD_UNITS);
        resolve();
      } catch (error) {
        reject();
      }
    });
  },
  [GET_BEEF_CATTLE_ID]: ({ state }) => {
    if(state.growings.length) {
      const cattle = state.growings.find((el) => el.isCattle);
      if(cattle) return cattle.id;
      return undefined;
    }
    return undefined;
  },
};

const getters: GetterTree<S, R> = {
  [StaticsGetterTypes.getCities](state) {
    return state.cities;
  },
  [StaticsGetterTypes.getStates](state) {
    return state.states;
  },
  [StaticsGetterTypes.getCountries](state) {
    return state.countries;
  },
  [StaticsGetterTypes.getGrowings](state) {
    return state.growings;
  },
  [StaticsGetterTypes.getHarvests](state) {
    return state.harvests;
  },
  [StaticsGetterTypes.getSpotHarvests](state) {
    return state.spotHarvests;
  },
  [StaticsGetterTypes.getCurrencies](state) {
    return state.currencies;
  },
  [StaticsGetterTypes.getMaturationGroups](state) {
    return state.maturationGroups;
  },
  [StaticsGetterTypes.getOutgoingCategories](state) {
    return state.outgoingCategories;
  },
  [StaticsGetterTypes.getSubOutgoingType](state) {
    return state.subOutgoingTypes;
  },
  [StaticsGetterTypes.getSaleReason](state) {
    return state.saleReasons;
  },
  [StaticsGetterTypes.getUnits](state) {
    return state.units;
  },
};

export default {
  state: new StateModule(),
  mutations,
  actions,
  getters,
};
