import Ownership, { IOwnership } from "./models/ownership";
import { Quotes } from "./business/Quotations";

/**Func verifica se esta no range de 2xx */
export function isValidResponse(status: number): boolean {
  if (status >= 200 && status <= 299) return true;
  return false;
}
/*
  Converte JSON object em instancias da classe genérica T
  Ex: Harvest, Ownership...
*/
export function arrayJsonToClass<T>(
  model: new (...args: any) => T,
  arr: Array<T>
): Array<T> {
  return arr.map((el) => {
    return new model(el);
  });
}
/**Func instancia o JSON para a classe atribuida no retorno */
export function intanciateApiData<T>(cl: new (...args: any) => T, data: T): T {
  if (data) {
    return new cl(data);
  }
  return data;
}
/**Func instancia o JSON para a classe atribuida no retorno dentro do array */
export function intanciateArrayApiData<T>(cl: new (...args: any) => T, data: T[]): T[] {
  if (data) {
    return arrayJsonToClass(cl, data);
  }
  return data;
}
/**Func que retorna o obj Ownership
 * com os dados da Global para rentabilidade
 */
export function globalOwnershipClass(): Ownership {
  const global: IOwnership = {
    id: 0,
    name: "Global",
    deal_soybean_reference: "03",
    deal_corn_reference: "09",
    deal_cotton_reference: "12",
    city_id: 0,
    is_deleted: false,
    user_id: 0,
  };
  return new Ownership(global);
}
/**Func para trazer uma cotação especifica
 * dentro do array de cotações
 */
export function filterQuotes(name: string, arr: Quotes[]): Quotes | undefined {
  return arr.find((el) => el.identifier === name);
}
/**Func para validar se uma string
 * é uma propriedade valida do obj
 */
export function isValidKeys<T extends object>(cl: { new(): T }, value: string): value is keyof typeof cl {
  return value in new cl();
}
/**Func para inserir um valor no obj
 * em uma prop valida
 */
export function setValueValidKey<T, K extends keyof T>(obj: T, propertyName: K, value: any): T {
  obj[propertyName] = value;
  return obj;
}

export function stringToNumber(value: string | number): number {
  return typeof value === 'string' ? parseFloat(value) : value;
}

export function stringToNumberOrUndefined(value: string | number | undefined): number | undefined {
  if (typeof value !== 'undefined')
    return typeof value === 'string' ? parseFloat(value) : value;
}

export function fakeOwnership(): Ownership {
  const data: IOwnership = {
    id: -1,
    city_id: 0,
    deal_corn_reference: "09",
    deal_cotton_reference: "12",
    deal_soybean_reference: "03",
    is_deleted: false,
    name: "Fazenda Visitante",
    user_id: 0,
  }
  return new Ownership(data);
}

export function getWatermark(): HTMLCanvasElement {
  const waterMarkText = "Agrinvest";
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = canvas.height = 100;
  if (ctx) {
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.1;
    ctx.font = "20px Microsoft Yahei";
    ctx.translate(50, 50);
    ctx.rotate(-Math.PI / 4);
    ctx.fillText(waterMarkText, 0, 0);
  }
  return canvas;
}

// Prepara um obj para ser serializado
// removendo todos objetos dump_only
export function preperaSerializable(obj: any): any {
  for (const prop in obj) {
    if (Object.prototype.toString.call(obj[prop]) === "[object Object]") {
      delete obj[prop];
    }
  }
  return obj;
}

// Função simples para comparar 2 objects
export function iqualsTo(obj1: any, obj2: any): boolean {
  // remove sub objects para comparação
  obj1 = preperaSerializable(obj1);
  obj2 = preperaSerializable(obj2);
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function notNullObjectProperties(obj: any, skipProps: string[] = []): void {
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).
      filter(k => !skipProps.includes(k)).
      forEach(key => {
        if (obj[key] === null || typeof obj[key] === 'undefined')
          obj[key] = 0;
      });
  }
}
interface StringIndex {
  [key: string]: string
}
export function pick(obj: any, props: string[]): any {
  "use strict";
  // Make sure object and properties are provided
  if (!obj || !props) return;
  // Create new object
  const picked: StringIndex = {};
  // Loop through props and push to new object
  props.forEach(function (prop) {
    picked[prop] = obj[prop];
  });
  // Return new object
  return picked;
}

export function isEmptyObject(obj: any): boolean {
  return !Object.keys(obj).length;
}