import { isEmptyObject, stringToNumber } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Currency from "./currency";
import Provider from "./provider";

export interface ITradeslipExchangeAcc extends IModelBase {
  contract: string;
  contract_date: string;
  due_date?: string;
  notional: number;
  fee: number;
  interest: number;
  interest_frequency: string;
  currency_id: number;
  bank_id: number;
  tradeslip_id: number;
  origin_tradeslip_exchange_export_lock_id?: number;

  /**Relationship classes */
  bank?: Provider;
  currency?: Currency;
}

export class TradeslipExchangeAccDTO extends ModelBase implements ITradeslipExchangeAcc {
  contract = "";
  contract_date = "";
  due_date: string | undefined = undefined;
  notional = 0;
  fee = 0;
  interest = 0;
  interest_frequency = "";
  currency_id = 0;
  bank_id = 0;
  tradeslip_id = 0;
  origin_tradeslip_exchange_export_lock_id?: number | undefined = undefined;

  bank = new Provider();
  currency = new Currency();

  constructor(dto?: ITradeslipExchangeAcc) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.contract = dto.contract;
      this.contract_date = dto.contract_date;
      this.due_date = dto.due_date;
      this.notional = stringToNumber(dto.notional);
      this.fee = stringToNumber(dto.fee);
      this.interest = stringToNumber(dto.interest);
      this.interest_frequency = dto.interest_frequency;
      this.currency_id = dto.currency_id;
      this.bank_id = dto.bank_id;
      this.tradeslip_id = dto.tradeslip_id;
      this.origin_tradeslip_exchange_export_lock_id = dto.origin_tradeslip_exchange_export_lock_id;

      if(dto.bank)
        this.bank = new Provider(dto.bank);
      if(dto.currency)
        this.currency = new Currency(dto.currency);
    }
  }
}

export default class TradeslipExchangeAcc extends TradeslipExchangeAccDTO {
  private static resource = "tradeslip_exchange_acc";

  constructor();
  constructor(tradeslipExchangeAcc: ITradeslipExchangeAcc);
  constructor(tradeslipExchangeAcc?: ITradeslipExchangeAcc) {
    super(tradeslipExchangeAcc);
  }

  public static getResource(): string {
    return this.resource;
  }
}
