// app
export const SHOW_SNACKBAR = "showSnackbar"
export const SET_CLIENT = "setClient"

// auth
export const SET_TOKEN = "setToken"
export const SET_BITZENTOKEN = "setFMCToken"
export const SET_LOGGEDUSER = "setLoggedUser"
export const SET_CONFIGURATIONS = "setConfigurations"

//crud
export const SET_COUNTRIES = "setCountries"
export const SET_STATES = "setStates"
export const SET_CITIES = "setCities"
export const SET_RESOURCE = "setResource"
export const SET_GROWINGS = "setGrowings"
export const SET_HARVESTS = "setHarvests"
export const SET_SPOT_HARVESTS = "setSpotHarvests"
export const SET_CURRENCIES = "setCurrencies"
export const SET_MATURATION_GROUPS = "setMaturationGroups"
export const SET_OUTGOING_CATEGORIES = "setOutgoingCategories"
export const SET_SUBOUTGOING_TYPE = "setSubOutgoingType"
export const SET_SALE_REASON = "setSaleReason"
export const SET_UNITS = "setUnits"

//searches
export const SET_LOADING = "setLoading"
export const SET_RESULT = "setResult"
export const SET_PAGE = "setPage"
export const SET_NUM_RESULTS = "setNumResults"
export const SET_PAGE_TOTAL = "setPageTotal"

//calculations
export const SET_VALUE = "setValue"
export const SET_DOLLAR = "setDollar"
export const SET_PRODUCTION_COST = "setProductionCost"
export const SET_PRODUCTIVITY = "setProductivity"
export const SET_FOBBINGS = "setFobbings"
export const SET_LOGISTIC = "setLogistic"
export const SET_FLATPRICE = "setFlatprice"
export const SET_EXPECTED_PRODUCTIVITY = "setExpectedProductivity"
export const SET_MARGIN = "setMargin"
export const SET_CONSTANTS = "setConstants"
export const SET_BASIS = "setBasis"
export const SET_STUFFING = "setStuffing"
export const SET_GROWING = "setGrowing"
export const SET_PRODUCTIVITY_FIBER = "setProductivityFiber"
export const SET_PRODUCTIVITY_SEED = "setProductivitySeed"
export const SET_CATTLE_AMOUNT = "setCattleAmount"
export const SET_CATTLE_TOTAL_COST = "setCattleTotalCost"
export const SET_FLATPRICE_FOB = "setFlatpriceFob"
export const SET_FLATPRICE_EXW_CBOT = "setFlatpriceExwCbot"
export const SET_FLATPRICE_EXW_B3 = "setFlatpriceExwB3"
export const SET_BASIS_FOB = "setBasisFob"
export const SET_BASIS_EXW_CBOT = "setBasisExwCbot"
export const SET_BASIS_EXW_B3 = "setBasisExwB3"
export const SET_CBOT = "setCbot"
export const SET_NYBOT = "setNybot"
export const SET_B3 = "setB3"
export const SET_B3_CATLLE = "setB3Cattle"
export const SET_BASIS_CATTLE = "setBasisCattle"
export const SET_CARCASS_PRODUCTIVITY = "setCarcassProductivity"

//profit
export const SET_PROFIT = "setProfit"
export const SET_PROFIT_GROWING = "setProfitGrowing"
export const SET_PROFIT_HARVEST = "setProfitHarvest"
export const SET_PROFIT_OWNERSHIP_SELECTED = "setProfitOwnershipSelected"
export const SET_PROFIT_CATTLE_LOTS = "setProfitCattleLots"
export const SET_PROFIT_CATTLE_LOTS_PRICE = "setProfitCattleLotsPrice"
export const SET_PROFIT_PRICING = "setProfitPricing"
export const SET_PRO_CATTLE_BASIS_REGIONS = "setProCattleBasisRegion"
export const SET_PRO_CATTLE_BASIS = "setProCattleBasis"
export const SET_PRO_QUOTATIONS = "setProQuatatios"
export const SET_PRO_OWNERSHIP = "setProOwnership"
export const SET_PRO_FIELDS = "setProFields"
export const SET_PRO_INCOMES = "setProIncomes"
export const SET_PRO_INCOME_OP = "setProIncomesOp"
export const SET_PRO_MARGIN = "setProMargin"
export const SET_SALE_TRIGGERS = "setSaleTriggers"
export const ADD_SALE_TRIGGERS = "addSaleTriggers"
export const CHANGE_SALE_TRIGGERS = "changeSaleTriggers"
export const REMOVE_SALE_TRIGGERS = "removeSaleTriggers"
export const UPDATE_FLAT_PRICE = "updateFlatPrice"
export const SET_CATTLE_BAR_QUOTATIONS = "setCattleBarQuotation"
export const SET_PROFIT_PRICING_SELECTED = "setProfitPricingSelected"
export const SET_PROFIT_PRICES = "setProfitPrices"

// tradeslip
export const UPDATE_LIST = "updateList"
export const REMOVE_LIST = "removeList"
export const SET_ALL_PROVIDERS = "setAllProviders"
export const SET_BROKERAGEFIRMS = "setBrokerageFirms"
export const SET_BROKERAGE_ACCOUNT = "setBrokerageAccount"
export const SET_SELLERS = "setSellers"
export const SET_BUYERS = "setBuyers"
export const SET_BANKS = "setBanks"
export const SET_SILOS = "setSilos"
export const SET_INCOTERMS = "setIncoterms"
export const SET_CONTRACT_SILOS = "setContractSilos"
export const SET_CONTRACT_DELIVERIES = "setContractDeliveries"
export const SET_CONTRACT_PRICINGS = "setContractPricings"
export const SET_CONTRACT_PRICING_DETAILS = "setContractPricingDetails"
export const SET_CONTRACT_PRICING_COMPONENTS = "setContractPricingComponents"
export const SET_CONTRACT_EXCHANGE_ACC = "setContractExchangeAcc"
export const SET_CONTRACT_EXCHANGE_NDF = "setContractExchangeNdf"
export const SET_CONTRACT_EXCHANGE_EXPORT_LOCK = "setContractExchangeExportLock"
export const SET_CONTRACT_EXCHANGE_DOLLAR_FUTURES = "setContractExchangeDollarFutures"
export const SET_CONTRACT_RESULT = "setContractResult"

// Sysadmin
export const SET_ALL_HARVESTS = "setAllHarvest"
export const SET_ALL_GROWINGS = "setAllGrowing"
export const SET_ALL_UNITS = "setAllUnits"
export const UPDATE_ADMIN_RESOURCES = "updateAdminResources"

// Analytics
// History
export const SET_BASIS_SHIPMENT = "setBasisShipment"
export const ADD_BASIS_SHIPMENT = "addBasisShipment"
export const CHANGE_BASIS_SHIPMENT = "changeBasisShipment"
export const REMOVE_BASIS_SHIPMENT = "removeBasisShipment"
// Projection
export const SET_USER_ALLOWED_CITY = "setUserAllowedCity"
export const ADD_USER_ALLOWED_CITY = "addUserAllowedCity"
export const CHANGE_USER_ALLOWED_CITY = "changeUserAllowedCity"
export const REMOVE_USER_ALLOWED_CITY = "removeUserAllowedCity"
// monthly data table
export const SET_MONTHLY_PROJECTION_DATA_TABLE = "setMonthlyProjectionDataTable"