import { Constants } from "./constants";

/**Func para converter saca para bushel */
export function sackToBushel(sack: number, constants: Constants): number {
  return sack / constants.x;
}
/**Func para converter bushel para saca */
export function bushelToSack(bushel: number, constants: Constants): number {
  return bushel * constants.x;
}
/**Func para converter bushel para saca */
export function bushelToTon(bushel: number, constants: Constants): number {
  return bushel * constants.t;
}