import _Vue from "vue";
import { ToastObject } from "vue-toasted";

const action = [
  {
    text: 'Fechar',
    onClick: (_: Event, toastObject: ToastObject) => {
      toastObject.goAway(0);
    }
  }
]

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$alertSuccess = (msg: string, timeout = 5000): void => {
      Vue.toasted.show(msg, {
        action,
        theme: "toasted-primary",
        position: "top-right",
        className: "success-toast-class",
        duration: timeout,
      });
    };

    Vue.prototype.$alertError = (msg: string, timeout = 5000) => {
      Vue.toasted.show(msg, {
        action,
        theme: "toasted-primary",
        position: "top-right",
        className: "error-toast-class",
        duration: timeout,
      });
    };

    Vue.prototype.$alertInfo = (msg: string, timeout = 5000) => {
      Vue.toasted.show(msg, {
        action,
        theme: "toasted-primary",
        position: "top-right",
        className: "info-toast-class",
        duration: timeout,
      });
    };
  }
}
