import { RawLocation, RouteConfig } from "vue-router";
import utils from "./utils";

const profit: RouteConfig = {
  name: 'Profit',
  path: '/profit',
  beforeEnter: utils.openPaths,
  component: () => import('@/views/Profit.vue'),
  meta: {},
  children: [
    {
      name: 'Rentabilidade',
      path: 'global',
      redirect: (): RawLocation => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: '/profit' }
      },
    }
  ]
}

export default profit;