import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import TradeslipDeliveryPeriod from "./tradeslipDeliveryPeriod";

export interface ITradeslipPricing extends IModelBase {
  amount: number;
  price?: number;
  detailed_price?: string;
  dollar?: number;
  tradeslip_delivery_period_id: number;
  tradeslip_id: number;

  /**Relationship classes */
  tradeslip_delivery_period?: TradeslipDeliveryPeriod;
}

export class TradeslipPricingDTO extends ModelBase implements ITradeslipPricing {
  amount = 0;
  price: number | undefined = undefined;
  detailed_price: string | undefined = undefined;
  dollar: number | undefined = undefined;
  tradeslip_delivery_period_id = 0;
  tradeslip_id = 0;

  tradeslip_delivery_period = new TradeslipDeliveryPeriod();

  constructor(dto?: ITradeslipPricing) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.amount = stringToNumber(dto.amount);
      this.price = stringToNumberOrUndefined(dto.price);
      this.detailed_price = dto.detailed_price;
      this.dollar = stringToNumberOrUndefined(dto.dollar);
      this.tradeslip_delivery_period_id = dto.tradeslip_delivery_period_id;
      this.tradeslip_id = dto.tradeslip_id;

      if(dto.tradeslip_delivery_period)
        this.tradeslip_delivery_period = new TradeslipDeliveryPeriod(dto.tradeslip_delivery_period)
    }
  }
}

export default class TradeslipPricing extends TradeslipPricingDTO {
  private static resource = "tradeslip_pricing";
  constructor();
  constructor(tradeslipPricing: ITradeslipPricing);
  constructor(tradeslipPricing?: ITradeslipPricing) {
    super(tradeslipPricing);
  }

  public static getResource(): string {
    return this.resource;
  }
}
