import Vue from 'vue';
import { DateTime } from 'luxon';
// const { DateTime } = require("luxon");

const luxon = {
  install(vue: typeof Vue) {
    vue.prototype.$luxon = (iso_date: string) => {
      return DateTime.fromISO(iso_date, { locale: "pt-br" });
    }
  }
}

Vue.use(luxon);