import { isEmptyObject, stringToNumber } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface IBasisShipment extends IModelBase {
  value: number;
  shipment_date: string;
  payment_date: string;
  ownership_id: number;
  growing_id: number;
  currency_id: number;
}

export class BasisShipmentDTO extends ModelBase {
  value = 0;
  shipment_date = "";
  payment_date = "";
  ownership_id = 0;
  growing_id = 0;
  currency_id = 0;

  constructor(dto?: IBasisShipment) {
    super(dto);
    if (dto && !isEmptyObject(dto)) {
      this.value = stringToNumber(dto.value);
      this.shipment_date = dto.shipment_date;
      this.payment_date = dto.payment_date;
      this.ownership_id = dto.ownership_id;
      this.growing_id = dto.growing_id;
      this.currency_id = dto.currency_id;
    }
  }
}

export default class BasisShipment extends BasisShipmentDTO {
  private static resource = "basis_shipment";

  constructor();
  constructor(basisShipment: IBasisShipment);
  constructor(basisShipment?: IBasisShipment) {
    super(basisShipment);
  }

  public static getResource(): string {
    return this.resource;
  }
}


interface IBasisShipmentExtraInfo {
  date?: string;
  sc_rs_price?: number;
  sc_us_price?: number;
  se_value?: number;
  dollar?: number;
  code_dollar?: string;
  code_se?: string;
  basis?: number;
  historic_price_rs?: number;
  historic_price_us?: number;
}

export class DisplayBasisShipment extends BasisShipment implements IBasisShipmentExtraInfo {
  date = "";
  sc_rs_price = 0;
  sc_us_price = 0;
  se_value = 0;
  dollar = 0;
  basis = 0;
  code_dollar = "";
  code_se = "";
  historic_price_rs = 0;
  historic_price_us = 0;

  constructor(
    basisShipment: BasisShipment,
    _date: string,
    _sc_rs_price: number,
    _sc_us_price: number,
    _se: number,
    _dollar: number,
    _basis: number,
    _code_dollar: string,
    _code_se: string,
    _historic_price_rs: number,
    _historic_price_us: number
  ) {
    super(basisShipment);
    this.date = _date;
    this.sc_rs_price = _sc_rs_price;
    this.sc_us_price = _sc_us_price;
    this.se_value = _se;
    this.dollar = _dollar;
    this.basis = _basis;
    this.code_dollar = _code_dollar;
    this.code_se = _code_se;
    this.historic_price_rs = _historic_price_rs;
    this.historic_price_us = _historic_price_us;
  }
}