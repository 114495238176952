<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="55.17mm" height="50.45mm" viewBox="0 0 156.38 143.02">
    <path d="M259.71,48.72l-1.8-20.25-20.33-.36,6.19,5.76c-53.21,68.47-140.44,103-140.44,103S205.4,107.48,252.85,42.32Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M248.35,56.79c-19.68,23.4-46.52,42.27-73.28,56.49,23.26-10.51,48.34-25,69.14-44.31,7.42,56.37-57.41,94.4-103.2,60.23q-3.63,1.49-7,2.79a71.2,71.2,0,0,0,45.78,16.62C227.3,148.61,262,102.74,248.35,56.79Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M183.26,76.82V39.09h1.23V73.3c5.29-3.5,10.27-7,14.85-10.35l.7-.16V39.09H184.49Zm1.23-37.73h16.78V64.28c-5.65,4.22-11.73,8.43-18,12.54Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M155.83,93.47V55.08h18V82.82c-6,3.74-12.11,7.32-18,10.65Zm1.23-38.39v35c5.3-3,10.52-6.11,15.54-9.22V55.08Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M129.86,107V70.06h1.23v33.75c4.85-2.32,10.07-5,15.55-7.92V70.06H131.09Zm1.23-36.94h16.78V97.85c-6.47,3.49-12.57,6.58-18,9.15Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M179.79,5.59c-59.55,0-93.19,69.14-56,115.93h0c1.93-1,5.28-2.73,5.9-3.07h0C148.28,108.64,182,89,205.11,67.94c-24,18.07-56.94,36.79-78.58,46.33-30-42.83.8-102.15,53.26-102.15A64.94,64.94,0,0,1,232.6,39.3q2.18-2.55,4.31-5.18A71.48,71.48,0,0,0,179.79,5.59Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M126,127.66c29.57-15,82.35-46.19,118.75-93l.7-.89-4.66-4.34,16,.28,1.42,16-5.53-5.15-.82,1.12c-24.36,33.46-63.53,57.24-92.1,71.31C147.15,119.11,135.45,124,126,127.66Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M179.77,147.83a70.37,70.37,0,0,1-43.36-15l4.4-1.76a64.88,64.88,0,0,0,38.49,12.79A68.46,68.46,0,0,0,230,120.69,64.18,64.18,0,0,0,245.4,69.26l-.31-2.36-1.75,1.62a221.68,221.68,0,0,1-35.1,26.34c15.53-10.94,28.89-22.77,39.52-35.06a70.34,70.34,0,0,1-68,88Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M184.49,73.3V39.09H200V40.7H184.49V66.38Q191.9,64.6,199.34,63C194.76,66.33,189.78,69.8,184.49,73.3Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M157.06,55.08H172.6V80.9c-5,3.11-10.24,6.2-15.54,9.22Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M131.09,70.06h15.55V95.89c-5.48,2.94-10.7,5.6-15.55,7.92Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M124.16,120A70.24,70.24,0,0,1,235.34,34.1c-.86,1.06-1.75,2.12-2.68,3.22a66.4,66.4,0,0,0-52.87-26.43,65.1,65.1,0,0,0-58.64,35.67c-11.62,22.33-10,47.9,4.37,68.42l.58.83.93-.41a433.81,433.81,0,0,0,48.48-26,499.21,499.21,0,0,1-46.37,28C128.61,117.66,126.08,119,124.16,120Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M244.74,34.63a258.37,258.37,0,0,1-20.08,22.81q8.29-1.53,16.62-2.89A157.14,157.14,0,0,0,251.85,41.6l.82-1.12,5.53,5.15-1.42-16-16-.28,4.66,4.34Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M199.34,63l.7-.52v.36Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M184.49,66.38V40.7H200V62.43l-.7.52Q191.9,64.59,184.49,66.38Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M157.06,73.55V56.69H172.6V69.35Q164.82,71.38,157.06,73.55Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M131.09,81.88V71.67h15.55v4.91C141.46,78.14,136.24,79.89,131.09,81.88Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
    <path d="M114.54,89a65.59,65.59,0,0,1,6.61-42.46,65.1,65.1,0,0,1,58.64-35.67,66.4,66.4,0,0,1,52.87,26.43c.93-1.1,1.82-2.16,2.68-3.22a70.33,70.33,0,0,0-124.45,57C112.09,90.36,113.31,89.68,114.54,89Z" transform="translate(-103.33 -5.59)" style="fill:currentColor;fill-rule:evenodd"/>
  </svg>
</template>

<script>
export default {
  name: "ProMarket",
};
</script>
