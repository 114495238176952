export interface IThemeType {
  [name: string]: string;

  primary: string;
  secondary: string;
  accent: string;
  success: string;
  info: string;
  warning: string;
  error: string;
  inspect: string;
  background: string;
  textColor: string;
  soyColor: string;
  cornColor: string;
  cottonColor: string;
  cattleColor: string;
  cardMomColor: string;
  cardHarColor: string;
  cardGroColor: string;
  cardProColor: string;
  cardExwColor: string;
  cardLogColor: string;
  cardPpeColor: string;
  cardCprColor: string;
  cardSltColor: string;
  cardMtxColor: string;
  cardIopColor: string;
  cardOpmColor: string;
  cardHisColor: string;
}

export interface IGrowingThemeType {
  name: string;
  light: IThemeType;
  dark: IThemeType;
}

export interface IOrganizationThemeType {
  agrinvest: Array<IGrowingThemeType>;
  agroamazonia: Array<IGrowingThemeType>;
}

export const organizationThemes: IOrganizationThemeType = {
  agrinvest: [
    {
      name: "soybean",
      light: {
        primary: "#066F6A", //headers
        secondary: "#066F6A", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#066F6A", // main card profit default color
        cardHarColor: "#066F6A", // harvest card profit default color
        cardGroColor: "#066F6A", // growing card profit default color
        cardProColor: "#066F6A", // ownership card profit default color
        cardExwColor: "#066F6A", // exw card profit default color
        cardLogColor: "#066F6A", // logistic card profit default color
        cardPpeColor: "#066F6A", // ppe card profit default color
        cardCprColor: "#066F6A", // cattle pricing profit default color
        cardSltColor: "#066F6A", // sales triggers card profit default color
        cardMtxColor: "#066F6A", // matrix card profit default color
        cardIopColor: "#066F6A", // incomes card profit default color
        cardOpmColor: "#066F6A", // roi card profit default color
        cardHisColor: "#066F6A", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    },
    {
      name: "corn",
      light: {
        primary: "#D1AE6C", //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#D1AE6C", // main card profit default color
        cardHarColor: "#D1AE6C", // harvest card profit default color
        cardGroColor: "#D1AE6C", // growing card profit default color
        cardProColor: "#D1AE6C", // ownership card profit default color
        cardExwColor: "#D1AE6C", // exw card profit default color
        cardLogColor: "#D1AE6C", // logistic card profit default color
        cardPpeColor: "#D1AE6C", // ppe card profit default color
        cardCprColor: "#D1AE6C", // cattle pricing profit default color
        cardSltColor: "#D1AE6C", // sales triggers card profit default color
        cardMtxColor: "#D1AE6C", // matrix card profit default color
        cardIopColor: "#D1AE6C", // incomes card profit default color
        cardOpmColor: "#D1AE6C", // roi card profit default color
        cardHisColor: "#D1AE6C", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    },
    {
      name: "cotton",
      light: {
        primary: "#2C3F7A", //headers
        secondary: "#2C3F7A", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#2C3F7A", // main card profit default color
        cardHarColor: "#2C3F7A", // harvest card profit default color
        cardGroColor: "#2C3F7A", // growing card profit default color
        cardProColor: "#2C3F7A", // ownership card profit default color
        cardExwColor: "#2C3F7A", // exw card profit default color
        cardLogColor: "#2C3F7A", // logistic card profit default color
        cardPpeColor: "#2C3F7A", // ppe card profit default color
        cardCprColor: "#2C3F7A", // cattle pricing profit default color
        cardSltColor: "#2C3F7A", // sales triggers card profit default color
        cardMtxColor: "#2C3F7A", // matrix card profit default color
        cardIopColor: "#2C3F7A", // incomes card profit default color
        cardOpmColor: "#2C3F7A", // roi card profit default color
        cardHisColor: "#2C3F7A", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    },
    {
      name: "cattle",
      light: {
        primary: "#990000", //headers
        secondary: "#990000", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#990000", // main card profit default color
        cardHarColor: "#990000", // harvest card profit default color
        cardGroColor: "#990000", // growing card profit default color
        cardProColor: "#990000", // ownership card profit default color
        cardExwColor: "#990000", // exw card profit default color
        cardLogColor: "#990000", // logistic card profit default color
        cardPpeColor: "#990000", // ppe card profit default color
        cardCprColor: "#990000", // cattle pricing profit default color
        cardSltColor: "#990000", // sales triggers card profit default color
        cardMtxColor: "#990000", // matrix card profit default color
        cardIopColor: "#990000", // incomes card profit default color
        cardOpmColor: "#990000", // roi card profit default color
        cardHisColor: "#990000", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    }
  ],
  agroamazonia: [
    {
      name: "soybean",
      light: {
        primary: "#00a859", //headers
        secondary: "#066F6A", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#2950a4", // main card profit default color
        cardHarColor: "#a8cf45", // harvest card profit default color
        cardGroColor: "#a8cf45", // growing card profit default color
        cardProColor: "#a8cf45", // ownership card profit default color
        cardExwColor: "#00a859", // exw card profit default color
        cardLogColor: "#00a859", // logistic card profit default color
        cardPpeColor: "#00a859", // ppe card profit default color
        cardCprColor: "#00a859", // cattle pricing profit default color
        cardSltColor: "#00a859", // sales triggers card profit default color
        cardMtxColor: "#2950a4", // matrix card profit default color
        cardIopColor: "#00a859", // incomes card profit default color
        cardOpmColor: "#2950a4", // roi card profit default color
        cardHisColor: "#00a859", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    },
    {
      name: "corn",
      light: {
        primary: "#D1AE6C", //headers
        secondary: "#066F6A", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#2950a4", // main card profit default color
        cardHarColor: "#a8cf45", // harvest card profit default color
        cardGroColor: "#a8cf45", // growing card profit default color
        cardProColor: "#a8cf45", // ownership card profit default color
        cardExwColor: "#00a859", // exw card profit default color
        cardLogColor: "#00a859", // logistic card profit default color
        cardPpeColor: "#00a859", // ppe card profit default color
        cardCprColor: "#00a859", // cattle pricing profit default color
        cardSltColor: "#00a859", // sales triggers card profit default color
        cardMtxColor: "#2950a4", // matrix card profit default color
        cardIopColor: "#00a859", // incomes card profit default color
        cardOpmColor: "#2950a4", // roi card profit default color
        cardHisColor: "#00a859", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    },
    {
      name: "cotton",
      light: {
        primary: "#2C3F7A", //headers
        secondary: "#066F6A", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#2950a4", // main card profit default color
        cardHarColor: "#a8cf45", // harvest card profit default color
        cardGroColor: "#a8cf45", // growing card profit default color
        cardProColor: "#a8cf45", // ownership card profit default color
        cardExwColor: "#00a859", // exw card profit default color
        cardLogColor: "#00a859", // logistic card profit default color
        cardPpeColor: "#00a859", // ppe card profit default color
        cardCprColor: "#00a859", // cattle pricing profit default color
        cardSltColor: "#00a859", // sales triggers card profit default color
        cardMtxColor: "#2950a4", // matrix card profit default color
        cardIopColor: "#00a859", // incomes card profit default color
        cardOpmColor: "#2950a4", // roi card profit default color
        cardHisColor: "#00a859", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    },
    {
      name: "cattle",
      light: {
        primary: "#00a859", //headers
        secondary: "#066F6A", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
        background: "#FFFFFF", // background
        textColor: "#066F6A", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#2950a4", // main card profit default color
        cardHarColor: "#a8cf45", // harvest card profit default color
        cardGroColor: "#a8cf45", // growing card profit default color
        cardProColor: "#a8cf45", // ownership card profit default color
        cardExwColor: "#00a859", // exw card profit default color
        cardLogColor: "#00a859", // logistic card profit default color
        cardPpeColor: "#00a859", // ppe card profit default color
        cardCprColor: "#00a859", // cattle pricing profit default color
        cardSltColor: "#00a859", // sales triggers card profit default color
        cardMtxColor: "#2950a4", // matrix card profit default color
        cardIopColor: "#00a859", // incomes card profit default color
        cardOpmColor: "#2950a4", // roi card profit default color
        cardHisColor: "#00a859", // history card profit default color
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#D1AE6C", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
        background: "#1E1E1E", // background
        textColor: "#FFFFFF", // text
        soyColor: "#066F6A", // soybean default color
        cornColor: "#D1AE6C", // corn default color
        cottonColor: "#2C3F7A", // cotton default color
        cattleColor: "#990000", // cattle default color
        cardMomColor: "#4D4D4D", // main card profit default color
        cardHarColor: "#4D4D4D", // harvest card profit default color
        cardGroColor: "#4D4D4D", // growing card profit default color
        cardProColor: "#4D4D4D", // ownership card profit default color
        cardExwColor: "#4D4D4D", // exw card profit default color
        cardLogColor: "#4D4D4D", // logistic card profit default color
        cardPpeColor: "#4D4D4D", // ppe card profit default color
        cardCprColor: "#4D4D4D", // cattle pricing profit default color
        cardSltColor: "#4D4D4D", // sales triggers card profit default color
        cardMtxColor: "#4D4D4D", // matrix card profit default color
        cardIopColor: "#4D4D4D", // incomes card profit default color
        cardOpmColor: "#4D4D4D", // roi card profit default color
        cardHisColor: "#4D4D4D", // history card profit default color
      },
    }
  ]
}