import { SAVE_UPDATE, DELETE } from "@/store/actions-types";
import { SET_RESOURCE } from "@/store/mutations-types";
import { crudService } from "@/services/crud";
import { preperaSerializable } from '@/utilsObject';
import { ICrudModule, IRootState } from "@/types/store-types";
import { ActionTree, MutationTree } from "vuex";

// Shorthands for easier type building
type S = ICrudModule;
type R = IRootState;

class State implements ICrudModule {
  resource: undefined;
}

const mutations: MutationTree<S> = {
  [SET_RESOURCE]: (state, value) => {
    state.resource = value;
  },
};

const actions: ActionTree<S, R> = {
  [SAVE_UPDATE]: ({ state }, data) => {
    return new Promise((resolve, reject) => {
      if(state.resource){
        data = preperaSerializable(data);
        crudService.save(state.resource, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }else {
        console.error('SAVE_UPDATE: no resource to endpoint');
        reject("no resource");
      }
    });
  },
  [DELETE]: ({ state }, id) => {
    return new Promise((resolve, reject) => {
      if(state.resource){
        crudService.remove(state.resource, id)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }else {
        console.error('DELETE: no resource to endpoint');
        reject("no resource");
      }
    });
  },
};

const getters = {};

export default {
  state: new State(),
  mutations,
  actions,
  getters,
};
