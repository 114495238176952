import utils from './utils';
import tradeslip from './tradeslip'
import crud from './crud'
import admin from './admin'
import analytics from './analytics'
import profit from './profit'
import { RouteConfig } from 'vue-router';


const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import('@/views/Index.vue'),
    children: [
      {
        name: 'home',
        path: '',
        beforeEnter: utils.checkAuth,
        component: () => import('@/views/Home.vue'),
        meta: {
          admin: true,
          broker: true,
          N1: true,
          N2: true,
          N3: true
        }
      },
      crud,
      tradeslip,
      // Admininstration
      admin,
      analytics,
      // PROFIT
      profit,
    ]
  },
  {
    path: '/authenticate',
    name: 'authenticate',
    beforeEnter: utils.auth
  },
  {
    path: "*",
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: "/unauthorized",
    component: () => import('@/views/Unauthorized.vue'),
  },

];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export { routes };
