import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";

export interface IPricing {
  cbot_flat_price_ppe?: number;
  cbot_ppe_exw_rs_sc?: number;
  cbot_ppe_exw_us_sc?: number;
  cbot_ppe_fca_rs_sc?: number;
  cbot_ppe_fca_us_sc?: number;
  cbot_flat_price_local_basis?: number;
  cbot_local_basis_exw_rs_sc?: number;
  cbot_local_basis_exw_us_sc?: number;
  // corn
  b3_flat_price_local_basis?: number;
  b3_local_basis_exw_rs_sc?: number;
  b3_local_basis_exw_us_sc?: number;
  // cotton
  nybot_flat_price?: number;
  nybot_ppe_exw_rs_arroba?: number;
  nybot_ppe_exw_us_arroba?: number;
  nybot_ppe_fca_rs_arroba?: number;
  nybot_ppe_fca_us_arroba?: number;
}

export class PricingDTO implements IPricing {
  cbot_flat_price_ppe: number | undefined = 0;
  cbot_ppe_exw_rs_sc: number | undefined = 0;
  cbot_ppe_exw_us_sc: number | undefined = 0;
  cbot_ppe_fca_rs_sc: number | undefined = 0;
  cbot_ppe_fca_us_sc: number | undefined = 0;
  cbot_flat_price_local_basis: number | undefined = 0;
  cbot_local_basis_exw_rs_sc: number | undefined = 0;
  cbot_local_basis_exw_us_sc: number | undefined = 0;
  // corn
  b3_flat_price_local_basis: number | undefined = 0;
  b3_local_basis_exw_rs_sc: number | undefined = 0;
  b3_local_basis_exw_us_sc: number | undefined = 0;
  // cotton
  nybot_flat_price: number | undefined = 0;
  nybot_ppe_exw_rs_arroba: number | undefined = 0;
  nybot_ppe_exw_us_arroba: number | undefined = 0;
  nybot_ppe_fca_rs_arroba: number | undefined = 0;
  nybot_ppe_fca_us_arroba: number | undefined = 0;

  constructor(dto?: IPricing) {
    if(dto && !isEmptyObject(dto)) {
      this.cbot_flat_price_ppe = stringToNumberOrUndefined(dto?.cbot_flat_price_ppe);
      this.cbot_ppe_exw_rs_sc = stringToNumberOrUndefined(dto?.cbot_ppe_exw_rs_sc);
      this.cbot_ppe_exw_us_sc = stringToNumberOrUndefined(dto?.cbot_ppe_exw_us_sc);
      this.cbot_ppe_fca_rs_sc = stringToNumberOrUndefined(dto?.cbot_ppe_fca_rs_sc);
      this.cbot_ppe_fca_us_sc = stringToNumberOrUndefined(dto?.cbot_ppe_fca_us_sc);
      this.cbot_flat_price_local_basis = stringToNumberOrUndefined(dto?.cbot_flat_price_local_basis);
      this.cbot_local_basis_exw_rs_sc = stringToNumberOrUndefined(dto?.cbot_local_basis_exw_rs_sc);
      this.cbot_local_basis_exw_us_sc = stringToNumberOrUndefined(dto?.cbot_local_basis_exw_us_sc);
      this.b3_flat_price_local_basis = stringToNumberOrUndefined(dto?.b3_flat_price_local_basis);
      this.b3_local_basis_exw_rs_sc = stringToNumberOrUndefined(dto?.b3_local_basis_exw_rs_sc);
      this.b3_local_basis_exw_us_sc = stringToNumberOrUndefined(dto?.b3_local_basis_exw_us_sc);
      this.nybot_flat_price = stringToNumberOrUndefined(dto?.nybot_flat_price);
      this.nybot_ppe_exw_rs_arroba = stringToNumberOrUndefined(dto?.nybot_ppe_exw_rs_arroba);
      this.nybot_ppe_exw_us_arroba = stringToNumberOrUndefined(dto?.nybot_ppe_exw_us_arroba);
      this.nybot_ppe_fca_rs_arroba = stringToNumberOrUndefined(dto?.nybot_ppe_fca_rs_arroba);
      this.nybot_ppe_fca_us_arroba = stringToNumberOrUndefined(dto?.nybot_ppe_fca_us_arroba);
    }
  }
}

export class Pricing extends PricingDTO {
  constructor();
  constructor(pricing: IPricing);
  constructor(pricing?: IPricing) {
    super(pricing);
  }
}

export interface ICattlePricing {
  [keys: string]: number | string | boolean | undefined;

  id?: number;
  b3_costs: number;
  b3_price: number;
  basis: number;
  basis_fit: number;
  basis_region: string;
  bonus: number;
  is_forward_contract: boolean;
  local_price_ref: number;
}

export class CattlePricingDTO implements ICattlePricing {
  [keys: string]: number | string | boolean | undefined;

  id: number | undefined = undefined;
  b3_costs = 0;
  b3_price = 0;
  basis = 0;
  basis_fit = 0;
  basis_region = "";
  bonus = 0;
  is_forward_contract = false;
  local_price_ref = 0;

  constructor(dto?: ICattlePricing) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.b3_costs = stringToNumber(dto.b3_costs);
      this.b3_price = stringToNumber(dto.b3_price);
      this.basis = stringToNumber(dto.basis);
      this.basis_fit = stringToNumber(dto.basis_fit);
      this.basis_region = dto.basis_region;
      this.bonus = stringToNumber(dto.bonus);
      this.is_forward_contract = dto.is_forward_contract;
      this.local_price_ref = stringToNumber(dto.local_price_ref);
    }
  }
}

export class CattlePricing extends CattlePricingDTO {
  private static resource = "cattle_pricing";

  constructor();
  constructor(cattlePricing: ICattlePricing);
  constructor(cattlePricing?: ICattlePricing) {
    super(cattlePricing);
  }
  public static getResource(): string {
    return this.resource;
  }
}
