import { isEmptyObject } from "@/utilsObject";
import { LotInfoProfit } from "./CattleProfit";
import { CattlePricing } from "./Pricing";
import { CattleQuotations } from "./Quotations";

export interface ILotSimpleProfit {
  id: number;
  name: string;
  lot_id: number
  price: CattlePricing;
  profit: LotInfoProfit;
  quotations: CattleQuotations;
}

export class LotSimpleProfitDTO implements ILotSimpleProfit {
  id = 0;
  name = ""
  lot_id = 0;
  price = new CattlePricing();
  profit = new LotInfoProfit();
  quotations = new CattleQuotations();

  constructor(dto?: ILotSimpleProfit) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.lot_id = dto.lot_id;
      this.price = new CattlePricing(dto.price);
      this.profit = new LotInfoProfit(dto.profit);
      this.quotations = new CattleQuotations(dto.quotations);
    }
  }
}

export class LotSimpleProfit extends LotSimpleProfitDTO {
  constructor();
  constructor(lotSimpleProfit: ILotSimpleProfit);
  constructor(lotSimpleProfit?: ILotSimpleProfit) {
    super(lotSimpleProfit);
  }
}

export interface ICattleSimpleProfit {
  id: number;
  name: string;
  lot: LotSimpleProfit;
  barQuotes: Array<CattleQuotations>
}

export class CattleSimpleProfitDTO implements ICattleSimpleProfit {
  id = 0;
  name = "";
  lot = new LotSimpleProfit();
  barQuotes = [] as CattleQuotations[];

  constructor(dto?: ICattleSimpleProfit) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.lot = new LotSimpleProfit(dto.lot);
      this.barQuotes = dto.barQuotes.map(el => new CattleQuotations(el));
    }
  }
}

export class CattleSimpleProfit extends CattleSimpleProfitDTO {
  constructor();
  constructor(cattleSimpleProfit: ICattleSimpleProfit);
  constructor(cattleSimpleProfit?: ICattleSimpleProfit) {
    super(cattleSimpleProfit);
  }
}
