import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Country from "./country";

export interface IState extends IModelBase {
  abbreviation: string;
  name: string;
  country_id: number;

  /**Relationship classes */
  country?: Country;
}

export class StateDTO extends ModelBase implements IState {
  abbreviation = "";
  name = "";
  country_id = 0;

  country = new Country();

  constructor(dto?: IState) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.abbreviation = dto.abbreviation;
      this.name = dto.name;
      this.country_id = dto.country_id;

      if(dto.country)
        this.country = new Country(dto.country);
    }
  }
}

export default class State extends StateDTO {
  constructor();
  constructor(state: IState);
  constructor(state?: IState) {
    super(state);
  }
}
