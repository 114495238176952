import Vue from 'vue'
import { months } from "@/constants";

import {
  PRE_LOAD,
  POS_LOAD,
  PRICING_LOAD,
  EXCHANGE_LOAD,
  SEARCH_TRADESLIP_DELIVERY_PERIOD,
  SEARCH_TRADESLIP_EXCHANGE_ACC,
  LOAD_CONTRACT_RESULT
} from "@/store/actions-types";

import {
  UPDATE_LIST,
  REMOVE_LIST,
  SET_ALL_PROVIDERS,
  SET_BROKERAGEFIRMS,
  SET_BROKERAGE_ACCOUNT,
  SET_SELLERS,
  SET_BUYERS,
  SET_BANKS,
  SET_SILOS,
  SET_INCOTERMS,
  SET_CONTRACT_SILOS,
  SET_CONTRACT_DELIVERIES,
  SET_CONTRACT_PRICINGS,
  SET_CONTRACT_PRICING_DETAILS,
  SET_CONTRACT_PRICING_COMPONENTS,
  SET_CONTRACT_EXCHANGE_ACC,
  SET_CONTRACT_EXCHANGE_NDF,
  SET_CONTRACT_EXCHANGE_EXPORT_LOCK,
  SET_CONTRACT_EXCHANGE_DOLLAR_FUTURES,
  SET_CONTRACT_RESULT
} from "@/store/mutations-types";

import { tradeslipService } from '@/services/tradeslip';
import { IParams, IRootState, ITradeslipModule, TradeslipGetterTypes } from '@/types/store-types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import BrokerageAccount from '@/models/brokerageAccount';
import Silo from '@/models/silo';
import Incoterm from '@/models/incoterm';
import Provider from '@/models/provider';
import TradeslipSilo from '@/models/tradeslipSilo';
import TradeslipExchangeNdf from '@/models/tradeslipExchangeNdf';
import TradeslipPricingDetail from '@/models/tradeslipPricingDetail';
import TradeslipPricingComponent from '@/models/tradeslipPricingComponent';
import TradeslipPricing from '@/models/tradeslipPricing';
import TradeslipExchangeAcc from '@/models/tradeslipExchangeAcc';
import TradeslipDeliveryPeriod from '@/models/tradeslipDeliveryPeriod';
import TradeslipExchangeExportLock from '@/models/tradeslipExchangeExportLock';
import TradeslipExchangeDollarFutures from '@/models/tradeslipExchangeDollarFutures';

// Shorthands for easier type building
type S = ITradeslipModule;
type R = IRootState;

interface IPaylodTradeslipArrays {
  array: "allProviders"
  | "brokerageFirms"
  | "brokerageAccounts"
  | "sellers"
  | "buyers"
  | "banks"
  | "silos"
  | "incoterms"
  | "brokerageAccount"
  | "contractSilos"
  | "contractDeliveries"
  | "contractPricings"
  | "allContractPricingDetails"
  | "contractPricingComponents"
  | "accs"
  | "ndfs"
  | "exportLocks"
  | "dollarFutures",
  item: Provider
  | BrokerageAccount
  | Silo
  | Incoterm
  | TradeslipSilo
  | TradeslipDeliveryPeriod
  | TradeslipPricing
  | TradeslipPricingDetail
  | TradeslipPricingComponent
  | TradeslipExchangeAcc
  | TradeslipExchangeNdf
  | TradeslipExchangeExportLock
  | TradeslipExchangeDollarFutures
}

const securities = () => {
  const today = new Date();
  const result = [];
  let year = today.getFullYear().toString().substr(-2);
  // securities do ano atual
  for(let i=today.getMonth();i<months.length;i++){
    result.push(`${months[i]}${year}`)
  }
  year = (today.getFullYear()+1).toString().substr(-2);
  // securities do proximo ano
  for(let i=0;i<today.getMonth();i++){
    result.push(`${months[i]}${year}`)
  }
  return result;
}

class State implements ITradeslipModule {
  quering = false;
  stockContracts = securities();
  // Resources Lists
  allProviders: Provider[] = [];
  brokerageFirms: Provider[] = [];
  brokerageAccounts: BrokerageAccount[] = [];
  sellers: Provider[] = [];
  buyers: Provider[] = [];
  banks: Provider[] = [];
  silos: Silo[] = [];
  incoterms: Incoterm[] = [];
  brokerageAccount: BrokerageAccount[] = [];
  // Page Resources
  contractSilos: TradeslipSilo[] = [];
  contractDeliveries: TradeslipDeliveryPeriod[] = [];
  contractPricings: TradeslipPricing[] = [];
  allContractPricingDetails: TradeslipPricingDetail[] = [];
  contractPricingComponents: TradeslipPricingComponent[] = [];
  // Exchange
  accs: TradeslipExchangeAcc[] = [];
  ndfs: TradeslipExchangeNdf[] = [];
  exportLocks: TradeslipExchangeExportLock[] = [];
  dollarFutures: TradeslipExchangeDollarFutures[] = [];
  // Result
  contractResultInfo: undefined
}

const getters: GetterTree<S, R> = {
  [TradeslipGetterTypes.getQuering]: state => state.quering,
  [TradeslipGetterTypes.getAllProviders]: state => state.allProviders,
  [TradeslipGetterTypes.getBrokerageFirms]: state => state.brokerageFirms,
  [TradeslipGetterTypes.getBrokerageAccount]: state => state.brokerageAccounts,
  [TradeslipGetterTypes.getSellers]: state => state.sellers,
  [TradeslipGetterTypes.getBuyers]: state => state.buyers,
  [TradeslipGetterTypes.getBanks]: state => state.banks,
  [TradeslipGetterTypes.getSilos]: state => state.silos,
  [TradeslipGetterTypes.getIncoterms]: state => {
    const groupEF = state.incoterms.filter(el => ["E", "F"].indexOf(el.group) !== -1)
    return groupEF.filter(el => el.name !== 'FCA')
  },
  [TradeslipGetterTypes.getContractSilos]: state => state.contractSilos,
  [TradeslipGetterTypes.getContractDeliveries]: state => state.contractDeliveries,
  [TradeslipGetterTypes.getContractPricings]: state => state.contractPricings,
  [TradeslipGetterTypes.getAllContractPricingDetails]: state => state.allContractPricingDetails,
  [TradeslipGetterTypes.getContractPricingComponents]: state => state.contractPricingComponents,
  [TradeslipGetterTypes.getAccs]: state => state.accs,
  [TradeslipGetterTypes.getNDFs]: state => state.ndfs,
  [TradeslipGetterTypes.getExportLocks]: state => state.exportLocks,
  [TradeslipGetterTypes.getDollarFutures]: state => state.dollarFutures,
  [TradeslipGetterTypes.getStockContracts]: state => state.stockContracts,
  [TradeslipGetterTypes.getContractResult]: state => state.contractResultInfo,
}

const actions: ActionTree<S, R> = {
  [PRE_LOAD]: async ({ dispatch, commit, state }) => {
    state.quering = true;
    const payload = {
      resource: Provider.getResource(),
      params: {
        my: true,
        checkDelete: true
      }
    };
    try {
      // Forncedores/clientes/vendedor
      let result = await dispatch('SIMPLE_SEARCH', payload);
      if(result && result.length > 0) {
        commit(SET_ALL_PROVIDERS, result);
        commit(SET_BROKERAGEFIRMS, result.filter((r: Provider) => r.service === 'BROKER'));
        commit(SET_SELLERS, result.filter((b: Provider) => b.service === 'INCOMPANY'));
        commit(SET_BUYERS, result.filter((b: Provider) => b.service !== 'INCOMPANY' && b.service !== 'BROKER'));
        commit(SET_BANKS, result.filter((r: Provider) => r.service === 'BANK'));
      }
      payload.resource = 'incoterm'
      result = await dispatch('SIMPLE_SEARCH', payload);
      if(result && result.length > 0) {
        commit(SET_ALL_PROVIDERS, result);
      }
      payload.resource = 'incoterm'
      result = await dispatch('SIMPLE_SEARCH', payload);
      if(result && result.length > 0) {
        commit(SET_INCOTERMS, result);
      }
      payload.resource = 'silo'
      result = await dispatch('SIMPLE_SEARCH', payload);
      if(result && result.length > 0) {
        commit(SET_SILOS, result);
      }
      payload.resource = 'brokerage_account'
      result = await dispatch('SIMPLE_SEARCH', payload);
      if(result && result.length > 0) {
        commit(SET_BROKERAGE_ACCOUNT, result);
      }
      state.quering = false;
      return;
    }catch (e) {
      state.quering = false;
      dispatch('AGR_ALERT', {
        message: "Houve um problema ao carregar as informações",
        color: "error",
      });
      console.log('error - pre load');
      console.log(e);
      return;
    }
  },
  [POS_LOAD]: async ({ dispatch, commit, state }, tradeslip_id) => {
    state.quering = true;
    const payload = {
      resource: TradeslipPricingComponent.getResource(),
      params: {}
    };
    try {
      let result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_PRICING_COMPONENTS, result);
      payload.resource = 'tradeslip_silo';
      payload.params = {tradeslip_id: tradeslip_id};
      result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_SILOS, result);
      payload.resource = 'tradeslip_delivery_period';
      result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_DELIVERIES, result);
      state.quering = false;
      return;
    }catch (e) {
      state.quering = false;
      dispatch('AGR_ALERT', {
        message: "Houve um problema ao carregar as informações",
        color: "error",
      });
      console.log('error - pos load');
      console.log(e);
      return;
    }
  },
  [PRICING_LOAD]: async ({ dispatch, commit, state }, tradeslip_id) => {
    state.quering = true;
    const payload = {
      resource: TradeslipPricing.getResource(),
      params: {
        tradeslip_id: tradeslip_id
      }
    };
    try {
      let result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_PRICINGS, result);
      payload.resource = 'tradeslip_pricing_detail';
      result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_PRICING_DETAILS, result);
      state.quering = false;
      return;
    }catch (e) {
      state.quering = false;
      dispatch('AGR_ALERT', {
        message: "Houve um problema ao carregar as informações",
        color: "error",
      });
      console.log('error - pos load');
      console.log(e);
      return;
    }
  },
  [EXCHANGE_LOAD]: async ({ dispatch, commit, state }, tradeslip_id) => {
    state.quering = true;
    const payload = {
      resource: TradeslipExchangeAcc.getResource(),
      params: {
        tradeslip_id: tradeslip_id
      }
    };
    try {
      let result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_EXCHANGE_ACC, result);
      payload.resource = 'tradeslip_exchange_ndf'
      result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_EXCHANGE_NDF, result);
      payload.resource = 'tradeslip_exchange_dollar_futures'
      result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_EXCHANGE_DOLLAR_FUTURES, result);
      payload.resource = 'tradeslip_exchange_export_lock'
      result = await dispatch('SIMPLE_SEARCH', payload);
      commit(SET_CONTRACT_EXCHANGE_EXPORT_LOCK, result);
      state.quering = false;
      return;
    }catch (e) {
      state.quering = false;
      dispatch('AGR_ALERT', {
        message: "Houve um problema ao carregar as informações",
        color: "error",
      });
      console.log('error - pos load');
      console.log(e);
      return;
    }
  },
  [SEARCH_TRADESLIP_DELIVERY_PERIOD]: async ({ dispatch, commit, state }, tradeslip_id) => {
    state.quering = true;
    const payload = {
      resource: TradeslipDeliveryPeriod.getResource(),
      params: {
        tradeslip_id: tradeslip_id
      }
    };
    const result = await dispatch('SIMPLE_SEARCH', payload);
    commit(SET_CONTRACT_DELIVERIES, result);
    state.quering = false;
    return;
  },
  [SEARCH_TRADESLIP_EXCHANGE_ACC]: async ({ dispatch, commit, state }, tradeslip_id) => {
    state.quering = true;
    const payload = {
      resource: TradeslipExchangeAcc.getResource(),
      params: {
        tradeslip_id: tradeslip_id
      }
    };
    const result = await dispatch('SIMPLE_SEARCH', payload);
    commit(SET_CONTRACT_EXCHANGE_ACC, result);
    state.quering = false;
    return;
  },
  [LOAD_CONTRACT_RESULT]: ({ commit }, tradeslip_id) => {
    return new Promise<void>((resolve, reject) => {
      const params: IParams = {
        tradeslip_id: tradeslip_id,
        checkDelete: true
      }
      tradeslipService.processResult(params).then((response) => {
        commit(SET_CONTRACT_RESULT, response);
        resolve();
      }).catch((error) => {
        console.error(error);
        reject();
      });
    });
  }
}

const mutations: MutationTree<S> = {
  [UPDATE_LIST]: (state, payload: IPaylodTradeslipArrays) => {
    const item = payload.item;
    const index = state[payload.array].findIndex(element => element.id === item.id);
    if(index > -1)
      Vue.set(state[payload.array], index , item);
    else
      Vue.set(state[payload.array], 0 , item);
  },
  [REMOVE_LIST]: (state, payload: IPaylodTradeslipArrays) => {
    const item = payload.item;
    const index = state[payload.array].findIndex(element => element.id === item.id);
    if(index > -1)
      state[payload.array].splice(index, 1);
  },
  [SET_ALL_PROVIDERS]: (state, value) => {
      state.allProviders = value
  },
  [SET_BROKERAGEFIRMS]: (state, value) => {
      state.brokerageFirms = value
  },
  [SET_BROKERAGE_ACCOUNT]: (state, value) => {
      state.brokerageAccounts = value
  },
  [SET_SELLERS]: (state, value) => {
      state.sellers = value
  },
  [SET_BUYERS]: (state, value) => {
      state.buyers = value
  },
  [SET_BANKS]: (state, value) => {
      state.banks = value
  },
  [SET_SILOS]: (state, value) => {
      state.silos = value
  },
  [SET_INCOTERMS]: (state, value) => {
      state.incoterms = value
  },
  [SET_CONTRACT_SILOS]: (state, value) => {
      state.contractSilos = value
  },
  [SET_CONTRACT_DELIVERIES]: (state, value) => {
      state.contractDeliveries = value
  },
  [SET_CONTRACT_PRICINGS]: (state, value) => {
      state.contractPricings = value
  },
  [SET_CONTRACT_PRICING_DETAILS]: (state, value) => {
      state.allContractPricingDetails = value
  },
  [SET_CONTRACT_PRICING_COMPONENTS]: (state, value) => {
      state.contractPricingComponents = value
  },
  [SET_CONTRACT_EXCHANGE_ACC]: (state, value) => {
      state.accs = value
  },
  [SET_CONTRACT_EXCHANGE_NDF]: (state, value) => {
      state.ndfs = value
  },
  [SET_CONTRACT_EXCHANGE_EXPORT_LOCK]: (state, value) => {
      state.exportLocks = value
  },
  [SET_CONTRACT_EXCHANGE_DOLLAR_FUTURES]: (state, value) => {
      state.dollarFutures = value
  },
  [SET_CONTRACT_RESULT]: (state, value) => {
      state.contractResultInfo = value
  },
}

export default {
  state: new State(),
  mutations,
  actions,
  getters,
};