import store from "@/store";
import Growing from "./growing";
import Ownership from "./ownership";

import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { LotSimpleProfit } from "@/business/CattleSimpleProfit";
import { IModelBase, ModelBase } from "./base";

export interface ICattleLot extends IModelBase {
  name: string;
  amount: number;
  gender: string;
  cycle: string;
  initial_date_breed?: string;
  initial_thin_weight?: number;
  breed_period?: number;
  dayly_weight_gain_breed?: number;
  final_weight_breed?: number;
  initial_date_finishing: string;
  initial_weight_finishing?: number;
  finishing_period: number;
  dayly_weight_gain_finishing: number;
  slaughter_date: string;
  final_weight_finishing: number;
  yield_carcass: number;
  final_weight: number;
  total_weight_lot: number;
  is_deleted: boolean;
  deleted_date?: string;
  ownership_id: number;
  growing_id: number;

  /**Relationship classes */
  ownership?: Ownership;
  growing?: Growing;
}

export class CattleLotDTO extends ModelBase implements ICattleLot {
  name = "";
  amount = 0;
  gender = "MALE";
  cycle = "BREED_FINISHING";
  initial_date_breed?: string | undefined = undefined;
  initial_thin_weight?: number | undefined = undefined;
  breed_period?: number | undefined = undefined;
  dayly_weight_gain_breed?: number | undefined = undefined;
  final_weight_breed?: number | undefined = undefined;
  initial_date_finishing = "";
  initial_weight_finishing?: number | undefined = undefined;
  finishing_period = 0;
  dayly_weight_gain_finishing = 0;
  slaughter_date = "";
  final_weight_finishing = 0;
  yield_carcass = 0;
  final_weight = 0;
  total_weight_lot = 0;
  is_deleted = false;
  deleted_date?: string | undefined = undefined;
  ownership_id = 0;
  growing_id = 0;

  ownership = new Ownership();
  growing = new Growing();

  constructor(dto?: ICattleLot) {
    super(dto);
    if (dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.amount = dto.amount;
      this.gender = dto.gender;
      this.cycle = dto.cycle;
      this.initial_date_breed = dto.initial_date_breed;
      this.initial_thin_weight = stringToNumberOrUndefined(
        dto.initial_thin_weight
      );
      this.breed_period = dto.breed_period;
      this.dayly_weight_gain_breed = stringToNumberOrUndefined(
        dto.dayly_weight_gain_breed
      );
      this.final_weight_breed = stringToNumberOrUndefined(
        dto.final_weight_breed
      );
      this.initial_date_finishing = dto.initial_date_finishing;
      this.initial_weight_finishing = stringToNumberOrUndefined(
        dto.initial_weight_finishing
      );
      this.finishing_period = dto.finishing_period;
      this.dayly_weight_gain_finishing = stringToNumber(
        dto.dayly_weight_gain_finishing
      );
      this.slaughter_date = dto.slaughter_date;
      this.final_weight_finishing = stringToNumber(dto.final_weight_finishing);
      this.yield_carcass = stringToNumber(dto.yield_carcass);
      this.final_weight = stringToNumber(dto.final_weight);
      this.total_weight_lot = stringToNumber(dto.total_weight_lot);
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto.deleted_date;
      this.ownership_id = dto.ownership_id;
      this.growing_id = dto.growing_id;

      if (dto.ownership) this.ownership = new Ownership(dto.ownership);
      if (dto.growing) this.growing = new Growing(dto.growing);
    } else {
      this.findBeefCattleId();
    }
  }

  async findBeefCattleId(): Promise<void> {
    const id = await store.dispatch("GET_BEEF_CATTLE_ID");
    if (id) {
      if (this.growing_id === 0) this.growing_id = id;
    } else {
      throw new Error("No id found for Beef Cattle instance!");
    }
  }
}

export default class CattleLot extends CattleLotDTO {
  private static resource = "cattle_lot";

  constructor();
  constructor(cattleLot: ICattleLot);
  constructor(cattleLot?: ICattleLot) {
    super(cattleLot);
  }

  public static getResource(): string {
    return this.resource;
  }

  public setLotInfoFromSimpleProfit(lotInfo: LotSimpleProfit): void {
    this.id = lotInfo.id;
    this.name = lotInfo.name;
    this.amount = lotInfo.profit.animal_amount;
    this.yield_carcass = lotInfo.profit.yield_carcass;
    this.slaughter_date = lotInfo.profit.slaughter_date;
    this.final_weight =
      (lotInfo.profit.slaughter_weight * (lotInfo.profit.yield_carcass / 100)) /
      15; // Arrobas
  }
}

interface ICattleLotExtraInfo {
  tooltip?: string;
  color?: string;
}

export class CattleLotExtraInfo
  extends CattleLot
  implements ICattleLotExtraInfo
{
  tooltip: string | undefined = undefined;
  color: string | undefined = undefined;

  constructor(cattleLot: CattleLot, _tooltip: string, _color: string) {
    super(cattleLot);
    this.tooltip = _tooltip;
    this.color = _color;
  }
}
