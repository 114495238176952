import { CattlePricing } from "@/business/Pricing";
import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface IProfitN1 extends IModelBase {
  production_cost: number;
  productivity?: number;
  total_area?: number;
  shipping?: number;
  fobbings?: number;
  animal_amount?: number;
  final_weight_finishing?: number;
  yield_carcass?: number;
  slaughter_date?: string;
  basis?: number;
  is_forward_contract: boolean;
  basis_fit?: number;
  bonus?: number;
  local_price_ref?: number;
  b3_price?: number;
  b3_costs?: number;
  basis_region?: string;
  user_id: number;
  ownership_id: number;
  harvest_id?: number;
  growing_id: number;
  sold_amount: number;
  sold_rs_sc: number;
  sold_us_sc: number;
  sold_value_rs: number;
  sold_value_us: number;
}

export class ProfitN1DTO extends ModelBase implements IProfitN1 {
  production_cost = 0;
  productivity: number | undefined = undefined;
  total_area: number | undefined = undefined;
  shipping: number | undefined = undefined;
  fobbings: number | undefined = undefined;
  animal_amount: number | undefined = undefined;
  final_weight_finishing: number | undefined = undefined;
  yield_carcass: number | undefined = undefined;
  slaughter_date: string | undefined = undefined;
  basis: number | undefined = undefined;
  is_forward_contract = false;
  basis_fit: number | undefined = undefined;
  bonus: number | undefined = undefined;
  local_price_ref: number | undefined = undefined;
  b3_price: number | undefined = undefined;
  b3_costs: number | undefined = undefined;
  basis_region: string | undefined = undefined;
  user_id = 0;
  ownership_id = 0;
  harvest_id: number | undefined = undefined;
  growing_id = 0;
  sold_amount = 0;
  sold_rs_sc = 0;
  sold_us_sc = 0;
  sold_value_rs = 0;
  sold_value_us = 0;

  constructor(dto?: IProfitN1) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.production_cost = stringToNumber(dto.production_cost);
      this.productivity = stringToNumberOrUndefined(dto.productivity);
      this.total_area = stringToNumberOrUndefined(dto.total_area);
      this.shipping = stringToNumberOrUndefined(dto.shipping);
      this.fobbings = dto.fobbings;
      this.animal_amount = stringToNumberOrUndefined(dto.animal_amount);
      this.final_weight_finishing = stringToNumberOrUndefined(dto.final_weight_finishing);
      this.yield_carcass = stringToNumberOrUndefined(dto.yield_carcass);
      this.slaughter_date = dto.slaughter_date;
      this.basis = stringToNumberOrUndefined(dto.basis);
      this.is_forward_contract = dto.is_forward_contract;
      this.basis_fit = stringToNumberOrUndefined(dto.basis_fit);
      this.bonus = stringToNumberOrUndefined(dto.bonus);
      this.local_price_ref = stringToNumberOrUndefined(dto.local_price_ref);
      this.b3_price = stringToNumberOrUndefined(dto.b3_price);
      this.b3_costs = stringToNumberOrUndefined(dto.b3_costs);
      this.basis_region = dto.basis_region;
      this.user_id = dto.user_id;
      this.ownership_id = dto.ownership_id;
      this.harvest_id = dto.harvest_id;
      this.growing_id = dto.growing_id;
      this.sold_amount = stringToNumber(dto.sold_amount);
      this.sold_rs_sc = stringToNumber(dto.sold_rs_sc);
      this.sold_us_sc = stringToNumber(dto.sold_us_sc);
      this.sold_value_rs = stringToNumber(dto.sold_value_rs);
      this.sold_value_us = stringToNumber(dto.sold_value_us);
    }
  }
}

export default class ProfitN1 extends ProfitN1DTO {
  constructor();
  constructor(profitN1: IProfitN1);
  constructor(profitN1?: IProfitN1) {
    super(profitN1);
  }
  /* methods */
  public setCattlePricings(cattlePricing: CattlePricing): void {
    this.b3_costs = cattlePricing.b3_costs;
    this.b3_price = cattlePricing.b3_price;
    this.basis = cattlePricing.basis;
    this.basis_fit = cattlePricing.basis_fit;
    this.basis_region = cattlePricing.basis_region;
    this.bonus = cattlePricing.bonus;
    this.is_forward_contract = cattlePricing.is_forward_contract;
    this.local_price_ref = cattlePricing.local_price_ref;
    if(!this.is_forward_contract) this.b3_price = undefined;
  }
}
