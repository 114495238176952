export interface IConstants {
  x: number;
  t: number;
  basis: string;
  price: string;
  months: Map<string, string>;
}

export class Constants implements IConstants {
  x = 0;
  t = 0;
  basis = "";
  price = "";
  months = new Map([["", ""]]);

  constructor(constants?: IConstants) {
    if(constants) {
      this.x = constants.x;
      this.t = constants.t;
      this.basis = constants.basis;
      this.price = constants.price;
      this.months = constants.months;
    }
  }
}

const monthsSoil = new Map([
  ["F", "H"],
  ["G", "H"],
  ["H", "K"],
  ["J", "K"],
  ["K", "N"],
  ["M", "N"],
  ["N", "Q"],
  ["Q", "U"],
  ["U", "X"],
  ["V", "X"],
  ["X", "F"],
  ["Z", "F"],
]);

const monthsCorn = new Map([
  ["F", "H"],
  ["G", "H"],
  ["H", "K"],
  ["J", "K"],
  ["K", "N"],
  ["M", "N"],
  ["N", "U"],
  ["Q", "U"],
  ["U", "Z"],
  ["V", "Z"],
  ["X", "Z"],
  ["Z", "H"],
]);

const monthsCottom = new Map([
  ["F", "H"],
  ["G", "H"],
  ["H", "K"],
  ["J", "K"],
  ["K", "N"],
  ["M", "N"],
  ["N", "U"],
  ["Q", "U"],
  ["U", "Z"],
  ["V", "Z"],
  ["X", "Z"],
  ["Z", "H"],
]);

const monthsDollar = new Map([
  ["F", "G"],
  ["G", "H"],
  ["H", "J"],
  ["J", "K"],
  ["K", "M"],
  ["M", "N"],
  ["N", "Q"],
  ["Q", "U"],
  ["U", "V"],
  ["V", "X"],
  ["X", "Z"],
  ["Z", "F"],
]);

const constants = new Map<string, Constants>([
  [
    "C",
    new Constants({
      x: 2.3621,
      t: 39.3683,
      basis: "FC-",
      price: "C",
      months: monthsCorn,
    }),
  ],
  [
    "S",
    new Constants({
      x: 2.2046,
      t: 36.7454,
      basis: "FS-",
      price: "S",
      months: monthsSoil,
    }),
  ],
  [
    "CT",
    new Constants({
      x: 22.0462,
      t: 0.330693,
      basis: "FCT-",
      price: "CT",
      months: monthsCottom,
    }),
  ],
  [
    "dollar",
    new Constants({
      x: 0,
      t: 0,
      basis: "",
      price: "DC",
      months: monthsDollar,
    }),
  ],
]);

export const months = ["F", "G", "H", "J", "K", "M", "N", "Q", "U", "V", "X", "Z"];

export default constants;
