// /* eslint-disable no-console */

import { register } from 'register-service-worker';
import Vue from 'vue';
import { ToastObject } from 'vue-toasted';
import { removeLocalStoreCache } from './utilsCache';

if ('serviceWorker' in navigator) {
  const notifyUserAboutUpdate = (): void => {
    const action = [
      {
        text: 'Fechar',
        onClick: (_: Event, toastObject: ToastObject) => {
          toastObject.goAway(0);
        }
      }
    ]

    Vue.toasted.show('Seu Pro Market será atualizado!', {
      action,
      theme: "bubble",
      position: "top-left",
      className: 'toast-class',
      containerClass: 'container-toast-class'
    });
  };

  if (["production", "staging"].includes(process.env.NODE_ENV)) {
    window.addEventListener('load', () => {
      register(`${process.env.BASE_URL}service-worker.js`, {
        ready () {
          console.log(
            'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
          )
        },
        registered () {
          console.log('Service worker has been registered.')
        },
        cached () {
          console.log('Content has been cached for offline use.')
        },
        updatefound () {
          console.log('New content is downloading.')
        },
        updated (registration) {
          console.log('New content is available; refreshing...')
          notifyUserAboutUpdate();
          if(registration.waiting) {
            removeLocalStoreCache();
            registration.waiting.postMessage({ type: "SKIP_WAITING" });

            let refreshing: boolean;
            navigator.serviceWorker.addEventListener('controllerchange', function () {
              if (refreshing) return;
              window.location.reload();
              refreshing = true;
            });
          }
        },
        offline () {
          console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
          console.error('Error during service worker registration:', error)
        }
      });

    });
  }
}else {
  console.warn("Service workers are not supported.");
}
