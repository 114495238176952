// #################
// Number Functions
// #################
export function round(num: number, decimalPlaces: number): number {
  const p = Math.pow(10, decimalPlaces);
  const m = num * p * (1 + Number.EPSILON);
  return Math.round(m) / p;
}

type DecimalPrecisionReturn = {
  round: (a: number, b: number) => number;
  ceil: (a: number, b: number) => number;
  floor: (a: number, b: number) => number;
  trunc: (a: number, b: number) => number;
  toFixed: (a: number, b: number) => number;
}
export function DecimalPrecision(): DecimalPrecisionReturn {
  let epsilon = Math.pow(2, -52);
  if (Number.EPSILON) {
    epsilon = Number.EPSILON;
  }

  if (Math.trunc === undefined) {
    Math.trunc = function (v) {
      return v < 0 ? Math.ceil(v) : Math.floor(v);
    };
  }

  const isRound = function (num: number, decimalPlaces: number): boolean {
    //return decimalPlaces >= 0 &&
    //    +num.toFixed(decimalPlaces) === num;
    const p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p === num;
  };
  const decimalAdjust = function (type: "round" | "ceil" | "floor" | "trunc", num: number, decimalPlaces: number): number {
    if (isRound(num, decimalPlaces || 0)) return num;
    const p = Math.pow(10, decimalPlaces || 0);
    const m = num * p * (1 + epsilon);
    return Math[type](m) / p;
  };

  return {
    // Decimal round (half away from zero)
    round: function (num, decimalPlaces) {
      return decimalAdjust("round", num, decimalPlaces);
    },
    // Decimal ceil
    ceil: function (num, decimalPlaces) {
      return decimalAdjust("ceil", num, decimalPlaces);
    },
    // Decimal floor
    floor: function (num, decimalPlaces) {
      return decimalAdjust("floor", num, decimalPlaces);
    },
    // Decimal trunc
    trunc: function (num, decimalPlaces) {
      return decimalAdjust("trunc", num, decimalPlaces);
    },
    // Format using fixed-point notation
    toFixed: function (num, decimalPlaces) {
      return parseFloat(
        decimalAdjust(
          "round",
          num,
          decimalPlaces
        ).toFixed(
          decimalPlaces
        )
      );
    },
  };
}

export function decimalBRZToNumber(value: string): number {
  if (typeof value !== "string") return value;
  return parseFloat(
    value
      .split(",")
      .map((a) => a.split(".").join(""))
      .join(".")
  );
}

export function toFixedNumber(num: number, fixedValue: number): number {
  return parseFloat(num.toFixed(fixedValue));
}

export function toNumber(num: string | number): number {
  return typeof num === "string" ? parseFloat(num) : num;
}