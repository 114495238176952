
import Vue, { PropType } from "vue";

import mask from "@/directives/mask.js";

import { eventBus } from "@/main";
import { formatDate, formatDateMonth, getDateFromMonth, unformatDate } from "@/utilsDate";

export default Vue.extend({
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "event",
    },
    type: {
      type: String,
      default: "date",
    },
    showCurrent: {
      type: String,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    allowedDates: {
      type: Function,
    },
    year: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    disabledMenu: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  directives: { mask },
  created() {
    eventBus.$on("reset-date", () => {
      this.dateFormatted = this.formatFunction(
        new Date().toISOString().substr(0, 10)
      );
    });
  },
  data() {
    return {
      // controla menu de data
      dialog: false,
      // data no formato ISO
      date: "",
      // data no formato xx/xx/xxxx
      dateFormatted: "",
    };
  },
  methods: {
    doInput(): void {
      this.dialog = false;
      this.$emit("input", this.date);
    },
    formatFunction(date: string): string {
      if (this.type === "month") {
        const month = formatDateMonth(date);
        if (month) return this.$t(month).toString();
        else {
          console.warn("formatFunction: Can't get month from: ", date);
          return "Error";
        }
      } else {
        const formated = formatDate(date);
        if (formated) return formated;
        else {
          console.warn("formatFunction: Can't format date from: ", date);
          return "Error";
        }
      }
    },
    unformatFunction(date: string): string {
      const unformated = unformatDate(date)?.substr(0, 10);
      if (unformated) return unformated;
      else {
        console.warn("formatFunction: Can't format date from: ", date);
        return "Error";
      }
    },
    getDate(date: string): string {
      if (this.type === "month") {
        return getDateFromMonth(date, this.year);
      } else {
        return date.substr(0, 10);
      }
    },
    customEmit(): void {
      this.dialog = false;
      if (this.dateFormatted) {
        let undate = this.unformatFunction(this.dateFormatted);
        if (!this.date || this.date !== undate) {
          this.date = undate;
          this.$emit("change", this.date);
        } else this.$emit("change", this.date);
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          var formatted = this.formatFunction(newValue);
          if (formatted !== this.dateFormatted) {
            this.dateFormatted = formatted;
            this.date = this.getDate(newValue);
          }
        } else {
          this.dateFormatted = this.formatFunction(
            new Date().toISOString().substr(0, 10)
          );
          this.date = this.getDate(new Date().toISOString());
        }
      },
    },
  },
});
