import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface ICountry extends IModelBase {
  name: string;
  continent: string;
}

export class CountryDTO extends ModelBase implements ICountry {
  name = "";
  continent = "";

  constructor(dto?: ICountry) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.continent = dto.continent;
    }
  }
}

export default class Country extends CountryDTO {
  constructor();
  constructor(country: ICountry);
  constructor(country?: ICountry) {
    super(country);
  }
}
