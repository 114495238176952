import User from "@/models/user";
import State from "@/models/state";
import City from "@/models/city";
import Country from "@/models/country";
import Growing from "@/models/growing";
import Harvest from "@/models/harvest";
import Currency from "@/models/currency";
import MaturationGroup from "@/models/maturationGroup";
import SaleReason from "../models/saleReason";
import Unit from "../models/unit";
import Income from "@/models/income";
import Field from "@/models/field";
import SaleTrigger from "@/models/saleTrigger";

import { OutgoingCategory, SubOutgoingType } from "../models/outgoingCategory";
import { Constants } from "@/constants";
import { CattleQuotations, Quotations, Quotes } from "@/business/Quotations";
import { CattlePricing, Pricing } from "@/business/Pricing";
import { IBasisCattle, LotInfoProfit } from "@/business/CattleProfit";
import { OwnershipProfit, ProfitCosts } from "@/business/Profit";
import { OperationIncome } from "@/business/OperationIncome";
import { OperationMargin } from "@/business/OperationMargin";
import { ISnackbar } from "@/store/modules/app";

import Provider from "@/models/provider";
import BrokerageAccount from "@/models/brokerageAccount";
import Silo from "@/models/silo";
import Incoterm from "@/models/incoterm";
import TradeslipSilo from "@/models/tradeslipSilo";
import TradeslipDeliveryPeriod from "@/models/tradeslipDeliveryPeriod";
import TradeslipPricing from "@/models/tradeslipPricing";
import TradeslipPricingDetail from "@/models/tradeslipPricingDetail";
import TradeslipPricingComponent from "@/models/tradeslipPricingComponent";
import TradeslipExchangeAcc from "@/models/tradeslipExchangeAcc";
import TradeslipExchangeNdf from "@/models/tradeslipExchangeNdf";
import TradeslipExchangeExportLock from "@/models/tradeslipExchangeExportLock";
import TradeslipExchangeDollarFutures from "@/models/tradeslipExchangeDollarFutures";
import BasisShipment from "@/models/basisShipment";
import UserBasisCity from "@/models/userBasisCity";

// ##########################
/* Module types  */
export interface IAuthModule {
  token: string;
  bitzenToken: string;
  loggedUser: User;
}

export interface ISearchModule {
  loading: boolean;
  results: Array<unknown>;
  page: number;
  num_results: number;
  total_pages: number;
}

export interface IStaticsModule {
  states: Array<State>;
  cities: Array<City>;
  countries: Array<Country>;
  growings: Array<Growing>;
  harvests: Array<Harvest>;
  spotHarvests: Harvest;
  currencies: Array<Currency>;
  maturationGroups: Array<MaturationGroup>;
  outgoingCategories: Array<OutgoingCategory>;
  subOutgoingTypes: Array<SubOutgoingType>
  saleReasons: Array<SaleReason>;
  units: Array<Unit>;
}

export interface IProfitModule {
  profitOwnership?: OwnershipProfit;
  profitCattleLot?: LotInfoProfit;
  profitCattleLotPrice?: CattlePricing;
  profitOwnershipSelected?: ProfitCosts;
  profitIncomeSelected?: OperationIncome;
  profitMarginSelected?: OperationMargin;
  pricing?: Pricing;
  quotations?: Quotations | Array<Quotes>;
  pricingSelected?: string;
  incomes: Array<Income>;
  fields: Array<Field>;
  saleTriggers: Array<SaleTrigger>;
  cattleBasisRegions?: Array<string>;
  cattleBasis?: IBasisCattle;
  cattleBarQuotations?: Array<CattleQuotations>;
  prices: {
    priceRs: number;
    priceUs: number;
  }
}

export interface ICalculationModule {
  value?: number;
  basis?: number;
  dollar?: number;
  constants?: Constants;
  se?: number;
  productionCost?: number;
  productivity?: number;
  logistic?: number;
  flatprice?: number;
  expectedProductivity?: number;
  margin?: number;
  fobbings?: number;
  stuffing?: number;
  productivityFiber?: number;
  productivitySeed?: number;
  growingSelected?: Growing;
  cbot?: number;
  nybot?: number;
  b3?: number;
  flatpriceFob?: number;
  flatpriceExwCbot?: number;
  flatpriceExwB3?: number;
  basisFob?: number;
  basisExwCbot?: number;
  basisExwB3?: number;

  b3Cattle?: number;
  basisCattle?: number;
  cattleAmount?: number;
  cattleTotalCost?: number;
  carcassProductivity?: number;
}

export interface IAdminModule {
  allHarvests: Array<Harvest>;
  allGrowings: Array<Growing>;
  allUnits: Array<Unit>;
}

export interface IAppModule {
  snackbar: ISnackbar;
  packageVersion: string;
  client?: User;
}

export interface ICrudModule {
  resource?: string;
}

export interface ITradeslipModule {
  quering: boolean;
  stockContracts: Array<string>;
  allProviders: Array<Provider>;
  brokerageFirms: Array<Provider>;
  brokerageAccounts: Array<BrokerageAccount>;
  sellers: Array<Provider>;
  buyers: Array<Provider>;
  banks: Array<Provider>;
  silos: Array<Silo>;
  incoterms: Array<Incoterm>;
  brokerageAccount: Array<BrokerageAccount>;
  contractSilos: Array<TradeslipSilo>;
  contractDeliveries: Array<TradeslipDeliveryPeriod>;
  contractPricings: Array<TradeslipPricing>;
  allContractPricingDetails: Array<TradeslipPricingDetail>;
  contractPricingComponents: Array<TradeslipPricingComponent>;
  accs: Array<TradeslipExchangeAcc>;
  ndfs: Array<TradeslipExchangeNdf>;
  exportLocks: Array<TradeslipExchangeExportLock>;
  dollarFutures: Array<TradeslipExchangeDollarFutures>;
  contractResultInfo: undefined;
}

interface IObjectKeys {
  [key: string]: string | number;
}

export interface IMonthlyProjectionDataTable extends IObjectKeys {
  city: string,
  month_1: number,
  month_2: number,
  month_3: number,
  month_4: number,
  month_5: number,
  month_6: number,
  month_7: number,
}

export interface IAnalyticsModule {
  basisShipments: Array<BasisShipment>;
  userBasisCities: Array<UserBasisCity>;
  monthlyProjectionDataTable: Array<IMonthlyProjectionDataTable>
}

// ##########################
/* Root State type */
export interface IRootState {
  admin: IAdminModule;
  app: IAppModule;
  auth: IAuthModule;
  crud: ICrudModule;
  search: ISearchModule;
  profit: IProfitModule;
  calculations: ICalculationModule;
  tradeslip: ITradeslipModule;
  analytics: IAnalyticsModule;
}

// ##########################
/* Getter names */
export enum AuthGetterTypes {
  getLoggedUser = "getLoggedUser",
  getLoggedUserRole = "getLoggedUserRole",
  getConfigurations = "getConfigurations"
}
export enum SearchGetterTypes {
  getResults = "getResults",
  getLoading = "getLoading",
  getPage = "getPage",
  getNumResults = "getNumResults",
  getTotalPages = "getTotalPages"
}
export enum StaticsGetterTypes {
  getCities = "getCities",
  getStates = "getStates",
  getCountries = "getCountries",
  getGrowings = "getGrowings",
  getHarvests = "getHarvests",
  getSpotHarvests = "getSpotHarvests",
  getCurrencies = "getCurrencies",
  getMaturationGroups = "getMaturationGroups",
  getOutgoingCategories = "getOutgoingCategories",
  getSubOutgoingType = "getSubOutgoingType",
  getSaleReason = "getSaleReason",
  getUnits = "getUnits",
}
export enum ProfitGetterTypes {
  getProfitOwnership = "getProfitOwnership",
  getProfitOwnershipSelected = "getProfitOwnershipSelected",
  getProfitIncomeSelected = "getProfitIncomeSelected",
  getCottonConfigs = "getCottonConfigs",
  getProfitIncomeSelectedFiber = "getProfitIncomeSelectedFiber",
  getProfitIncomeSelectedSeed = "getProfitIncomeSelectedSeed",
  getProfitMarginSelected = "getProfitMarginSelected",
  getProfitCattleLotSelected = "getProfitCattleLotSelected",
  getProfitCattleLotPriceSelected = "getProfitCattleLotPriceSelected",
  getCattleBasisRegions = "getCattleBasisRegions",
  getCattleBasis = "getCattleBasis",
  getProQuotations = "getProQuotations",
  getCattleBarQuotations = "getCattleBarQuotations",
  getProIncomes = "getProIncomes",
  getProFields = "getProFields",
  getPrincingSelected = "getPrincingSelected",
  getSalesSUM = "getSalesSUM",
  getSaleTriggers = "getSaleTriggers",
  getPrincing = "getPrincing",
  getPrices = "getPrices",
}
export enum CalculationGetterTypes {
  getCalculationState = "getCalculationState",
  getCalculationStateCattle = "getCalculationStateCattle",
  getCalculationStateSPOT = "getCalculationStateSPOT",
}

export enum AdminGetterTypes {
  getAllHarvests = "getAllHarvests",
  getAllGrowings = "getAllGrowings",
  getAllUnits = "getAllUnits"
}

export enum AppGetterTypes {
  getSnackbarInstance = "getSnackbarInstance",
  appVersion = "appVersion",
  getClient = "getClient"
}

export enum TradeslipGetterTypes {
  getQuering = "getQuering",
  getAllProviders = "getAllProviders",
  getBrokerageFirms = "getBrokerageFirms",
  getBrokerageAccount = "getBrokerageAccount",
  getSellers = "getSellers",
  getBuyers = "getBuyers",
  getBanks = "getBanks",
  getSilos = "getSilos",
  getIncoterms = "getIncoterms",
  getContractSilos = "getContractSilos",
  getContractDeliveries = "getContractDeliveries",
  getContractPricings = "getContractPricings",
  getAllContractPricingDetails = "getAllContractPricingDetails",
  getContractPricingComponents = "getContractPricingComponents",
  getAccs = "getAccs",
  getNDFs = "getNDFs",
  getExportLocks = "getExportLocks",
  getDollarFutures = "getDollarFutures",
  getStockContracts = "getStockContracts",
  getContractResult = "getContractResult",
}

export enum AnalyticsGetterTypes {
  getBasisShipments = "getBasisShipments",
  getUserBasisCities = "getUserBasisCities",
  getMonthlyProjectionDataTable = "getMonthlyProjectionDataTable",
}

// ##########################
/* Type definition of our getters */
export interface IAuthGetters {
  [AuthGetterTypes.getLoggedUser](state: IAuthModule): User;
  [AuthGetterTypes.getLoggedUserRole](state: IAuthModule): string;
}
export interface ISearchGetters {
  [SearchGetterTypes.getResults](state: ISearchModule): Array<Record<string, unknown>>;
  [SearchGetterTypes.getLoading](state: ISearchModule): boolean;
  [SearchGetterTypes.getPage](state: ISearchModule): number;
  [SearchGetterTypes.getNumResults](state: ISearchModule): number;
  [SearchGetterTypes.getTotalPages](state: ISearchModule): number;
}
export interface IStaticsGetters {
  [StaticsGetterTypes.getCities](state: IStaticsModule): Array<City>;
  [StaticsGetterTypes.getStates](state: IStaticsModule): Array<State>;
  [StaticsGetterTypes.getCountries](state: IStaticsModule): Array<Country>;
  [StaticsGetterTypes.getGrowings](state: IStaticsModule): Array<Growing>;
  [StaticsGetterTypes.getHarvests](state: IStaticsModule): Array<Harvest>;
  [StaticsGetterTypes.getSpotHarvests](state: IStaticsModule): Harvest;
  [StaticsGetterTypes.getCurrencies](state: IStaticsModule): Array<Currency>;
  [StaticsGetterTypes.getMaturationGroups](state: IStaticsModule): Array<MaturationGroup>;
  [StaticsGetterTypes.getOutgoingCategories](state: IStaticsModule): Array<OutgoingCategory>;
  [StaticsGetterTypes.getSubOutgoingType](state: IStaticsModule): Array<SubOutgoingType>;
  [StaticsGetterTypes.getSaleReason](state: IStaticsModule): Array<SaleReason>;
  [StaticsGetterTypes.getUnits](state: IStaticsModule): Array<Unit>;
}
export interface ICalculationGetters {
  [CalculationGetterTypes.getCalculationState](state: ICalculationModule): ICalculationModule;
  [CalculationGetterTypes.getCalculationStateSPOT](state: ICalculationModule): ICalculationModule;
}

export interface IAnalyticsGetters {
  [AnalyticsGetterTypes.getBasisShipments](state: IAnalyticsModule): IAnalyticsModule;
  [AnalyticsGetterTypes.getUserBasisCities](state: IAnalyticsModule): IAnalyticsModule;
}

// ##########################
/* Payload types */
export interface IParams {
  checkDelete?: boolean;
  my?: boolean;
  limit?: number;
  valid_lots?: boolean;
  id?: number;
  ownership_id?: number;
  growing_id?: number;
  harvest_id?: number | string;
  cattle_lot_id?: number;
  user_id?: number;
  tradeslip_id?: number;
  client?: number;
}
export interface IPayloadSearch {
  resource: string;
  params: IParams;
}

export interface IPayloadByGrowing {
  dollar?: number;
  se?: number;
  basis?: number;
  expectedProductivity?: number;
  productionCost?: number;
  productivity?: number;
  flatprice?: number;
  constants?: Constants;
  logistic?: number;
  cattleAmount?: number;
  cattleTotalCost?: number;
  fobbings?: number;
  stuffing?: number;
  productivityFiber?: number;
  productivitySeed?: number;
}

// ##########################
/* Response types */
export interface IUserMeResponse {
  token: string;
  user: User;
}

export interface ISearchResponseSimple<T> {
  objects: Array<T>;
}
export interface ISearchResponse<T> {
  objects: Array<T>;
  page: number;
  num_results: number;
  total_pages: number;
}
export interface IArrayParams {
  growing: string;
  items: {
    dollar?: Array<string>,
    basis?: {
      exw?: Array<string>,
      fob?: Array<string>
    },
    se?: {
      b3?: Array<string>,
      cbot?: Array<string>,
      nybot?: Array<string>
    }
  };
}
