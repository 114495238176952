import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import State from "./state";

export interface ICity extends IModelBase {
  name: string;
  geocode?: string;
  ddd?: string;
  state_id: number;

  /**Relationship classes */
  state?: State;
}

export class CityDTO extends ModelBase implements ICity {
  name = "";
  geocode?: string | undefined = undefined;
  ddd?: string | undefined = undefined;
  state_id = 0;

  state = new State();

  constructor(dto?: ICity) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.geocode = dto.geocode;
      this.ddd = dto.ddd;
      this.state_id = dto.state_id;

      if(dto.state)
        this.state = new State(dto.state);
    }
  }
}

export default class City extends CityDTO {
  constructor();
  constructor(city: ICity);
  constructor(city?: ICity) {
    super(city);
  }
}
