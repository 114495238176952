import Vue from "vue";
import { DecimalPrecision } from "./utilsNumber";

Vue.filter("formatterReal", (value: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
});

Vue.filter("formatterRealSimple", (value: number): string => {
  if (!isNaN(value) && typeof value !== "undefined") {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(value)
      .replace(/R\$\u00A0/g, "");
  }
  return "NaN";
});

Vue.filter("formatterRealSC", (value: number): string => {
  if (!isNaN(value) && typeof value !== "undefined") {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(value)
      .replace(/R\$\u00A0/g, "") + ' R$/sc';
  }
  return "NaN";
});

Vue.filter("formatterDollar", (value: number): string => {
  let satanas = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
  satanas = satanas.replace(/,/g, " ");
  satanas = satanas.replace(/\./g, ",");
  satanas = satanas.replace(/ /g, ".");
  satanas = satanas.replace(/\$/g, "$ ");
  return satanas;
});

Vue.filter("formatterUnit", (value: number, after: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: after,
    maximumFractionDigits: after,
  }).format(value);
});

Vue.filter("formatterUnitDot", (value: number, after: number): string => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: after,
    maximumFractionDigits: after,
  }).format(value);
});

Vue.filter("formatterCents", (value: number): string => {
  return "¢ " + DecimalPrecision().round(value, 2);
});

Vue.filter("formatterBRZDate", (value: string): string => {
  const date = new Date(value);
  return date.toLocaleDateString("pt-BR");
});
