import { isEmptyObject } from "@/utilsObject";

export interface IModelBase {
  id?: number;
  created_at?: string;
  updated_at?: string;
}

export class ModelBaseDTO implements IModelBase {
  id: number | undefined = undefined;
  created_at?: string | undefined = undefined;
  updated_at?: string | undefined = undefined;

  constructor(dto?: any) {
    if(dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.created_at = dto.created_at;
      this.updated_at = dto.updated_at;
    }
  }
}

export class ModelBase extends ModelBaseDTO {
  constructor();
  constructor(base: any);
  constructor(base?: any) {
    super(base);
  }
}