import axios from "@/plugins/axios";

import Growing from "@/models/growing";
import Harvest from "@/models/harvest";
import Unit from "@/models/unit";
import { intanciateArrayApiData, isValidResponse } from "@/utilsObject";
import { ISearchResponseSimple } from "@/types/store-types";

export interface IHarvestTurn {
  current: Harvest,
  future: Harvest,
}

const getAllGrowings = async (): Promise<Growing[]> => {
  const params = { limit: 0 };
  const response = await axios.httpApi.get<ISearchResponseSimple<Growing>>("growing", { params });
  if(isValidResponse(response.status)) {
    return intanciateArrayApiData(Growing, response.data.objects);
  }
  console.error(`Error to get All Growings: ${response.statusText}`);
  return response.data.objects;
};
const getAllHarvests = async (): Promise<Harvest[]> => {
  const params = { limit: 0, all: true };
  const response = await axios.httpApi.get<ISearchResponseSimple<Harvest>>("harvest", { params });
  if(isValidResponse(response.status)) {
    return intanciateArrayApiData(Harvest, response.data.objects);
  }
  console.error(`Error to get All Harvests: ${response.statusText}`);
  return response.data.objects;
};
const getAllUnits = async (): Promise<Unit[]> => {
  const params = { limit: 0 };
  const response = await axios.httpApi.get<ISearchResponseSimple<Unit>>("unit", { params });
  if(isValidResponse(response.status)) {
    return intanciateArrayApiData(Unit, response.data.objects);
  }
  console.error(`Error to get All Units: ${response.statusText}`);
  return response.data.objects;
};
const makeHarvestTurn = async (data: IHarvestTurn): Promise<void> => {
  const response = await axios.httpApi.post("sys-admin/harvest-turn", data);
  if(!isValidResponse(response.status))
    console.error(`Error to make harvest turn: ${response.statusText}`);
  return response.data;
};


export const adminService = {
  getAllGrowings,
  getAllHarvests,
  getAllUnits,
  makeHarvestTurn,
};
