import Vue from "vue";

import {
  UPDATE_ALL,
  UPDATE_ALL_SIMPLE,
  UPDATE_PROFIT_OWNERSHIP_SELECTED,
  UPDATE_PROFIT_INCOME_SELECTED,
  UPDATE_PROFIT_MARGIN_SELECTED,
  UPDATE_PROFIT_FLAT_PRICE,
  UPDATE_PROFIT_PRICING,
  UPDATE_PROFIT_CATTLE_LOT,
  UPDATE_PRO_QUOTATIONS,
  UPDATE_PRO_OWNERSHIP,
  UPDATE_PRO_FIELDS,
  UPDATE_PRO_INCOMES,
  SEARCH_SALE_TRIGGERS,
  UPDATE_SALE_TRIGGERS,
  DELETE_SALE_TRIGGERS,
  LOAD_BEEF_CATTLE_BASIS,
  LOAD_PROFIT_GRAIN_STORE,
  LOAD_PROFIT_GRAIN_SPOT_STORE,
  LOAD_SIMPLE_PROFIT_GRAIN_SPOT_STORE,
  LOAD_SIMPLE_PROFIT_GRAIN_STORE,
  LOAD_PROFIT_CATTLE_STORE,
  LOAD_SIMPLE_PROFIT_CATTLE_STORE,
  UPDATE_CATTLE_QUOTATIONS_BANNER,
  CALC_SPOT_PPE_EXW_PRICE,
  UPDATE_ALL_SPOT,
  LOAD_EMPTY_ANONYMOUNS_PROFIT,
} from "@/store/actions-types";

import {
  SET_PRO_OWNERSHIP,
  SET_PROFIT_OWNERSHIP_SELECTED,
  SET_PROFIT_CATTLE_LOTS,
  SET_PROFIT_CATTLE_LOTS_PRICE,
  SET_PRO_CATTLE_BASIS_REGIONS,
  SET_PRO_CATTLE_BASIS,
  SET_PRO_QUOTATIONS,
  SET_PRO_FIELDS,
  SET_PRO_INCOMES,
  SET_PRO_INCOME_OP,
  SET_PRO_MARGIN,
  SET_SALE_TRIGGERS,
  ADD_SALE_TRIGGERS,
  CHANGE_SALE_TRIGGERS,
  REMOVE_SALE_TRIGGERS,
  UPDATE_FLAT_PRICE,
  SET_CATTLE_BAR_QUOTATIONS,
  SET_PROFIT_PRICING,
  SET_PROFIT_PRICING_SELECTED,
  SET_PROFIT_PRICES,
} from "@/store/mutations-types";

import Business from "@/utilsBusiness";
import SaleTrigger from "@/models/saleTrigger";

import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  filterQuotes,
  arrayJsonToClass,
  setValueValidKey,
  isValidKeys,
} from "@/utilsObject";
import { profitService } from "@/services/profit";
import { CattleQuotations, Quotations, Quotes } from "@/business/Quotations";
import { bushelToSack, bushelToTon } from "@/utilsUnit";
import { crudService } from "@/services/crud";
import {
  IProfitModule,
  IRootState,
  ProfitGetterTypes,
} from "@/types/store-types";
import { CattleProfit, IBasisCattle, LotInfoProfit, LotProfit } from "@/business/CattleProfit";
import { CattlePricing, Pricing } from "@/business/Pricing";
import { OwnershipProfit, ProfitCosts, Profit } from "@/business/Profit";
import { OperationIncome } from "@/business/OperationIncome";
import { OperationMargin } from "@/business/OperationMargin";
import { Spot } from "@/business/Spot";

import Income from "@/models/income";
import Field from "@/models/field";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import CattleLot from "@/models/cattleLot";
import { OwershipSimpleProfit, SimpleProfit } from "@/business/SimpleProfit";
import { CattleSimpleProfit, LotSimpleProfit } from "@/business/CattleSimpleProfit";
import { checkCacheExpiryVersion, setCacheItem } from "@/utilsCache";
import { Constants } from "@/constants";

// Shorthands for easier type building
type S = IProfitModule;
type R = IRootState;

export interface IPayloadProfitGrainData {
  profit: Profit;
  harvest: Harvest;
  growing: Growing;
  ownership: Ownership;
}

export interface IPayloadSimpleProfitGrainData {
  profit: SimpleProfit;
  harvest: Harvest;
  growing: Growing;
}

export interface IPayloadProfitCattleData {
  profit: Profit;
  ownership: Ownership;
  lot: CattleLot;
}

export interface IPayloadSimpleProfitCattleData {
  profit: SimpleProfit;
}

class State implements IProfitModule {
  profitOwnership: OwnershipProfit | undefined = undefined;
  profitCattleLot: LotInfoProfit | undefined = undefined;
  profitCattleLotPrice: CattlePricing | undefined = undefined;
  profitOwnershipSelected: ProfitCosts | undefined = undefined;
  quotations: Array<Quotes> | undefined = [];
  profitIncomeSelected: OperationIncome | undefined = undefined;
  profitMarginSelected: OperationMargin | undefined = undefined;
  incomes = [] as Income[];
  fields = [] as Field[];
  saleTriggers = [] as SaleTrigger[];
  cattleBasisRegions: string[] | undefined = undefined;
  cattleBasis: IBasisCattle | undefined = undefined;
  cattleBarQuotations: CattleQuotations[] | undefined = undefined;
  pricing: Pricing | undefined = undefined;
  pricingSelected = "ppe";
  prices = {
    priceRs: 0,
    priceUs: 0,
  };
}

const mutations: MutationTree<S> = {
  [SET_PRO_OWNERSHIP]: (
    state,
    profitOwnership: OwnershipProfit
  ) => {
    state.profitOwnership = profitOwnership;
  },
  [SET_PROFIT_OWNERSHIP_SELECTED]: (
    state,
    profitOwnershipSelected: ProfitCosts
  ) => {
    state.profitOwnershipSelected = profitOwnershipSelected;
  },
  [SET_PROFIT_CATTLE_LOTS]: (state, profitCattleLots: LotInfoProfit) => {
    state.profitCattleLot = profitCattleLots;
  },
  [SET_PROFIT_CATTLE_LOTS_PRICE]: (
    state,
    profitCattleLotsPrice: CattlePricing
  ) => {
    state.profitCattleLotPrice = profitCattleLotsPrice;
  },
  [SET_PRO_INCOME_OP]: (state, profitIncomeSelected: OperationIncome) => {
    state.profitIncomeSelected = profitIncomeSelected;
  },
  [SET_PRO_CATTLE_BASIS_REGIONS]: (state, cattleBasisRegions: string[]) => {
    state.cattleBasisRegions = cattleBasisRegions;
  },
  [SET_PRO_CATTLE_BASIS]: (state, cattleBasis: IBasisCattle) => {
    state.cattleBasis = cattleBasis;
  },
  [SET_PRO_MARGIN]: (state, profitMarginSelected: OperationMargin) => {
    state.profitMarginSelected = profitMarginSelected;
  },
  [SET_PRO_QUOTATIONS]: (state, quotations: Array<Quotes>) => {
    state.quotations = quotations;
  },
  [SET_PRO_FIELDS]: (state, fields: Field[]) => {
    state.fields = fields;
  },
  [SET_PRO_INCOMES]: (state, incomes: Income[]) => {
    state.incomes = incomes;
  },
  [SET_SALE_TRIGGERS]: (state, saleTriggers: SaleTrigger[]) => {
    state.saleTriggers = saleTriggers;
  },
  [ADD_SALE_TRIGGERS]: (state, saleTrigger: SaleTrigger) => {
    if (state.saleTriggers.length > 0) {
      const lastSaleTrigger = state.saleTriggers[state.saleTriggers.length - 1];
      if (lastSaleTrigger?.id) saleTrigger.id = lastSaleTrigger.id + 1;
      else saleTrigger.id = 1;
    } else saleTrigger.id = 1;
    state.saleTriggers.push(saleTrigger);
  },
  [CHANGE_SALE_TRIGGERS]: (state, saleTrigger: SaleTrigger) => {
    const index = state.saleTriggers.findIndex(
      (element) => element.id === saleTrigger.id
    );
    if (index > -1) Vue.set(state.saleTriggers, index, saleTrigger);
  },
  [REMOVE_SALE_TRIGGERS]: (state, idSaleTrigger: number) => {
    const elementsIndex = state.saleTriggers.findIndex(
      (element) => element.id == idSaleTrigger
    );
    if (elementsIndex > -1) Vue.delete(state.saleTriggers, elementsIndex);
  },
  [UPDATE_FLAT_PRICE]: (state, flatprice: number) => {
    if (state.profitOwnershipSelected)
      state.profitOwnershipSelected.flat_price = flatprice;
  },
  [SET_CATTLE_BAR_QUOTATIONS]: (state, cattle_quotes: CattleQuotations[]) => {
    state.cattleBarQuotations = cattle_quotes;
  },
  [SET_PROFIT_PRICING]: (state, price: Pricing) => {
    state.pricing = price;
  },
  [SET_PROFIT_PRICING_SELECTED]: (state, pricingSelected: string) => {
    state.pricingSelected = pricingSelected;
  },
  [SET_PROFIT_PRICES]: (state, prices) => {
    state.prices = prices;
  },
};
const actions: ActionTree<S, R> = {
  [LOAD_PROFIT_GRAIN_STORE]: ({ commit }, payload: IPayloadProfitGrainData) => {
    return new Promise<void>((resolve) => {
      const growing = payload.growing;
      const harvest = payload.harvest;
      const profit = payload.profit;
      let data = undefined;
      // Fluxo Grãos
      if (growing.isCotton) {
        // Algodão
        data = profit.cotton;
      } else if (growing.isCorn) {
        // Milho
        data = profit.corn;
      }else {
        // Soja
        data = profit.soybean;
      }
      // Identifica a safra selecionada
      if(harvest.is_current)
        data = data?.actual;
      else
        data = data?.future;
      // Pega a fazenda selecionada
      data = data?.ownerships.find(el => el.id === payload.ownership.id);
      // Se não tem, carrega instancia fazia
      if(!data) data = new OwnershipProfit();
      // ### Grãos ####
      // Set ownership
      commit(SET_PRO_OWNERSHIP, data);
      // Set ownership selected
      commit(SET_PROFIT_OWNERSHIP_SELECTED, data.profit);
      // Precificação
      commit(SET_PROFIT_PRICING, data.pricing);
      // Set quotations
      commit(SET_PRO_QUOTATIONS, data.quotations);
      // Set profit income selected
      commit(SET_PRO_INCOME_OP, data.op_income);
      // Set profit margin selected
      commit(SET_PRO_MARGIN, data.op_margin);
      // Set income selected
      commit(SET_PRO_INCOMES, data.incomes);
      // Set fields selected
      commit(SET_PRO_FIELDS, data.fields);

      resolve();
    });
  },
  [LOAD_SIMPLE_PROFIT_GRAIN_STORE]: ({ commit }, payload: IPayloadSimpleProfitGrainData) => {
    return new Promise<void>((resolve) => {
      const growing = payload.growing;
      const harvest = payload.harvest;
      const profit = payload.profit;
      let data = undefined;
      // Fluxo Grãos
      if (growing.isCotton) {
        // Algodão
        data = profit.cotton;
      } else if (growing.isCorn) {
        // Milho
        data = profit.corn;
      }else {
        // Soja
        data = profit.soybean;
      }
      // Identifica a safra selecionada
      if(harvest.is_current)
        data = data?.actual;
      else
        data = data?.future;
      // Se não tem, carrega instancia fazia
      if(!data) data = new OwershipSimpleProfit();
      // ### Grãos ####
      // Set ownership
      commit(SET_PRO_OWNERSHIP, data);
      // Set ownership selected
      commit(SET_PROFIT_OWNERSHIP_SELECTED, data.profit);
      // Precificação
      commit(SET_PROFIT_PRICING, data.pricing);
      // Set quotations
      commit(SET_PRO_QUOTATIONS, data.quotations);

      resolve();
    });
  },
  [LOAD_PROFIT_CATTLE_STORE]: ({ commit }, payload: IPayloadProfitCattleData) => {
    return new Promise<void>((resolve) => {
      const profit = payload.profit;
      let data = undefined;
      let lot = undefined;
      data = profit.cattle;
      // Pega a fazenda selecionada
      data = data?.ownerships.find(el => el.id === payload.ownership.id);
      lot = data?.lots.find(el => el.id === payload.lot.id);
      // Se não tem, carrega instancia fazia
      if(!data) data = new CattleProfit();
      if(!lot) lot = new LotProfit();

      // #### BOI ####
      commit(SET_PROFIT_OWNERSHIP_SELECTED, data);
      // Set quotations for the banner cattle
      commit(SET_CATTLE_BAR_QUOTATIONS, data.barQuotes);
      // Set profit cattle lot selected
      commit(SET_PROFIT_CATTLE_LOTS, lot.profit);
      // Set cattle lot price selected
      commit(SET_PROFIT_CATTLE_LOTS_PRICE, lot.price);
      // Set quotations
      commit(SET_PRO_QUOTATIONS, lot.quotations);
      // Set profit income selected
      commit(SET_PRO_INCOME_OP, lot.op_income);
      // Set profit margin selected
      commit(SET_PRO_MARGIN, lot.op_margin);
      // Set income selected
      commit(SET_PRO_INCOMES, lot.incomes);

      resolve();
    });
  },
  [LOAD_SIMPLE_PROFIT_CATTLE_STORE]: ({ commit }, payload: IPayloadSimpleProfitCattleData) => {
    return new Promise<void>((resolve) => {
      const profit = payload.profit;
      let data = undefined;
      let lot = undefined;
      data = profit.cattle;
      // Pega a fazenda selecionada
      lot = data?.lot;
      // Se não tem, carrega instancia fazia
      if(!data) data = new CattleSimpleProfit();
      if(!lot) lot = new LotSimpleProfit();

      // #### BOI ####
      commit(SET_PROFIT_OWNERSHIP_SELECTED, data);
      // Set quotations for the banner cattle
      commit(SET_CATTLE_BAR_QUOTATIONS, data.barQuotes);
      // Set profit cattle lot selected
      commit(SET_PROFIT_CATTLE_LOTS, lot.profit);
      // Set cattle lot price selected
      commit(SET_PROFIT_CATTLE_LOTS_PRICE, lot.price);
      // Set quotations
      commit(SET_PRO_QUOTATIONS, lot.quotations);

      resolve();
    });
  },
  [UPDATE_ALL]: ({ commit }, payload) => {
    return new Promise<void>((resolve) => {
      if (payload.harvest) {
        // ### Grãos ####
        // Set harvest selected
        // Set growing selected
        // Set quotations
        commit(SET_PRO_QUOTATIONS, payload.quotations);
        // Set ownership selected
        commit(SET_PROFIT_OWNERSHIP_SELECTED, payload.ownership.profit);
        // Set profit income selected
        commit(SET_PRO_INCOME_OP, payload.ownership.op_income);
        // Set profit margin selected
        commit(SET_PRO_MARGIN, payload.ownership.op_margin);
        // Set income selected
        commit(SET_PRO_INCOMES, payload.ownership.incomes);
        // Set fields selected
        commit(SET_PRO_FIELDS, payload.ownership.fields);
      } else {
        // #### BOI ####
        commit(SET_PROFIT_OWNERSHIP_SELECTED, payload.ownership);
        // Set quotations for the banner cattle
        commit(SET_CATTLE_BAR_QUOTATIONS, payload.ownership.barQuotes);
        // Set profit cattle lot selected
        commit(SET_PROFIT_CATTLE_LOTS, payload.lot.profit);
        // Set cattle lot price selected
        if (payload.lot.price) {
          [
            "b3_price",
            "b3_costs",
            "basis",
            "basis_fit",
            "bonus",
            "local_price_ref",
          ].forEach((key) => {
            payload.lot.price[key] = parseFloat(payload.lot.price[key]);
          });
        }
        commit(SET_PROFIT_CATTLE_LOTS_PRICE, payload.lot.price);
        // Set quotations
        commit(SET_PRO_QUOTATIONS, payload.lot.quotations);
        // Set profit income selected
        commit(SET_PRO_INCOME_OP, payload.lot.op_income);
        // Set profit margin selected
        commit(SET_PRO_MARGIN, payload.lot.op_margin);
        // Set income selected
        commit(SET_PRO_INCOMES, payload.lot.incomes);
      }
      resolve();
    });
  },
  [UPDATE_ALL_SIMPLE]: ({ commit, dispatch }, payload) => {
    return new Promise<void>((resolve) => {
      if (payload.harvest) {
        // ### Grãos ####
        // Set harvest selected
        // Set growing selected
        // Set quotations
        const instanceQuotatons = arrayJsonToClass(
          Quotes,
          payload.harvest.quotations
        );
        commit(SET_PRO_QUOTATIONS, instanceQuotatons);
        // Set ownership selected
        commit(SET_PROFIT_OWNERSHIP_SELECTED, payload.harvest.profit);
        // Set profit income selected
        commit(SET_PRO_INCOME_OP, payload.harvest.op_income);
        // Set profit margin selected
        commit(SET_PRO_MARGIN, payload.harvest.op_margin);
        // SPOT
        commit(SET_PROFIT_PRICING, payload.harvest.pricing);
        // Set ownership selected
        commit(SET_PROFIT_OWNERSHIP_SELECTED, payload.harvest.ownership);
        dispatch(CALC_SPOT_PPE_EXW_PRICE);
      } else {
        // #### BOI ####
        // Set growing selected
        // Set quotations for the banner cattle
        commit(SET_CATTLE_BAR_QUOTATIONS, payload.growing.barQuotes);
        // Set profit cattle lot selected
        commit(SET_PROFIT_CATTLE_LOTS, payload.lot.profit);
        // Set cattle lot price selected
        commit(SET_PROFIT_CATTLE_LOTS_PRICE, payload.lot.price);
        // Set quotations
        commit(SET_PRO_QUOTATIONS, payload.lot.quotations);
      }
      resolve();
    });
  },
  [UPDATE_PRO_OWNERSHIP]: ({ commit }, ownership) => {
    return new Promise<void>((resolve) => {
      commit(SET_PRO_OWNERSHIP, ownership);
      resolve();
    });
  },
  [UPDATE_PROFIT_OWNERSHIP_SELECTED]: ({ commit }, profitOwnershipSelected) => {
    return new Promise<void>((resolve) => {
      commit(SET_PROFIT_OWNERSHIP_SELECTED, profitOwnershipSelected);
      resolve();
    });
  },
  [UPDATE_PROFIT_INCOME_SELECTED]: ({ commit }, profitIncomeSelected) => {
    return new Promise<void>((resolve) => {
      commit(SET_PRO_INCOME_OP, profitIncomeSelected);
      resolve();
    });
  },
  [UPDATE_PROFIT_MARGIN_SELECTED]: ({ commit }, profitMarginSelected) => {
    return new Promise<void>((resolve) => {
      commit(SET_PRO_MARGIN, profitMarginSelected);
      resolve();
    });
  },
  [UPDATE_PROFIT_FLAT_PRICE]: ({ commit, state }) => {
    return new Promise<void>((resolve) => {
      if (state.quotations && state.quotations instanceof Quotations) {
        const flatprice =
          state.quotations.se.value + state.quotations.basis.value;
        commit(UPDATE_FLAT_PRICE, flatprice);
      }
      resolve();
    });
  },
  [UPDATE_PROFIT_PRICING]: ({ commit, dispatch, state }, growingSymbol) => {
    return new Promise<void>((resolve) => {
      if (Array.isArray(state.quotations)) {
        let pricing = { ...state.pricing };
        const constants = Business.getConstants(growingSymbol);
        const dollar = filterQuotes("dollar", state.quotations)?.value || 1;
        const basisFob = filterQuotes("basisFob", state.quotations)?.value || 0;
        let se: number | undefined = undefined;
        let basisExw: number | undefined = undefined;
        const ppe = (asset: string) => {
          if (se && constants) {
            const flatprice = `${asset}_flat_price_ppe`;
            if (isValidKeys(Pricing, flatprice))
              pricing = setValueValidKey(pricing, flatprice, se + basisFob);

            const fcaUs = `${asset}_ppe_fca_us_sc`;
            if (isValidKeys(Pricing, fcaUs) && isValidKeys(Pricing, flatprice))
              pricing = setValueValidKey(
                pricing,
                fcaUs,
                fcaPriceDollar(pricing[flatprice], constants, state)
              );

            const fcaRs = `${asset}_ppe_fca_rs_sc`;
            if (isValidKeys(Pricing, fcaRs) && isValidKeys(Pricing, fcaUs))
              pricing = setValueValidKey(
                pricing,
                fcaRs,
                pricing[fcaUs] * dollar
              );

            const exwRs = `${asset}_ppe_exw_rs_sc`;
            if (isValidKeys(Pricing, exwRs) && isValidKeys(Pricing, fcaRs))
              pricing = setValueValidKey(
                pricing,
                exwRs,
                exwPriceReal(pricing[fcaRs], state)
              );

            const exwUS = `${asset}_ppe_exw_us_sc`;
            if (isValidKeys(Pricing, exwUS) && isValidKeys(Pricing, exwRs))
              pricing = setValueValidKey(
                pricing,
                exwUS,
                (pricing[exwRs]/dollar)
              );
          }
        };
        const localBasis = (asset: string) => {
          if (se && constants) {
            const flatprice = `${asset}_flat_price_local_basis`;
            if (isValidKeys(Pricing, flatprice) && basisExw)
              pricing = setValueValidKey(pricing, flatprice, se + basisExw);
            const exwUs = `${asset}_local_basis_exw_us_sc`;
            if (isValidKeys(Pricing, exwUs) && isValidKeys(Pricing, flatprice))
              pricing = setValueValidKey(
                pricing,
                exwUs,
                bushelToSack(pricing[flatprice], constants).toFixed(2)
              );
            const exwRs = `${asset}_local_basis_exw_rs_sc`;
            if (isValidKeys(Pricing, exwRs) && isValidKeys(Pricing, exwUs))
              pricing = setValueValidKey(
                pricing,
                exwRs,
                pricing[exwUs] * dollar
              );
          }
        };
        switch (growingSymbol) {
          case "S":
            se = filterQuotes("cbot", state.quotations)?.value || 0;
            basisExw = filterQuotes("basisCbot", state.quotations)?.value || 0;
            // PPE
            ppe("cbot");
            // Basis local
            localBasis("cbot");
            break;

          case "C":
            se = filterQuotes("cbot", state.quotations)?.value || 0;
            basisExw = filterQuotes("basisCbot", state.quotations)?.value || 0;
            // PPE
            ppe("cbot");
            // Basis local
            localBasis("cbot");
            // Basis local B3
            se = filterQuotes("b3", state.quotations)?.value || 0;
            basisExw = filterQuotes("basisB3", state.quotations)?.value || 0;
            pricing.b3_flat_price_local_basis = se + basisExw;
            pricing.b3_local_basis_exw_rs_sc = parseFloat(
              pricing.b3_flat_price_local_basis.toFixed(2)
            );
            pricing.b3_local_basis_exw_us_sc = parseFloat(
              (pricing.b3_local_basis_exw_rs_sc / dollar).toFixed(2)
            );
            break;

          case "CT":
            se = filterQuotes("nybot", state.quotations)?.value || 0;
            // PPE
            ppe("nybot");
            break;

          default:
            se = filterQuotes("cbot", state.quotations)?.value || 0;
            basisExw = filterQuotes("basisCbot", state.quotations)?.value || 0;
            // PPE
            ppe("cbot");
            // Basis local
            localBasis("cbot");
            break;
        }
        // Save new price
        commit(SET_PROFIT_PRICING, pricing);
        // Calc os preços EXW para PPE
        dispatch(CALC_SPOT_PPE_EXW_PRICE);
      }
      resolve();
    });
  },
  [UPDATE_PROFIT_CATTLE_LOT]: ({ commit }, profitCattleLot) => {
    commit(SET_PROFIT_CATTLE_LOTS, profitCattleLot);
  },
  [UPDATE_PRO_QUOTATIONS]: ({ commit, state }, quotations) => {
    return new Promise<void>((resolve) => {
      if (Array.isArray(quotations)) {
        const instanceQuotatons = arrayJsonToClass(Quotes, quotations);
        if (Array.isArray(state.quotations)) {
          const basisExw = filterQuotes("basisCbot", state.quotations);
          if(basisExw) instanceQuotatons.push(basisExw);
          const basisB3 = filterQuotes("basisB3", state.quotations);
          if(basisB3) instanceQuotatons.push(basisB3);
        }
        commit(SET_PRO_QUOTATIONS, instanceQuotatons);
      } else {
        // Set quotations
        commit(SET_PRO_QUOTATIONS, quotations);
      }
      resolve();
    });
  },
  [UPDATE_PRO_FIELDS]: ({ commit }, fields) => {
    return new Promise<void>((resolve) => {
      commit(SET_PRO_FIELDS, fields);
      resolve();
    });
  },
  [UPDATE_PRO_INCOMES]: ({ commit }, incomes) => {
    return new Promise<void>((resolve) => {
      commit(SET_PRO_INCOMES, incomes);
      resolve();
    });
  },
  [SEARCH_SALE_TRIGGERS]: ({ commit }, params) => {
    return new Promise<void>((resolve) => {
      params = Object.assign({}, params, { limit: 0 });
      crudService
        .getResource("sale_trigger", params)
        .then((response) => {
          // garante a ordenação por id
          commit(
            SET_SALE_TRIGGERS,
            arrayJsonToClass(SaleTrigger, response.objects)
          );
          resolve();
        })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  [UPDATE_SALE_TRIGGERS]: ({ commit }, saleTrigger) => {
    return new Promise<void>((resolve) => {
      if (saleTrigger.id)
        commit(CHANGE_SALE_TRIGGERS, saleTrigger);
      else
        commit(ADD_SALE_TRIGGERS, saleTrigger);
      resolve();
    });
  },
  [DELETE_SALE_TRIGGERS]: ({ commit }, saleTrigger) => {
    return new Promise<void>((resolve) => {
      commit(REMOVE_SALE_TRIGGERS, saleTrigger);
      resolve();
    });
  },
  [LOAD_BEEF_CATTLE_BASIS]: async ({ getters, commit }) => {
    try {
      const cattleBasis = checkCacheExpiryVersion(
        "cache_cattle_basis",
        getters.appVersion
      );
      if (cattleBasis) {
        commit(SET_PRO_CATTLE_BASIS_REGIONS, Object.keys(cattleBasis));
        commit(SET_PRO_CATTLE_BASIS, cattleBasis);
      } else {
        const response = await profitService.getBasisBeefCattle();
        if (response) {
          commit(SET_PRO_CATTLE_BASIS_REGIONS, Object.keys(response));
          commit(SET_PRO_CATTLE_BASIS, response);
          // save to local store
          setCacheItem("cattle_basis", response, getters.appVersion, 1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  [UPDATE_CATTLE_QUOTATIONS_BANNER]: ({ commit }, quotations) => {
    commit(SET_CATTLE_BAR_QUOTATIONS, quotations);
  },
  [LOAD_EMPTY_ANONYMOUNS_PROFIT]: ({ commit }) => {
    commit(SET_PROFIT_OWNERSHIP_SELECTED, new ProfitCosts());
    commit(SET_PROFIT_PRICING, new Pricing());
  },
  /*##############
  # SPOT
  ################*/
  [LOAD_PROFIT_GRAIN_SPOT_STORE]: ({ commit, dispatch }, payload: IPayloadProfitGrainData) => {
    return new Promise<void>((resolve) => {
      const growing = payload.growing;
      const profit = payload.profit;
      let data = undefined;
      if (growing.isCotton) {
        // Algodão
        data = profit.cotton;
      } else if (growing.isCorn) {
        // Milho
        data = profit.corn;
      }else {
        // Soja
        data = profit.soybean;
      }
      data = data?.spot;
      data = data?.ownerships.find(el => el.id === payload.ownership.id);
      if(!data) data = new Spot();
      // Set ownership
      commit(SET_PRO_OWNERSHIP, data);
      // Precificação spot
      commit(SET_PROFIT_PRICING, data.pricing);
      // Set quotations
      commit(SET_PRO_QUOTATIONS, data.quotations);
      // Set profit income selected
      commit(SET_PRO_INCOME_OP, data.op_income);
      // Set income selected
      commit(SET_PRO_INCOMES, data.incomes);
      // Set fields selected
      commit(SET_PRO_FIELDS, data.fields);
      // Set ownership selected
      commit(SET_PROFIT_OWNERSHIP_SELECTED, data);
      /* Calc preço exw com base no frete
      o back só retorna o preço FCA
      o front calc o preço EXW antes de mostrar para o PPE
      pois precisa do frete */
      dispatch(CALC_SPOT_PPE_EXW_PRICE);
      resolve();
    });
  },
  [LOAD_SIMPLE_PROFIT_GRAIN_SPOT_STORE]: ({ commit, dispatch }, payload: IPayloadSimpleProfitGrainData) => {
    return new Promise<void>((resolve) => {
      const growing = payload.growing;
      const profit = payload.profit;
      let data = undefined;
      if (growing.isCotton) {
        // Algodão
        data = profit.cotton;
      } else if (growing.isCorn) {
        // Milho
        data = profit.corn;
      }else {
        // Soja
        data = profit.soybean;
      }
      data = data?.spot;
      if(!data) data = new Spot();
      // Set ownership
      commit(SET_PRO_OWNERSHIP, data);
      // Precificação spot
      commit(SET_PROFIT_PRICING, data.pricing);
      // Set quotations
      commit(SET_PRO_QUOTATIONS, data.quotations);
      // Set profit income selected
      commit(SET_PRO_INCOME_OP, data.op_income);
      // Set income selected
      commit(SET_PRO_INCOMES, data.incomes);
      // Set fields selected
      commit(SET_PRO_FIELDS, data.fields);
      // Set ownership selected
      commit(SET_PROFIT_OWNERSHIP_SELECTED, data);
      /* Calc preço exw com base no frete
      o back só retorna o preço FCA
      o front calc o preço EXW antes de mostrar para o PPE
      pois precisa do frete */
      dispatch(CALC_SPOT_PPE_EXW_PRICE);
      resolve();
    });
  },
  [UPDATE_ALL_SPOT]: ({ commit, dispatch }, payload) => {
    return new Promise<void>((resolve) => {
      // Precificação spot
      commit(SET_PROFIT_PRICING, payload.pricing);
      // Set quotations
      if (payload.quotations) {
        const instanceQuotatons = arrayJsonToClass(Quotes, payload.quotations);
        commit(SET_PRO_QUOTATIONS, instanceQuotatons);
      }
      // Set profit income selected
      commit(SET_PRO_INCOME_OP, payload.ownership.op_income);
      // Set income selected
      commit(SET_PRO_INCOMES, payload.ownership.incomes);
      // Set fields selected
      commit(SET_PRO_FIELDS, payload.ownership.fields);
      // Set ownership selected
      commit(SET_PROFIT_OWNERSHIP_SELECTED, payload.ownership);
      /* Calc preço exw com base no frete
      o back só retorna o preço FCA
      o front calc o preço EXW antes de mostrar para o PPE
      pois precisa do frete */
      dispatch(CALC_SPOT_PPE_EXW_PRICE);
      resolve();
    });
  },
  [CALC_SPOT_PPE_EXW_PRICE]: ({ state, commit }) => {
    if (Array.isArray(state.quotations)) {
      const pricing = { ...state.pricing };
      const dollar = filterQuotes("dollar", state.quotations)?.value || 1;
      // soybean and corn
      if (
        pricing.cbot_ppe_fca_rs_sc &&
        pricing.cbot_ppe_fca_us_sc &&
        state.profitOwnershipSelected
      ) {
        const logistic_sc = state.profitOwnershipSelected.logistic * 0.06;
        pricing.cbot_ppe_exw_rs_sc = pricing.cbot_ppe_fca_rs_sc - logistic_sc;
        pricing.cbot_ppe_exw_us_sc = pricing.cbot_ppe_exw_rs_sc / dollar;
      }
      // cotton
      if (
        pricing.nybot_ppe_fca_rs_arroba &&
        pricing.nybot_ppe_fca_us_arroba &&
        state.profitOwnershipSelected
      ) {
        const logistic_arroba = state.profitOwnershipSelected.logistic * 0.015; // logistica t para @
        pricing.nybot_ppe_exw_rs_arroba =
          pricing.nybot_ppe_fca_rs_arroba - logistic_arroba;
        pricing.nybot_ppe_exw_us_arroba =
          pricing.nybot_ppe_exw_rs_arroba / dollar;
      }
      // Save new price
      commit(SET_PROFIT_PRICING, pricing);
    }
  },
};

const getters: GetterTree<S, R> = {
  [ProfitGetterTypes.getProfitOwnership](state) {
    return state.profitOwnership;
  },
  [ProfitGetterTypes.getProfitOwnershipSelected](state) {
    return state.profitOwnershipSelected;
  },
  [ProfitGetterTypes.getProfitIncomeSelected](state) {
    return state.profitIncomeSelected;
  },
  [ProfitGetterTypes.getCottonConfigs](state) {
    if (state.profitOwnershipSelected)
      return state.profitOwnershipSelected.cotton_config;
    return undefined;
  },
  [ProfitGetterTypes.getProfitIncomeSelectedFiber](state) {
    if (state.profitIncomeSelected) return state.profitIncomeSelected.fiber;
    return undefined;
  },
  [ProfitGetterTypes.getProfitIncomeSelectedSeed](state) {
    if (state.profitIncomeSelected) return state.profitIncomeSelected.seed;
    return undefined;
  },
  [ProfitGetterTypes.getProfitMarginSelected](state) {
    return state.profitMarginSelected;
  },
  [ProfitGetterTypes.getProfitCattleLotSelected](state) {
    return state.profitCattleLot;
  },
  [ProfitGetterTypes.getProfitCattleLotPriceSelected](state) {
    return state.profitCattleLotPrice;
  },
  [ProfitGetterTypes.getCattleBasisRegions](state) {
    return state.cattleBasisRegions;
  },
  [ProfitGetterTypes.getCattleBasis](state) {
    return state.cattleBasis;
  },
  [ProfitGetterTypes.getProQuotations](state) {
    return state.quotations;
  },
  [ProfitGetterTypes.getCattleBarQuotations](state) {
    return state.cattleBarQuotations;
  },
  [ProfitGetterTypes.getProIncomes](state) {
    return state.incomes;
  },
  [ProfitGetterTypes.getProFields](state) {
    return state.fields;
  },
  [ProfitGetterTypes.getPrincing](state) {
    return state.pricing;
  },
  [ProfitGetterTypes.getPrincingSelected](state) {
    return state.pricingSelected;
  },
  [ProfitGetterTypes.getSalesSUM](state) {
    if (state.incomes) {
      return state.incomes.reduce((p, c) => p + c.amount, 0);
    }
    return 0;
  },
  [ProfitGetterTypes.getSaleTriggers](state) {
    return state.saleTriggers;
  },
  [ProfitGetterTypes.getPrices](state) {
    return state.prices;
  },
};
export default {
  state: new State(),
  mutations,
  actions,
  getters,
};

function fcaPriceDollar(flatprice: number, constants: Constants, state: IProfitModule): number {
  const fobbings = state.profitOwnershipSelected?.fobbings || 0;
  return (bushelToTon(flatprice, constants)-fobbings)*0.06;
}

function exwPriceReal(fcaReal: number, state: IProfitModule): number {
  const logistic = state.profitOwnershipSelected?.logistic || 0;
  return fcaReal-(logistic*0.06);
}