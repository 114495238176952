import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";

export interface IOperationMargin {
  op_roi_rs: number;
  op_roi_us?: number;
  revenue_amount_rs?: number;
  revenue_amount_rs_ha?: number;
  revenue_amount_us?: number;
  revenue_amount_us_ha?: number;
  total_cust_rs?: number;
  total_cust_us?: number;

  // Cattle
  lot_cost_arroba?: number;
  lot_cost_by_animal?: number;
  lot_revenue_arroba?: number;
  lot_revenue_by_animal?: number;
  lot_revenue_rs?: number;
  lot_total_cost?: number;
  op_roi_animal?: number;
  op_roi_arroba?: number;
}

export class OperationMarginDTO implements IOperationMargin {
  op_roi_rs = 0;
  op_roi_us: number | undefined = 0;
  revenue_amount_rs: number | undefined = 0;
  revenue_amount_rs_ha: number | undefined = 0;
  revenue_amount_us: number | undefined = 0;
  revenue_amount_us_ha: number | undefined = 0;
  total_cust_rs: number | undefined = 0;
  total_cust_us: number | undefined = 0;

  lot_cost_arroba: number | undefined = 0;
  lot_cost_by_animal: number | undefined = 0;
  lot_revenue_arroba: number | undefined = 0;
  lot_revenue_by_animal: number | undefined = 0;
  lot_revenue_rs: number | undefined = 0;
  lot_total_cost: number | undefined = 0;
  op_roi_animal: number | undefined = 0;
  op_roi_arroba: number | undefined = 0;

  constructor(dto?: IOperationMargin) {
    if(dto && !isEmptyObject(dto)) {
      this.op_roi_rs = stringToNumber(dto.op_roi_rs);
      this.op_roi_us = stringToNumberOrUndefined(dto.op_roi_us);
      this.revenue_amount_rs = stringToNumberOrUndefined(dto.revenue_amount_rs);
      this.revenue_amount_rs_ha = stringToNumberOrUndefined(dto.revenue_amount_rs_ha);
      this.revenue_amount_us = stringToNumberOrUndefined(dto.revenue_amount_us);
      this.revenue_amount_us_ha = stringToNumberOrUndefined(dto.revenue_amount_us_ha);
      this.total_cust_rs = stringToNumberOrUndefined(dto.total_cust_rs);
      this.total_cust_us = stringToNumberOrUndefined(dto.total_cust_us);

      this.lot_cost_arroba = stringToNumberOrUndefined(dto.lot_cost_arroba);
      this.lot_cost_by_animal = stringToNumberOrUndefined(dto.lot_cost_by_animal);
      this.lot_revenue_arroba = stringToNumberOrUndefined(dto.lot_revenue_arroba);
      this.lot_revenue_by_animal = stringToNumberOrUndefined(dto.lot_revenue_by_animal);
      this.lot_revenue_rs = stringToNumberOrUndefined(dto.lot_revenue_rs);
      this.lot_total_cost = stringToNumberOrUndefined(dto.lot_total_cost);
      this.op_roi_animal = stringToNumberOrUndefined(dto.op_roi_animal);
      this.op_roi_arroba = stringToNumberOrUndefined(dto.op_roi_arroba);
    }
  }
}
export class OperationMargin extends OperationMarginDTO {
  constructor();
  constructor(opMargin: IOperationMargin);
  constructor(opMargin?: IOperationMargin) {
    super(opMargin);
  }
}