import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import CattleLot from "./cattleLot";
import Currency from "./currency";
import Growing from "./growing";
import Harvest from "./harvest";
import Ownership from "./ownership";
import Provider from "./provider";
import SaleReason from "./saleReason";

export interface IIncome extends IModelBase {
  departure_date?: string;
  payment_date?: string;
  amount: number;
  value: number;
  price?: number;
  reference_value?: number;
  income_type: string;
  income_from?: string;
  cattle_weight?: number;
  cattle_yield_carcass?: number;
  provider_id: number;
  sale_reason_id?: number;
  ownership_id: number;
  harvest_id?: number;
  growing_id: number;
  currency_id: number;
  cattle_lot_id?: number;

  /**Relationship classes */
  provider?: Provider;
  sale_reason?: SaleReason;
  ownership?: Ownership;
  growing?: Growing;
  currency?: Currency;
  harvest?: Harvest;
  cattle_lot?: CattleLot;
}

export class IncomeDTO extends ModelBase implements IIncome {
  departure_date: string | undefined = undefined;
  payment_date: string | undefined = undefined;
  amount = 0;
  value = 0;
  price: number | undefined = undefined;
  reference_value: number | undefined = undefined;
  income_type = "RECEIVED";
  income_from: string | undefined = undefined;
  cattle_weight: number | undefined = undefined;
  cattle_yield_carcass: number | undefined = undefined;
  provider_id = 0;
  sale_reason_id: number | undefined = undefined;
  ownership_id = 0;
  harvest_id: number | undefined = undefined;
  growing_id = 0;
  currency_id = 0;
  cattle_lot_id: number | undefined = undefined;

  provider = new Provider();
  sale_reason = new SaleReason();
  ownership = new Ownership();
  growing = new Growing();
  currency = new Currency();
  harvest?: Harvest | undefined = undefined;
  cattle_lot?: CattleLot | undefined = undefined;

  constructor(dto?: IIncome) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.departure_date = dto.departure_date;
      this.payment_date = dto.payment_date;
      this.amount = stringToNumber(dto.amount);
      this.value = stringToNumber(dto.value);
      this.price = stringToNumberOrUndefined(dto.price);
      this.reference_value = stringToNumberOrUndefined(dto.reference_value);
      this.income_type = dto.income_type;
      this.income_from = dto.income_from;
      this.cattle_weight = stringToNumberOrUndefined(dto.cattle_weight);
      this.cattle_yield_carcass = stringToNumberOrUndefined(dto.cattle_yield_carcass);
      this.provider_id = dto.provider_id;
      this.sale_reason_id = dto.sale_reason_id;
      this.ownership_id = dto.ownership_id;
      this.harvest_id = dto.harvest_id;
      this.growing_id = dto.growing_id;
      this.currency_id = dto.currency_id;
      this.cattle_lot_id = dto.cattle_lot_id;

      if(dto.provider)
        this.provider = new Provider(dto.provider);
      if(dto.sale_reason)
        this.sale_reason = new SaleReason(dto.sale_reason);
      if(dto.ownership)
        this.ownership = new Ownership(dto.ownership);
      if(dto.growing)
        this.growing = new Growing(dto.growing);
      if(dto.currency)
        this.currency = new Currency(dto.currency);
      if(dto.harvest)
        this.harvest = new Harvest(dto.harvest);
      if(dto.cattle_lot)
        this.cattle_lot = new CattleLot(dto.cattle_lot);
    }
  }
}

export default class Income extends IncomeDTO {
  private static resource = "income";

  constructor();
  constructor(income: IIncome);
  constructor(income?: IIncome) {
    super(income);
  }

  public static getResource(): string {
    return this.resource;
  }
}
