<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="6.849mm"
    height="9.078mm"
    version="1.1"
    style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="0 0 11.25 14.91"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="__x0023_Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <circle class="fil0 str0" cx="5.62" cy="3.88" r="3.3" />
      <path
        class="fil0 str0"
        d="M0.58 14.91c0,-3.25 2.26,-5.89 5.05,-5.89 2.79,0 5.05,2.64 5.05,5.89"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.str0 {
  stroke: #066f6a;
  stroke-width: 1.16;
  stroke-miterlimit: 22.9256;
}
.fil0 {
  fill: none;
}
</style>