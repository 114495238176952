import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import City from "./city";
import Currency from "./currency";

export interface IProvider extends IModelBase {
  name: string;
  document?: string;
  legal_person?: boolean;
  company_name?: string;
  phone_number?: string
  email?: string;
  service?: string;
  city_id?: number;
  currency_id?: number;
  is_deleted: boolean
  deleted_date?: string;
  user_id: number;

  /**Relationship classes */
  city?: City;
  currency?: Currency;
}

export class ProviderDTO extends ModelBase implements IProvider {
  name = '';
  document: string | undefined = undefined;
  legal_person: boolean | undefined = false;
  company_name: string | undefined = undefined;
  phone_number: string | undefined = undefined;
  email: string | undefined = undefined;
  service: string | undefined = undefined;
  city_id: number | undefined = undefined;
  currency_id: number | undefined = undefined;
  is_deleted  = false;
  deleted_date: string | undefined = undefined;
  user_id = 0;

  city?: City | undefined = undefined
  currency?: Currency | undefined = undefined

  constructor(dto?: IProvider) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.document = dto.document;
      this.legal_person = dto.legal_person;
      this.company_name = dto.company_name;
      this.phone_number = dto.phone_number;
      this.email = dto.email;
      this.service = dto.service;
      this.city_id = dto.city_id;
      this.currency_id = dto.currency_id;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto.deleted_date;
      this.user_id = dto.user_id;

      if(dto.city)
        this.city = new City(dto.city);
      if(dto.currency)
        this.currency = new Currency(dto.currency);
    }
  }
}

export default class Provider extends ProviderDTO {
  private static resource = "provider";

  constructor();
  constructor(provider: IProvider);
  constructor(provider?: IProvider) {
    super(provider);
  }

  public static getResource(): string {
    return this.resource;
  }
}