import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Growing from "./growing";
import User from "./user";

export interface IUserBasisCity extends IModelBase {
  city: string;
  state: string;
  user_id: number;
  growing_id: number;

  /**Relationship classes */
  user?: User;
  growing?: Growing;
}

export class UserBasisCityDTO extends ModelBase implements IUserBasisCity {
  [keys: string]: string | number | User | Growing | undefined;

  city = '';
  state = '';
  user_id = 0;
  growing_id = 0;

  user = new User();
  growing = new Growing();


  constructor(dto?: IUserBasisCity) {
    super(dto);
    if (dto && !isEmptyObject(dto)) {
      this.city = dto.city;
      this.state = dto.state;
      this.user_id = dto.user_id;
      this.growing_id = dto.growing_id;
      if  (dto.user) {
        this.user = new User(dto.user);
      }
      if (dto.growing) {
        this.growing = new Growing(dto.growing);
      }
    }
  }
}

export default class UserBasisCity extends UserBasisCityDTO {
  private static resource = "user_basis_city";

  constructor();
  constructor(userBasisCity: IUserBasisCity);
  constructor(userBasisCity?: IUserBasisCity) {
    super(userBasisCity);
  }

  public static getResource(): string {
    return this.resource;
  }
}
