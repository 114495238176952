// auth
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT"
export const CHECK_AUTH = "CHECK_AUTH";
export const USER_OR_ANONYMOUS = "USER_OR_ANONYMOUS";
export const LOAD_ANONYMOUS = "LOAD_ANONYMOUS";
export const LOAD_USER = "LOAD_USER";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const SAVE_USER = "SAVE_USER";

// app
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const AGR_ALERT = "AGR_ALERT";

//crud
export const LOAD_ADDRESSES = "LOAD_ADDRESSES";
export const LOAD_GROWINGS = "LOAD_GROWINGS";
export const LOAD_HARVESTS = "LOAD_HARVESTS";
export const LOAD_CURRENCIES = "LOAD_CURRENCIES";
export const LOAD_MATURATION_GROUPS = "LOAD_MATURATION_GROUPS";
export const LOAD_OUTGOING_CATEGORIES = "LOAD_OUTGOING_CATEGORIES";
export const LOAD_SUBOUTGOING_TYPE = "LOAD_SUBOUTGOING_TYPE";
export const LOAD_SALE_REASON = "LOAD_SALE_REASON";
export const LOAD_UNITS = "LOAD_UNITS";
export const LOAD_CACHE_RESOURCES = "LOAD_CACHE_RESOURCES";
export const SAVE_UPDATE = "SAVE_UPDATE";
export const DELETE = "DELETE";
export const GET_BEEF_CATTLE_ID = "GET_BEEF_CATTLE_ID";

//searches
export const SEARCH_CRUD = "SEARCH_CRUD";
export const SIMPLE_SEARCH = "SIMPLE_SEARCH";
export const SEARCH_BY_ID = "SEARCH_BY_ID";
export const RESET_SEARCH = "RESET_SEARCH";
export const MY_OWNERSHIPS = "MY_OWNERSHIPS";
export const MY_LOTS = "MY_LOTS";
export const OWNERSHIP_VALID_CATTLE_LOT = "OWNERSHIP_VALID_CATTLE_LOT";
export const OWNERSHIPS_CLIENT_PROFIT = "OWNERSHIPS_CLIENT_PROFIT";

//calculations
export const UPDATE_STATE_SOY_CORN = "UPDATE_STATE_SOY_CORN";
export const UPDATE_STATE_COTTON = "UPDATE_STATE_COTTON";
export const UPDATE_STATE_CATTLE = "UPDATE_STATE_CATTLE";
export const UPDATE_STATE_SPOT = "UPDATE_STATE_SPOT";
export const EXTRACT_QUOTATIONS = "EXTRACT_QUOTATIONS";

//profit
export const UPDATE_PROFIT = "UPDATE_PROFIT";
export const UPDATE_ALL = "UPDATE_ALL";
export const UPDATE_ALL_SIMPLE = "UPDATE_ALL_SIMPLE";
export const UPDATE_PROFIT_GROWING = "UPDATE_PROFIT_GROWING";
export const UPDATE_PROFIT_HARVEST = "UPDATE_PROFIT_HARVEST";
export const UPDATE_PROFIT_OWNERSHIP_SELECTED = "UPDATE_PROFIT_OWNERSHIP_SELECTED";
export const UPDATE_PROFIT_INCOME_SELECTED = "UPDATE_PROFIT_INCOME_SELECTED";
export const UPDATE_PROFIT_MARGIN_SELECTED = "UPDATE_PROFIT_MARGIN_SELECTED";
export const UPDATE_PROFIT_FLAT_PRICE = "UPDATE_PROFIT_FLAT_PRICE";
export const UPDATE_PROFIT_PRICING = "UPDATE_PROFIT_PRICING";
export const UPDATE_PROFIT_CATTLE_LOT = "UPDATE_PROFIT_CATTLE_LOT";
export const UPDATE_PRO_QUOTATIONS = "UPDATE_PRO_QUOTATIONS";
export const UPDATE_PRO_OWNERSHIP = "UPDATE_PRO_OWNERSHIP";
export const UPDATE_PRO_FIELDS = "UPDATE_PRO_FIELDS";
export const UPDATE_PRO_INCOMES = "UPDATE_PRO_INCOMES";
export const RELOAD_PROFIT = "RELOAD_PROFIT";
export const SEARCH_SALE_TRIGGERS = "SEARCH_SALE_TRIGGERS";
export const UPDATE_SALE_TRIGGERS = "UPDATE_SALE_TRIGGERS";
export const DELETE_SALE_TRIGGERS = "DELETE_SALE_TRIGGERS";
export const LOAD_BEEF_CATTLE_BASIS = "LOAD_BEEF_CATTLE_BASIS";
export const LOAD_PROFIT_GRAIN_STORE = "LOAD_PROFIT_GRAIN_STORE";
export const LOAD_PROFIT_GRAIN_SPOT_STORE = "LOAD_PROFIT_GRAIN_SPOT_STORE";
export const LOAD_SIMPLE_PROFIT_GRAIN_SPOT_STORE = "LOAD_SIMPLE_PROFIT_GRAIN_SPOT_STORE";
export const LOAD_SIMPLE_PROFIT_GRAIN_STORE = "LOAD_SIMPLE_PROFIT_GRAIN_STORE";
export const LOAD_PROFIT_CATTLE_STORE = "LOAD_PROFIT_CATTLE_STORE";
export const LOAD_SIMPLE_PROFIT_CATTLE_STORE = "LOAD_SIMPLE_PROFIT_CATTLE_STORE";
export const UPDATE_CATTLE_QUOTATIONS_BANNER = "UPDATE_CATTLE_QUOTATIONS_BANNER";
export const LOAD_EMPTY_ANONYMOUNS_PROFIT = "LOAD_EMPTY_ANONYMOUNS_PROFIT";

// spot
export const UPDATE_ALL_SPOT = "UPDATE_ALL_SPOT";
export const CALC_SPOT_PPE_EXW_PRICE = "CALC_SPOT_PPE_EXW_PRICE";

// tradeslip
export const PRE_LOAD = "PRE_LOAD";
export const POS_LOAD = "POS_LOAD";
export const PRICING_LOAD = "PRICING_LOAD";
export const EXCHANGE_LOAD = "EXCHANGE_LOAD";
export const SEARCH_TRADESLIP_DELIVERY_PERIOD = "SEARCH_TRADESLIP_DELIVERY_PERIOD";
export const SEARCH_TRADESLIP_EXCHANGE_ACC = "SEARCH_TRADESLIP_EXCHANGE_ACC";
export const LOAD_CONTRACT_RESULT = "LOAD_CONTRACT_RESULT";

// Sysadmin
export const LOAD_ALL_RESOURCES = "LOAD_ALL_RESOURCES";
export const MAKE_HARVEST_TURN = "MAKE_HARVEST_TURN";

// Analytics
// History
export const SEARCH_BASIS_SHIPMENT = "SEARCH_BASIS_SHIPMENT";
export const UPDATE_BASIS_SHIPMENT = "UPDATE_BASIS_SHIPMENT";
export const DELETE_BASIS_SHIPMENT = "DELETE_BASIS_SHIPMENT";
// Projection
export const SEARCH_USER_ALLOWED_CITY = "SEARCH_USER_ALLOWED_CITY";
export const UPDATE_USER_ALLOWED_CITY = "UPDATE_USER_ALLOWED_CITY";
export const DELETE_USER_ALLOWED_CITY = "DELETE_USER_ALLOWED_CITY";