import store from "@/store/index";
import {
  AUTH,
  CHECK_AUTH,
  USER_OR_ANONYMOUS,
  LOAD_ANONYMOUS,
} from "@/store/actions-types";
import { NavigationGuardNext, Route } from "vue-router";

const auth = (to: Route, from: Route, next: NavigationGuardNext): void => {
  store.dispatch(AUTH, to.query.token).then(() => {
    next("");
  });
};

const checkAuth = (to: Route, from: Route, next: NavigationGuardNext): void => {
  store
    .dispatch(CHECK_AUTH)
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        if (to.meta && to.meta[store.getters.getLoggedUserRole]) {
          next();
        } else {
          next("/Unauthorized");
        }
      } else {
        next({ path: "/authenticate", query: { redirect: to.fullPath } });
      }
    })
    .catch(() => {
      next("/NotFound");
    });
};

const openPaths = (to: Route, from: Route, next: NavigationGuardNext): void => {
  store.dispatch(USER_OR_ANONYMOUS).then((isAuthenticated) => {
    if (isAuthenticated) {
      next();
    } else {
      if(to.name == "Profit") next();
      else next({ path: "/profit" });
    }
  });
};

const loadAnonymous = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void => {
  store.dispatch(LOAD_ANONYMOUS).then(() => {
    next();
  });
};

export default {
  auth,
  checkAuth,
  openPaths,
  loadAnonymous,
};
