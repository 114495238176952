import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface ISaleReason extends IModelBase {
  name: string;
  is_deleted: boolean;
  deleted_date?: string;
}

export class SaleReasonDTO extends ModelBase implements ISaleReason {
  name = "";
  is_deleted = false;
  deleted_date?: string | undefined = undefined;

  constructor(dto?: ISaleReason) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto.deleted_date;
    }
  }
}

export default class SaleReason extends SaleReasonDTO {
  constructor();
  constructor(saleReason: ISaleReason);
  constructor(saleReason?: ISaleReason) {
    super(saleReason);
  }
}
