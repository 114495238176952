
import Vue from "vue";

import City from "@/models/city";
import { mapGetters } from "vuex";

interface ICityList {
  id: number;
  name: string;
}

export default Vue.extend({
  name: "CitySelector",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: "Cidade"
    },
    color: {
      type: String,
      default: "primary"
    },
    fieldRequired: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      city_id: undefined as string | number | undefined,
      cityRules: undefined as Array<(x: string) => boolean | string> | undefined,
    }
  },
  created() {
    if(this.fieldRequired) this.cityRules = [v => !!v || "Informe a cidade"];
  },
  computed: {
    ...mapGetters({
      cities: "getCities",
    }),
    cityList(): ICityList[] {
      return this.cities.map((c: City) => {
        return {
          id: c.id,
          name: `${c.name}, ${c.state.abbreviation} - ${c.state.country.name}`
        }
      });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue): void {
        if(newValue) {
          this.city_id = newValue;
        }else{
          this.city_id = undefined;
        }
      }
    }
  }
});
