
import Vue from "vue";

export default Vue.extend({
  props: {
    msg: {
      type: String,
      default: "Deseja realmente excluir?"
    },
    text: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    exclude(): void {
      this.dialog = true;
    },
    confirmDelete(): void {
      this.dialog = false;
      this.$emit("confirmDelete", this.item);
    }
  }
});
