
import Vue from "vue";

import { DataTableHeader } from "vuetify";

export default Vue.extend({
  name: "AgrDataTable",
  props: {
    headers: Array as () => DataTableHeader[],
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "Carregando...",
    },
    color: {
      type: String,
      default: "primary",
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    // habilita os btn de action na linha
    btnActions: {
      type: Boolean,
      default: false,
    },
    // utilizado na action de duplicação
    resource: {
      type: String,
      default: "",
    },
    // mostra o btn de talhão nas actions
    btnFields: {
      type: Boolean,
      default: false,
    },
    // tamanho da tabela
    height: {
      type: String,
      default: "",
    },
    // prop para ordenar a tabela
    sortBy: {
      type: String,
      default: "",
    },
    // ordenar descendente
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      crudClass: "font-weight-bold text-h4 text-md-h5 text-sm-subtitle-1",
    };
  },
  computed: {
    informedSlots(): Array<string> {
      return Object.keys(this.$scopedSlots);
    },
  },
  methods: {
    validateProps(): void {
      if (this.btnActions && !this.resource)
        console.error(
          "When btn-action is true, you must informa the resource!"
        );
    },
  },
});
