import axios from "@/plugins/axios";
import { ITradeslipResult } from "@/types/service-types";
import { IParams } from "@/types/store-types";
import { isValidResponse } from "@/utilsObject";

const processResult = async (params: IParams): Promise<ITradeslipResult> => {
  const response = await axios.httpApi.get("tradeslips/process-result", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to process result tradeslip: ${response.statusText}`);
  return response.data;
}

export const tradeslipService = {
  processResult
}