import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Growing from "./growing";

export interface IMaturationGroup extends IModelBase {
  name: string;
  days: string;
  growing_id: number;

  /**Relationship classes */
  growing?: Growing;
}

export class MaturationGroupDTO extends ModelBase implements IMaturationGroup {
  name = "";
  days = "";
  growing_id = 0;

  growing = new Growing();

  constructor(dto?: IMaturationGroup) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.days = dto.days;
      this.growing_id = dto.growing_id;

      if(dto.growing)
        this.growing = new Growing(dto.growing);
    }
  }
}

export default class MaturationGroup extends MaturationGroupDTO {
  constructor();
  constructor(maturationGroup: IMaturationGroup);
  constructor(maturationGroup?: IMaturationGroup) {
    super(maturationGroup);
  }
}
