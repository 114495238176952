
import Vue from "vue";

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: null
    },
  },
  methods: {
    onClick(): void {
      this.$emit("onClick", this.item);
    }
  }
});
