import Vue from "vue";

import {
  SET_BASIS_SHIPMENT,
  ADD_BASIS_SHIPMENT,
  CHANGE_BASIS_SHIPMENT,
  REMOVE_BASIS_SHIPMENT,

  SET_USER_ALLOWED_CITY,
  ADD_USER_ALLOWED_CITY,
  CHANGE_USER_ALLOWED_CITY,
  REMOVE_USER_ALLOWED_CITY,

  SET_MONTHLY_PROJECTION_DATA_TABLE,
} from "@/store/mutations-types";

import {
  SEARCH_BASIS_SHIPMENT,
  UPDATE_BASIS_SHIPMENT,
  DELETE_BASIS_SHIPMENT,
  SEARCH_USER_ALLOWED_CITY,
  UPDATE_USER_ALLOWED_CITY,
  DELETE_USER_ALLOWED_CITY
} from "@/store/actions-types";

import BasisShipment from "@/models/basisShipment";
import UserBasisCity from "@/models/userBasisCity";

import { AnalyticsGetterTypes, IAnalyticsModule, IMonthlyProjectionDataTable, IRootState } from "@/types/store-types";

import { ActionTree, GetterTree, MutationTree } from "vuex";
import { crudService } from "@/services/crud";
import { arrayJsonToClass } from "@/utilsObject";

type S = IAnalyticsModule;
type R = IRootState;

class State implements IAnalyticsModule {
  basisShipments: BasisShipment[] = [];
  userBasisCities: UserBasisCity[] = [];
  monthlyProjectionDataTable: IMonthlyProjectionDataTable[] = [];
}

const mutations: MutationTree<S> = {
  [SET_BASIS_SHIPMENT]: (state, basisShipments: BasisShipment[]) => {
    state.basisShipments = basisShipments;
  },
  [SET_USER_ALLOWED_CITY]: (state, userBasisCities: UserBasisCity[]) => {
    state.userBasisCities = userBasisCities;
  },
  [SET_MONTHLY_PROJECTION_DATA_TABLE]: (state, monthlyProjectionDataTable: IMonthlyProjectionDataTable[]) => {
    state.monthlyProjectionDataTable = monthlyProjectionDataTable;
  },

  [ADD_BASIS_SHIPMENT]: (state, basisShipments: BasisShipment) => {
    if (state.basisShipments.length > 0) {
      const lastBasisShipment = state.basisShipments[state.basisShipments.length - 1];
      if (lastBasisShipment?.id) {
        basisShipments.id = lastBasisShipment.id + 1
      } else {
        basisShipments.id = 1
      }
    } else {
      basisShipments.id = 1
    }
    state.basisShipments.push(basisShipments)
  },
  [ADD_USER_ALLOWED_CITY]: (state, userBasisCities: UserBasisCity) => {
    if (state.userBasisCities.length > 0) {
      const lastUserBasisCity = state.userBasisCities[state.userBasisCities.length - 1];
      if (lastUserBasisCity?.id) {
        userBasisCities.id = lastUserBasisCity.id + 1
      } else {
        userBasisCities.id = 1
      }
    } else {
      userBasisCities.id = 1
    }
    state.userBasisCities.push(userBasisCities)
  },

  [CHANGE_BASIS_SHIPMENT]: (state, basisShipments: BasisShipment) => {
    const index = state.basisShipments.findIndex(
      (element) => element.id === basisShipments.id
    );
    if (index > -1) Vue.set(state.basisShipments, index, basisShipments);
  },
  [CHANGE_USER_ALLOWED_CITY]: (state, userBasisCities: UserBasisCity) => {
    const index = state.userBasisCities.findIndex(
      (element) => element.id === userBasisCities.id
    );
    if (index > -1) Vue.set(state.userBasisCities, index, userBasisCities);
  },

  [REMOVE_BASIS_SHIPMENT]: (state, idBasisShipment: number) => {
    const elementsIndex = state.basisShipments.findIndex(
      (element) => element.id == idBasisShipment
    );
    if (elementsIndex > -1) {
      Vue.delete(state.basisShipments, elementsIndex);
    }
  },
  [REMOVE_USER_ALLOWED_CITY]: (state, idUserBasisCity: number) => {
    const elementsIndex = state.userBasisCities.findIndex(
      (element) => element.id == idUserBasisCity
    );
    if (elementsIndex > -1) {
      Vue.delete(state.userBasisCities, elementsIndex);
    }
  },
};

const actions: ActionTree<S, R> = {
  // SEARCHES
  [SEARCH_BASIS_SHIPMENT]: ({ commit }, params) => {
    return new Promise<void>((resolve) => {
      params = Object.assign({}, params, { limit: 0 });

      crudService.getResourceClass(
        BasisShipment,
        BasisShipment.getResource(),
        params
      ).then((response) => {
        // garante a ordenação por id
        commit(
          SET_BASIS_SHIPMENT,
          arrayJsonToClass(BasisShipment, response.objects)
        );
        resolve();
      })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  [SEARCH_USER_ALLOWED_CITY]: ({ commit }, params) => {
    return new Promise<void>((resolve) => {
      params = Object.assign({}, params, { limit: 0 });

      crudService.getResourceClass(
        UserBasisCity,
        UserBasisCity.getResource(),
        params
      ).then((response) => {
        // garante a ordenação por id
        commit(
          SET_USER_ALLOWED_CITY,
          arrayJsonToClass(UserBasisCity, response.objects)
        );
        resolve();
      })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  // UPDATES
  [UPDATE_BASIS_SHIPMENT]: ({ commit }, basisShipment) => {
    return new Promise<void>((resolve) => {
      if (basisShipment.id)
        commit(CHANGE_BASIS_SHIPMENT, basisShipment);
      else
        commit(ADD_BASIS_SHIPMENT, basisShipment);
      resolve();
    });
  },
  [UPDATE_USER_ALLOWED_CITY]: ({ commit }, userBasisCities) => {
    return new Promise<void>((resolve) => {
      if (userBasisCities.id)
        commit(CHANGE_USER_ALLOWED_CITY, userBasisCities);
      else
        commit(ADD_USER_ALLOWED_CITY, userBasisCities);
      resolve();
    });
  },
  // DELETES
  [DELETE_BASIS_SHIPMENT]: ({ commit }, basisShipment) => {
    return new Promise<void>((resolve) => {
      commit(REMOVE_BASIS_SHIPMENT, basisShipment);
      resolve();
    });
  },
  [DELETE_USER_ALLOWED_CITY]: ({ commit }, basisShipment) => {
    return new Promise<void>((resolve) => {
      commit(REMOVE_USER_ALLOWED_CITY, basisShipment);
      resolve();
    });
  },
};

const getters: GetterTree<S, R> = {
  [AnalyticsGetterTypes.getBasisShipments](state) {
    return state.basisShipments;
  },
  [AnalyticsGetterTypes.getUserBasisCities](state) {
    return state.userBasisCities;
  },
  [AnalyticsGetterTypes.getMonthlyProjectionDataTable](state) {
    return state.monthlyProjectionDataTable;
  },
}

export default {
  state: new State(),
  mutations,
  actions,
  getters
}