
import Vue from "vue";

export default Vue.extend({
  props: {
    item: {
      type: Object,
      default: null,
    },
    // mostra o btn de duplicar
    resource: {
      type: String,
      default: "",
    },
    // mostra o btn de talhões
    fields: {
      type: Boolean,
      default: false,
    },
    // mostra o btn de liquidação
    settled: {
      type: Boolean,
      default: false,
    },
    // mostra o btn de ativo/desativado
    active: {
      type: Boolean,
      default: false,
    },
    // btn pequenos
    // independente do tamanho da tela
    small: {
      type: Boolean,
      default: false,
    },
    // mostra btn customizado
    extraBtn: {
      type: Boolean,
      default: false,
    },
    // text do btn customizado
    extraText: {
      type: String,
      default: "",
    },
    // icon do btn customizado
    extraIcon: {
      type: String,
      default: "mdi-cash-check",
    },
    // monstra os 3 pontos
    dots: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    smallBtn(): boolean {
      return this.$vuetify.breakpoint.mdAndDown || this.small;
    },
  },
});
