import axios from "@/plugins/axios";
import { Profit } from "@/business/Profit";
import { IParams } from "@/types/store-types";
import { isValidResponse } from "@/utilsObject";
import { SimpleProfit } from "@/business/SimpleProfit";
import ProfitN1 from "@/models/profitN1";
import { IResourcesProfitAnonymous } from "@/types/profit-types";

const getProfit = async (client?: number): Promise<Profit> => {
  let response = undefined;
  if (client) {
    const params = { user: client };
    response = await axios.httpApi.get<Profit>("profit/global", { params });
  } else {
    response = await axios.httpApi.get<Profit>("profit/global");
  }
  if (isValidResponse(response.status)) {
    return new Profit(response.data);
  }
  return response.data;
};

const getSimpleProfit = async (client?: number): Promise<SimpleProfit> => {
  let response = undefined;
  if (client) {
    const params = { user: client };
    response = await axios.httpApi.post<SimpleProfit>("profit/simple", { params });
  } else {
    response = await axios.httpApi.post<SimpleProfit>("profit/simple");
  }
  if (isValidResponse(response.status)) {
    return new SimpleProfit(response.data);
  }
  return response.data;
};

const saveSimpleProfit = async (data: ProfitN1): Promise<ProfitN1> => {
  const response = await axios.httpApi.post<ProfitN1>("profit_n1", data);
  if (isValidResponse(response.status)) {
    return new ProfitN1(response.data);
  }
  console.error(`saveSimpleProfit: response status ${response.statusText}`);
  return response.data;
};

const anonymounsProfit = async (data?: ProfitN1): Promise<SimpleProfit | IResourcesProfitAnonymous> => {
  const profit = { profit: data };
  const response = await axios.httpApi.post("anonymous/profit", profit);
  if (isValidResponse(response.status)) {
    if(data)
      return new SimpleProfit(response.data);
    else
      return response.data;
  }
  console.error(`anonymounsProfit: response status ${response.statusText}`);
  return response.data;
};

const saveCottonConfigs = async (data: IParams): Promise<void> => {
  let response = undefined;
  if (data.id) {
    response = await axios.httpApi.patch("cotton_config/" + data.id, data);
  } else {
    response = await axios.httpApi.post("cotton_config", data);
  }
  return response.data;
};

const getBasisBeefCattle = async (): Promise<string[]> => {
  const response = await axios.httpApi.get("quotations/basis_cattle");
  return response.data;
};

export const profitService = {
  getProfit,
  getSimpleProfit,
  saveSimpleProfit,
  anonymounsProfit,
  saveCottonConfigs,
  getBasisBeefCattle,
};
