import Vue from 'vue';
import { io } from "socket.io-client";
import VueSocketIOExt from 'vue-socket.io-extended';

const options = {
  path: '/socket.io/',
  autoConnect: false
};

const socket = io(process.env.VUE_APP_SOCKET_URL, options);

Vue.use(VueSocketIOExt, socket);
