import { isEmptyObject } from "@/utilsObject";

export interface IQuoteReference {
  year: string;
  month_show: string;
  growing?: string;
}

export class QuoteReferenceDTO implements IQuoteReference {
  year = "-";
  month_show = "-";
  growing: string | undefined = undefined;

  constructor(dto?: IQuoteReference) {
    if (dto && !isEmptyObject(dto)) {
      this.month_show = dto.month_show;
      this.year = dto.year;
      this.growing = dto.growing;
    }
  }
}

export class QuoteReference extends QuoteReferenceDTO {
  constructor();
  constructor(quoteReference: IQuoteReference);
  constructor(quoteReference?: IQuoteReference) {
    super(quoteReference);
  }

  /* getters */
  get full(): string {
    if (this.month_show === 'PTAX') return this.month_show;
    return `${this.month_show}/${this.year}`;
  }
}

export interface IQuotes {
  code: string;
  value: number;
  arrow: number;
  identifier: string;
  name: string;
  unit: string;
  decimal: number;
  date?: string;
  ref: QuoteReference;
}

class QuotesDTO implements IQuotes {
  code = "-/-";
  value = 0;
  arrow = 0;
  name = "-";
  unit = "-";
  decimal = 0;
  identifier = "";
  date: string | undefined = undefined;
  ref = new QuoteReference();

  constructor(dto?: IQuotes) {
    if (dto && !isEmptyObject(dto)) {
      this.code = dto.code;
      this.value = dto.value;
      this.arrow = dto.arrow;
      this.identifier = dto.identifier;
      this.name = dto.name;
      this.unit = dto.unit;
      this.decimal = dto.decimal;
      this.date = dto.date;
      this.ref = new QuoteReference(dto.ref);
    }
  }
}

export class Quotes extends QuotesDTO {
  constructor();
  constructor(quotes: IQuotes);
  constructor(quotes?: IQuotes) {
    super(quotes);
  }
}

export interface IQuotations {
  dollar: Quotes;
  se: Quotes;
  basis: Quotes;
  ref: QuoteReference;
}

export interface IOnlineQuotations {
  jan?: Quotations;
  mar?: Quotations;
  mai?: Quotations;
  set?: Quotations;
  dez?: Quotations;
}

export class QuotationsDTO implements IQuotations {
  dollar = new Quotes();
  se = new Quotes();
  basis = new Quotes();
  ref = new QuoteReference();

  constructor(dto?: IQuotations) {
    if (dto && !isEmptyObject(dto)) {
      this.dollar = new Quotes(dto.dollar);
      this.se = new Quotes(dto.se);
      this.basis = new Quotes(dto.basis);
      this.ref = new QuoteReference(dto.ref);
    }
  }
}

export class Quotations extends QuotationsDTO {
  constructor();
  constructor(quotations: IQuotations);
  constructor(quotations?: IQuotations) {
    super(quotations);
  }
}

export interface ICattleQuotations {
  se: Quotes;
  ref: QuoteReference;
}

export class CattleQuotationsDTO implements ICattleQuotations {
  se = new Quotes();
  ref = new QuoteReference()

  constructor(dto?: ICattleQuotations) {
    if (dto && !isEmptyObject(dto)) {
      this.se = new Quotes(dto.se);
      this.ref = new QuoteReference(dto.ref);
    }
  }
}

export class CattleQuotations extends CattleQuotationsDTO {
  constructor();
  constructor(cattleQuotations: ICattleQuotations);
  constructor(cattleQuotations?: ICattleQuotations) {
    super(cattleQuotations);
  }
}

export interface IQuotationsDate {
  dollar?: Array<IQuotes>;
  basis?: {
    exw?: Array<IQuotes>,
    fob?: Array<IQuotes>
  }
  se?: {
    b3?: Array<IQuotes>;
    cbot?: Array<IQuotes>;
    nybot?: Array<IQuotes>;
  }
}

export class QuotationsDateDTO implements IQuotationsDate {
  dollar = [] as Quotes[];
  basis = {
    exw: [] as Quotes[],
    fob: [] as Quotes[]
  };
  se = {
    b3: [] as Quotes[],
    cbot: [] as Quotes[],
    nybot: [] as Quotes[],
  }

  constructor(dto?: IQuotationsDate) {
    if (dto && !isEmptyObject(dto)) {
      if (dto.dollar) {
        this.dollar = dto.dollar.map((element) => new Quotes(element));
      }
      if (dto.basis) {
        if (dto.basis.exw) {
          this.basis.exw = dto.basis.exw.map((element) => new Quotes(element));
        }
        if (dto.basis.fob) {
          this.basis.fob = dto.basis.fob.map((element) => new Quotes(element));
        }
      }
      if (dto.se) {
        if (dto.se.b3) {
          this.se.b3 = dto.se.b3.map((element) => new Quotes(element));
        }
        if (dto.se.cbot) {
          this.se.cbot = dto.se.cbot.map((element) => new Quotes(element));
        }
        if (dto.se.nybot) {
          this.se.nybot = dto.se.nybot.map((element) => new Quotes(element));
        }

      }
    }
  }
}

export class QuotationsDate extends QuotationsDateDTO {
  constructor();
  constructor(quotations: IQuotationsDate);
  constructor(quotations?: IQuotationsDate) {
    super(quotations);
  }
}