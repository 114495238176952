
import Vue from 'vue';
import Echarts from 'vue-echarts';

// CHART TYPE
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/heatmap';

// CHART COMPONENT
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';

// CHART PLUGIN
import 'echarts-gl';

Vue.component('chart', Echarts);