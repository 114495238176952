import { RouteConfig } from "vue-router";
import utils from "./utils";

const charts: RouteConfig = {
  name: 'Análises',
  path: '/analytics',
  component: () => import('@/views/Analytics.vue'),
  children: [
    {
      name: 'Histórico Basis',
      path: 'basis_history',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/analytics/Basis.vue'),
      meta: {
        admin: true,
        broker: true,
        N1: true,
        N2: true,
        N3: true
      }
    },
    {
      name: 'Projeção do Basis',
      path: 'basis_projection',
      beforeEnter: utils.checkAuth,
      component: () => import('@/components/analytics/BasisProjection.vue'),
      meta: {
        admin: true,
        broker: true,
        N3: true
      }
    },
  ]
}

export default charts;