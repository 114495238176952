import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import i18n from "@/plugins/i18n";

export interface IGrowingDTO extends IModelBase {
  name: string;
  symbol: string;
  unit_id: number;
}
export interface IGrowing extends IModelBase {
  nameEnglish: string;
  symbol: string;
  unit_id: number;
}

export class GrowingDTO extends ModelBase implements IGrowing {
  nameEnglish = "";
  symbol = "";
  unit_id = 0;

  constructor(dto?: IGrowingDTO) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      // en-US name
      this.nameEnglish = dto.name;
      // pt-br name
      this.symbol = dto.symbol;
      this.unit_id = dto.unit_id;
    }
  }
}

export default class Growing extends GrowingDTO {
  private static resource = "growing";

  constructor();
  constructor(growing: IGrowingDTO);
  constructor(growing?: IGrowingDTO) {
    super(growing);
  }
  public static getResource(): string {
    return this.resource;
  }
  /* getters */
  get name(): string {
    return i18n.t(this.nameEnglish).toString();
  }
  get isSoybean(): boolean {
    return this.symbol === "S";
  }
  get isCorn(): boolean {
    return this.symbol === "C";
  }
  get isCotton(): boolean {
    return this.symbol === "CT";
  }
  get isCattle(): boolean {
    return this.symbol === "BC";
  }
}
