var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-0 table-full-width",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":_vm.loadingText,"hide-default-footer":_vm.hideDefaultFooter,"color":_vm.color,"height":_vm.height,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-arrow-left',
    nextIcon: 'mdi-arrow-right',
  }},on:{"click:row":function($event){return _vm.$emit('click:row', $event)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(th,index){return {key:`header.`+th.value,fn:function({ header }){return [_c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({class:_vm.crudClass},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}}}),_vm._l((_vm.informedSlots),function(slot){return {key:`item.${slot}`,fn:function({ item }){return [_vm._t(slot,null,{"item":item})]}}}),(_vm.btnActions)?{key:`item.actions`,fn:function({ item }){return [_c('agr-actions-buttons-table',{attrs:{"item":item,"fields":_vm.btnFields,"resource":_vm.resource},on:{"redirectfields":function($event){return _vm.$emit('redirectfields', item)},"edit":function($event){return _vm.$emit('edit', item)},"confirmDelete":function($event){return _vm.$emit('confirmDelete', item)},"duplicate":(i, r) => _vm.$emit('duplicate', i, r)}})]}}:null,{key:"no-data",fn:function(){return [_c('v-container',{staticStyle:{"background-color":"transparent"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('strong',{staticClass:"py-2 inspect--text text-h4"},[_vm._v("Nenhum dado encontrado")])],1)],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }