import City from "@/models/city";
import Country from "@/models/country";
import Currency from "@/models/currency";
import Growing from "@/models/growing";
import Harvest from "@/models/harvest";
import MaturationGroup from "@/models/maturationGroup";
import State from "@/models/state";
import axios from "@/plugins/axios";
import SaleReason from "@/models/saleReason";
import Unit from "@/models/unit";

import {
  OutgoingCategory,
  SubOutgoingType,
} from "@/models/outgoingCategory";
import { IArrayParams, IParams, ISearchResponseSimple } from "@/types/store-types";
import { isValidResponse } from "@/utilsObject";
import { QuotationsDate } from "@/business/Quotations";

const params = { limit: 0 };

const getAllCountries = async (): Promise<ISearchResponseSimple<Country>> => {
  const response = await axios.httpApi.get("country", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get All Contries: ${response.statusText}`);

  return response.data
};

const getAllStates = async (): Promise<ISearchResponseSimple<State>> => {
  const response = await axios.httpApi.get("state", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get All States: ${response.statusText}`);

  return response.data;
};

const getAllCities = async (): Promise<ISearchResponseSimple<City>> => {
  const response = await axios.httpApi.get("city", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get All Cities: ${response.statusText}`);

  return response.data;
};

const getGrowings = async (): Promise<ISearchResponseSimple<Growing>> => {
  const response = await axios.httpApi.get("growing", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Growings: ${response.statusText}`);

  return response.data;
};

const getHarvests = async (): Promise<ISearchResponseSimple<Harvest>> => {
  const response = await axios.httpApi.get("harvest", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Harvest: ${response.statusText}`);

  return response.data;
};

const getMaturationGroups = async (): Promise<ISearchResponseSimple<MaturationGroup>> => {
  const response = await axios.httpApi.get("maturation_group", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Maturation Groups: ${response.statusText}`);

  return response.data;
};

const getCurrencies = async (): Promise<ISearchResponseSimple<Currency>> => {
  const response = await axios.httpApi.get("currency", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Currencies: ${response.statusText}`);

  return response.data;
};

const getOutgoingCategories = async (): Promise<ISearchResponseSimple<OutgoingCategory>> => {
  const response = await axios.httpApi.get("outgoing_category", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Outgoing Categories: ${response.statusText}`);

  return response.data;
};
const getSubOutgoingType = async (): Promise<ISearchResponseSimple<SubOutgoingType>> => {
  const response = await axios.httpApi.get("suboutgoing_type", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Suboutgoing categories: ${response.statusText}`);

  return response.data;
};
const getSaleReason = async (): Promise<ISearchResponseSimple<SaleReason>> => {
  const response = await axios.httpApi.get("sale_reason", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Sale Reason: ${response.statusText}`);

  return response.data;
};
const getUnits = async (): Promise<ISearchResponseSimple<Unit>> => {
  const response = await axios.httpApi.get("unit", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Units: ${response.statusText}`);

  return response.data;
};
const getQuotationsByDate = async (params: IParams): Promise<unknown> => {
  const response = await axios.httpApi.post("quotations/filtered_quotes", params);
  if (!isValidResponse(response.status))
    console.error(`Error to get Quotaions by date: ${response.statusText}`);

  return response.data;
};
const getQuotationsByArrayOfDate = async (params: IArrayParams): Promise<QuotationsDate> => {
  const response = await axios.httpApi.post("quotations/get_quotes", params);
  if (isValidResponse(response.status))
    return new QuotationsDate(response.data);
  else
    console.error(`Error to get Quotaions by date: ${response.statusText}`);

  return response.data;
};
const getQuotationsBySecutiry = async (params: IParams): Promise<unknown> => {
  const response = await axios.httpApi.get("quotations/by_security", { params });
  if (!isValidResponse(response.status))
    console.error(`Error to get Quotations by security: ${response.statusText}`);

  return response.data;
};
const getLogistics = async (data: IParams): Promise<unknown> => {
  const response = await axios.httpApi.post("logistics/get", data);
  if (!isValidResponse(response.status))
    console.error(`Error to get Logistics: ${response.statusText}`);

  return response.data;
};

export const utilsService = {
  getAllCountries,
  getAllStates,
  getAllCities,
  getGrowings,
  getHarvests,
  getMaturationGroups,
  getCurrencies,
  getOutgoingCategories,
  getSubOutgoingType,
  getSaleReason,
  getUnits,
  getQuotationsByDate,
  getQuotationsByArrayOfDate,
  getQuotationsBySecutiry,
  getLogistics,
};
