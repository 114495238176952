import CattleLot from "@/models/cattleLot";
import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";

export interface ICottonFiberIncome {
  revenue_amount: number;
  revenue_pcent: number;
  revenue_value_rs: number;
  revenue_value_us: number;
  sold_amount: number;
  sold_pcent: number;
  sold_rs_arroba: number;
  sold_us_arroba: number;
  sold_value_rs: number;
  sold_value_us: number;
  statement_amount: number;
  statement_rs_arroba: number;
  statement_us_arroba: number;
  statement_value_rs: number;
  statement_value_us: number;
  statement_pcent: number;
  revenue_rs_arroba: number;
  revenue_us_arroba: number;
}

export class CottonFiberIncomeDTO implements ICottonFiberIncome {
  revenue_amount = 0;
  revenue_pcent = 0;
  revenue_value_rs = 0;
  revenue_value_us = 0;
  sold_amount = 0;
  sold_pcent = 0;
  sold_rs_arroba = 0;
  sold_us_arroba = 0;
  sold_value_rs = 0;
  sold_value_us = 0;
  statement_amount = 0;
  statement_rs_arroba = 0;
  statement_us_arroba = 0;
  statement_value_rs = 0;
  statement_value_us = 0;
  statement_pcent = 0;
  revenue_rs_arroba = 0;
  revenue_us_arroba = 0;

  constructor(dto?: ICottonFiberIncome) {
    if(dto && !isEmptyObject(dto)) {
      this.revenue_amount = stringToNumber(dto.revenue_amount);
      this.revenue_pcent = stringToNumber(dto.revenue_pcent);
      this.revenue_value_rs = stringToNumber(dto.revenue_value_rs);
      this.revenue_value_us = stringToNumber(dto.revenue_value_us);
      this.sold_amount = stringToNumber(dto.sold_amount);
      this.sold_pcent = stringToNumber(dto.sold_pcent);
      this.sold_rs_arroba = stringToNumber(dto.sold_rs_arroba);
      this.sold_us_arroba = stringToNumber(dto.sold_us_arroba);
      this.sold_value_rs = stringToNumber(dto.sold_value_rs);
      this.sold_value_us = stringToNumber(dto.sold_value_us);
      this.statement_amount = stringToNumber(dto.statement_amount);
      this.statement_rs_arroba = stringToNumber(dto.statement_rs_arroba);
      this.statement_us_arroba = stringToNumber(dto.statement_us_arroba);
      this.statement_value_rs = stringToNumber(dto.statement_value_rs);
      this.statement_value_us = stringToNumber(dto.statement_value_us);
      this.statement_pcent = stringToNumber(dto.statement_pcent);
      this.revenue_rs_arroba = stringToNumber(dto.revenue_rs_arroba);
      this.revenue_us_arroba = stringToNumber(dto.revenue_us_arroba);
    }
  }
}

export class CottonFiberIncome extends CottonFiberIncomeDTO {
  constructor();
  constructor(cottonFiberIncome: ICottonFiberIncome);
  constructor(cottonFiberIncome?: ICottonFiberIncome) {
    super(cottonFiberIncome);
  }
}

export interface ICottonSeedIncome {
  revenue_amount: number;
  revenue_pcent: number;
  revenue_value_rs: number;
  revenue_value_us: number;
  sold_amount: number;
  sold_pcent: number;
  sold_value_rs: number;
  sold_value_us: number;
  statement_amount: number;
  statement_pcent: number;
  statement_value_rs: number;
  statement_value_us: number;
  revenue_rs_kg: number;
  revenue_us_kg: number;
  sold_rs_kg: number;
  sold_us_kg: number;
  statement_rs_kg: number;
  statement_us_kg: number;
}

export class CottonSeedIncomeDTO implements ICottonSeedIncome {
  revenue_amount = 0;
  revenue_pcent = 0;
  revenue_value_rs = 0;
  revenue_value_us = 0;
  sold_amount = 0;
  sold_pcent = 0;
  sold_value_rs = 0;
  sold_value_us = 0;
  statement_amount = 0;
  statement_pcent = 0;
  statement_value_rs = 0;
  statement_value_us = 0;
  revenue_rs_kg = 0;
  revenue_us_kg = 0;
  sold_rs_kg = 0;
  sold_us_kg = 0;
  statement_rs_kg = 0;
  statement_us_kg = 0;

  constructor(dto?: ICottonSeedIncome) {
    if(dto && !isEmptyObject(dto)) {
      this.revenue_amount = stringToNumber(dto.revenue_amount);
      this.revenue_pcent = stringToNumber(dto.revenue_pcent);
      this.revenue_value_rs = stringToNumber(dto.revenue_value_rs);
      this.revenue_value_us = stringToNumber(dto.revenue_value_us);
      this.sold_amount = stringToNumber(dto.sold_amount);
      this.sold_pcent = stringToNumber(dto.sold_pcent);
      this.sold_value_rs = stringToNumber(dto.sold_value_rs);
      this.sold_value_us = stringToNumber(dto.sold_value_us);
      this.statement_amount = stringToNumber(dto.statement_amount);
      this.statement_pcent = stringToNumber(dto.statement_pcent);
      this.statement_value_rs = stringToNumber(dto.statement_value_rs);
      this.statement_value_us = stringToNumber(dto.statement_value_us);
      this.revenue_rs_kg = stringToNumber(dto.revenue_rs_kg);
      this.revenue_us_kg = stringToNumber(dto.revenue_us_kg);
      this.sold_rs_kg = stringToNumber(dto.sold_rs_kg);
      this.sold_us_kg = stringToNumber(dto.sold_us_kg);
      this.statement_rs_kg = stringToNumber(dto.statement_rs_kg);
      this.statement_us_kg = stringToNumber(dto.statement_us_kg);
    }
  }
}

export class CottonSeedIncome extends CottonSeedIncomeDTO {
  constructor();
  constructor(cottonSeedIncome: ICottonSeedIncome);
  constructor(cottonSeedIncome?: ICottonSeedIncome) {
    super(cottonSeedIncome);
  }
}

export interface IOperationIncome {
  // Soybean and Corn
  revenue_amount?: number;
  revenue_pcent?: number;
  revenue_sc_rs?: number;
  revenue_sc_us?: number;
  revenue_value_rs?: number;
  revenue_value_us?: number;
  sold_amount?: number;
  sold_pcent?: number;
  sold_rs_sc?: number;
  sold_us_sc?: number;
  sold_value_rs?: number;
  sold_value_us?: number;
  statement_amount?: number;
  statement_pcent?: number;
  statement_sc_rs?: number;
  statement_sc_us?: number;
  statement_value_rs?: number;
  statement_value_us?: number;

  // Cattle
  revenue_alive_weight?: number;
  revenue_arroba_amount?: number;
  revenue_arroba_price?: number;
  revenue_total_price?: number;
  revenue_value_by_animal?: number;
  revenue_weight_carcass_arrobas?: number;
  revenue_weight_carcass_kilos?: number;
  revenue_yield_carcass?: number;
  sold_alive_weight?: number;
  sold_arroba_amount?: number;
  sold_arroba_price?: number;
  sold_total_price?: number;
  sold_value_by_animal?: number;
  sold_weight_carcass_arrobas?: number;
  sold_weight_carcass_kilos?: number;
  sold_yield_carcass?: number;
  statement_alive_weight?: number;
  statement_arroba_amount?: number;
  statement_arroba_price?: number;
  statement_total_price?: number;
  statement_value_by_animal?: number;
  statement_weight_carcass_arrobas?: number;
  statement_weight_carcass_kilos?: number;
  statement_yield_carcass?: number;

  // Cotton
  fiber?: CottonFiberIncome;
  seed?: CottonSeedIncome;

  // Cotton SPOT
  sold_rs_arroba?: number;
  sold_us_arroba?: number;
}

export class OperationIncomeDTO implements IOperationIncome {
  revenue_amount: number | undefined = undefined;
  revenue_pcent: number | undefined = undefined;
  revenue_sc_rs: number | undefined = undefined;
  revenue_sc_us: number | undefined = undefined;
  revenue_value_rs: number | undefined = undefined;
  revenue_value_us: number | undefined = undefined;
  sold_amount: number | undefined = undefined;
  sold_pcent: number | undefined = undefined;
  sold_rs_sc: number | undefined = undefined;
  sold_us_sc: number | undefined = undefined;
  sold_value_rs: number | undefined = undefined;
  sold_value_us: number | undefined = undefined;
  statement_amount: number | undefined = undefined;
  statement_pcent: number | undefined = undefined;
  statement_sc_rs: number | undefined = undefined;
  statement_sc_us: number | undefined = undefined;
  statement_value_rs: number | undefined = undefined;
  statement_value_us: number | undefined = undefined;

  revenue_alive_weight: number | undefined = undefined;
  revenue_arroba_amount: number | undefined = undefined;
  revenue_arroba_price: number | undefined = undefined;
  revenue_total_price: number | undefined = undefined;
  revenue_value_by_animal: number | undefined = undefined;
  revenue_weight_carcass_arrobas: number | undefined = undefined;
  revenue_weight_carcass_kilos: number | undefined = undefined;
  revenue_yield_carcass: number | undefined = undefined;
  sold_alive_weight: number | undefined = undefined;
  sold_arroba_amount: number | undefined = undefined;
  sold_arroba_price: number | undefined = undefined;
  sold_total_price: number | undefined = undefined;
  sold_value_by_animal: number | undefined = undefined;
  sold_weight_carcass_arrobas: number | undefined = undefined;
  sold_weight_carcass_kilos: number | undefined = undefined;
  sold_yield_carcass: number | undefined = undefined;
  statement_alive_weight: number | undefined = undefined;
  statement_arroba_amount: number | undefined = undefined;
  statement_arroba_price: number | undefined = undefined;
  statement_total_price: number | undefined = undefined;
  statement_value_by_animal: number | undefined = undefined;
  statement_weight_carcass_arrobas: number | undefined = undefined;
  statement_weight_carcass_kilos: number | undefined = undefined;
  statement_yield_carcass: number | undefined = undefined;

  fiber?: CottonFiberIncome | undefined = new CottonFiberIncome();
  seed?: CottonSeedIncome | undefined = new CottonSeedIncome();
  sold_rs_arroba: number | undefined = undefined;
  sold_us_arroba: number | undefined = undefined;

  constructor(dto?: IOperationIncome) {
    if(dto && !isEmptyObject(dto)) {
      this.revenue_amount = stringToNumberOrUndefined(dto.revenue_amount);
      this.revenue_pcent = stringToNumberOrUndefined(dto.revenue_pcent);
      this.revenue_sc_rs = stringToNumberOrUndefined(dto.revenue_sc_rs);
      this.revenue_sc_us = stringToNumberOrUndefined(dto.revenue_sc_us);
      this.revenue_value_rs = stringToNumberOrUndefined(dto.revenue_value_rs);
      this.revenue_value_us = stringToNumberOrUndefined(dto.revenue_value_us);
      this.sold_amount = stringToNumberOrUndefined(dto.sold_amount);
      this.sold_pcent = stringToNumberOrUndefined(dto.sold_pcent);
      this.sold_rs_sc = stringToNumberOrUndefined(dto.sold_rs_sc);
      this.sold_us_sc = stringToNumberOrUndefined(dto.sold_us_sc);
      this.sold_value_rs = stringToNumberOrUndefined(dto.sold_value_rs);
      this.sold_value_us = stringToNumberOrUndefined(dto.sold_value_us);
      this.statement_amount = stringToNumberOrUndefined(dto.statement_amount);
      this.statement_pcent = stringToNumberOrUndefined(dto.statement_pcent);
      this.statement_sc_rs = stringToNumberOrUndefined(dto.statement_sc_rs);
      this.statement_sc_us = stringToNumberOrUndefined(dto.statement_sc_us);
      this.statement_value_rs = stringToNumberOrUndefined(dto.statement_value_rs);
      this.statement_value_us = stringToNumberOrUndefined(dto.statement_value_us);

      this.revenue_alive_weight = stringToNumberOrUndefined(dto.revenue_alive_weight);
      this.revenue_arroba_amount = stringToNumberOrUndefined(dto.revenue_arroba_amount);
      this.revenue_arroba_price = stringToNumberOrUndefined(dto.revenue_arroba_price);
      this.revenue_total_price = stringToNumberOrUndefined(dto.revenue_total_price);
      this.revenue_value_by_animal = stringToNumberOrUndefined(dto.revenue_value_by_animal);
      this.revenue_weight_carcass_arrobas = stringToNumberOrUndefined(dto.revenue_weight_carcass_arrobas);
      this.revenue_weight_carcass_kilos = stringToNumberOrUndefined(dto.revenue_weight_carcass_kilos);
      this.revenue_yield_carcass = stringToNumberOrUndefined(dto.revenue_yield_carcass);
      this.sold_alive_weight = stringToNumberOrUndefined(dto.sold_alive_weight);
      this.sold_arroba_amount = stringToNumberOrUndefined(dto.sold_arroba_amount);
      this.sold_arroba_price = stringToNumberOrUndefined(dto.sold_arroba_price);
      this.sold_total_price = stringToNumberOrUndefined(dto.sold_total_price);
      this.sold_value_by_animal = stringToNumberOrUndefined(dto.sold_value_by_animal);
      this.sold_weight_carcass_arrobas = stringToNumberOrUndefined(dto.sold_weight_carcass_arrobas);
      this.sold_weight_carcass_kilos = stringToNumberOrUndefined(dto.sold_weight_carcass_kilos);
      this.sold_yield_carcass = stringToNumberOrUndefined(dto.sold_yield_carcass);
      this.statement_alive_weight = stringToNumberOrUndefined(dto.statement_alive_weight);
      this.statement_arroba_amount = stringToNumberOrUndefined(dto.statement_arroba_amount);
      this.statement_arroba_price = stringToNumberOrUndefined(dto.statement_arroba_price);
      this.statement_total_price = stringToNumberOrUndefined(dto.statement_total_price);
      this.statement_value_by_animal = stringToNumberOrUndefined(dto.statement_value_by_animal);
      this.statement_weight_carcass_arrobas = stringToNumberOrUndefined(dto.statement_weight_carcass_arrobas);
      this.statement_weight_carcass_kilos = stringToNumberOrUndefined(dto.statement_weight_carcass_kilos);
      this.statement_yield_carcass = stringToNumberOrUndefined(dto.statement_yield_carcass);

      if(dto.fiber)
        this.fiber = new CottonFiberIncome(dto.fiber);
      if(dto.seed)
        this.seed = new CottonSeedIncome(dto.seed);

      this.sold_rs_arroba = stringToNumberOrUndefined(dto.sold_rs_arroba);
      this.sold_us_arroba = stringToNumberOrUndefined(dto.sold_us_arroba);
    }
  }
}

export class OperationIncome extends OperationIncomeDTO {
  constructor();
  constructor(operationIncomeJSON: IOperationIncome);
  constructor(operationIncome?: IOperationIncome) {
    super(operationIncome);
  }
}

export interface IIncomeFormFunc {
  callFromProfit?: () => void;
}