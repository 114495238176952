// ##############
// Cache local
// ##############
import { addDays } from "./utilsDate";

export function checkCacheExpiryVersion(key: string, version: string): any | undefined {
  const value = localStorage.getItem(key);
  if (value) {
    const obj = JSON.parse(value);
    const now = new Date();
    if (
      !obj.version ||
      version.localeCompare(obj.version) !== 0 ||
      now.getTime() > obj.expiry
    ) {
      localStorage.removeItem(key);
      return undefined;
    }
    return obj.value;
  }
}

export function setCacheItem(key: string, value: any, version = undefined, expiryDays = 7): void {
  const expiry = addDays(expiryDays);

  localStorage.setItem("cache_" + key, JSON.stringify(
    {
      expiry: expiry,
      version: version,
      value: value,
    }
  ));
}

export function removeLocalStoreCache(): void {
  const user: string | null = localStorage.getItem("USER");
  const token: string | null = localStorage.getItem("USER_TOKEN");

  // Limpa todo local storage
  localStorage.clear();

  // Set user e token
  // para não precisar autenticar de novo
  if(user && token) {
    localStorage.setItem("USER", user);
    localStorage.setItem("USER_TOKEN", token);
  }
}