import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface IHarvest extends IModelBase {
  name: string;
  begin_date: string;
  end_date: string;
  is_current: boolean;
  is_future: boolean;
  is_deleted: boolean;
  deleted_date?: string;
  ids?: number[];
}

export class HarvestDTO extends ModelBase implements IHarvest {
  name = "";
  begin_date = "";
  end_date = "";
  is_current = false;
  is_future = false;
  is_deleted = false;
  deleted_date: string | undefined = undefined;
  ids?: number[] = [];

  constructor(dto?: IHarvest) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.begin_date = dto.begin_date;
      this.end_date = dto.end_date;
      this.is_current = dto.is_current;
      this.is_future = dto.is_future;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto?.deleted_date;
      this.ids = dto?.ids;
    }
  }
}

export default class Harvest extends HarvestDTO {
  private static resource = "harvest";

  constructor();
  constructor(harvest: IHarvest);
  constructor(harvest?: IHarvest) {
    super(harvest);
  }
  public static getResource(): string {
    return this.resource;
  }
  /* getters */
  get isSpot(): boolean {
    return !this.is_current && !this.is_future;
  }
}

interface IScreenHarvest {
  period: string;
}

export class ScreenHarvest extends Harvest implements IScreenHarvest{
  period = "";

  constructor(harvest: Harvest, period: string) {
    super(harvest);
    this.period = period;
  }
}