import { isEmptyObject, stringToNumber, stringToNumberOrUndefined } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface ICottonConfigurations extends IModelBase {
  [keys: string]: number | string | undefined;

  seed_price: number;
  seed_efficiency: number;
  fiber_efficiency: number;
  harvest_id?: number;
  ownership_id?: number;
}

export interface ICottonConfigurationsDTO extends IModelBase {
  seed_price: number;
  seed_efficiency: number;
  fiber_efficiency: number | string;
  harvest_id?: number;
  ownership_id?: number;
}

export class CottonConfigurationsDTO extends ModelBase implements ICottonConfigurations {
  [keys: string]: number | string | undefined;

  seed_price = 0;
  seed_efficiency = 0;
  fiber_efficiency = 0;
  harvest_id: number | undefined = undefined;
  ownership_id: number | undefined = undefined;

  constructor(dto?: ICottonConfigurationsDTO) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.seed_price = stringToNumber(dto.seed_price);
      this.seed_efficiency = stringToNumber(dto.seed_efficiency);
      this.fiber_efficiency = stringToNumber(dto.fiber_efficiency);
      this.harvest_id = stringToNumberOrUndefined(dto.harvest_id);
      this.ownership_id = stringToNumberOrUndefined(dto.ownership_id);
    }
  }
}

export default class CottonConfigurations extends CottonConfigurationsDTO {
  constructor();
  constructor(cottonConfigurations: ICottonConfigurations);
  constructor(cottonConfigurations?: ICottonConfigurations) {
    super(cottonConfigurations);
  }
}
