import { UPDATE_CLIENT, AGR_ALERT } from "@/store/actions-types";
import { SHOW_SNACKBAR, SET_CLIENT } from "@/store/mutations-types";
import { version } from "@/../package.json";
import { AppGetterTypes, IAppModule, IRootState } from "@/types/store-types";
import { ActionTree, GetterTree, MutationTree } from "vuex";

// Shorthands for easier type building
type S = IAppModule;
type R = IRootState;

export interface ISnackbar {
  message: string;
  show: boolean;
  timeout?: number;
  important: boolean;
  color?: string;
  href?: string;
}

class State implements IAppModule {
  snackbar = {
    message: "",
    show: false,
    timeout: undefined,
    important: false,
    color: undefined,
    href: undefined,
  };
  packageVersion = version;
  client = undefined;
}

const mutations: MutationTree<S> = {
  [SHOW_SNACKBAR]: (state, payload) => {
    if (state.snackbar.show) {
      state.snackbar.show = false;
    }
    setTimeout(() => {
      if (typeof payload == "string") {
        state.snackbar.message = payload;
        state.snackbar.color = "info";
        state.snackbar.href = undefined;
        state.snackbar.timeout = undefined;
        state.snackbar.important = false;
        state.snackbar.show = true;
      } else {
        state.snackbar.message = payload.message;
        state.snackbar.color = payload.color;
        state.snackbar.href = payload.href;
        state.snackbar.show = true;
        state.snackbar.timeout =
          payload.timeout != undefined ? payload.timeout : undefined;
        state.snackbar.important = payload.important
          ? payload.important
          : false;
      }
    }, 500);
  },
  [SET_CLIENT]: (state, client) => {
    state.client = client;
  },
};

const actions: ActionTree<S, R> = {
  [UPDATE_CLIENT]: ({ commit }, client) => {
    commit(SET_CLIENT, client);
  },
  [AGR_ALERT]: ({ commit }, payload) => {
    commit(SHOW_SNACKBAR, payload);
  },
};

const getters: GetterTree<S, R> = {
  [AppGetterTypes.getSnackbarInstance](state) {
    return state.snackbar;
  },
  [AppGetterTypes.appVersion](state) {
    return state.packageVersion;
  },
  [AppGetterTypes.getClient](state) {
    return state.client;
  },
};

export default {
  state: new State(),
  actions,
  mutations,
  getters,
};
