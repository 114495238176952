import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import City from "./city";
import User from "./user";

export interface IOwnership extends IModelBase {
  name: string;
  car_code?: string;
  polygon?: string;
  deal_soybean_reference: string;
  deal_corn_reference: string;
  deal_cotton_reference: string;
  is_deleted: boolean;
  deleted_date?: string;
  city_id: number;
  city_reference_id?: number;
  user_id: number;

  /**Relationship classes */
  city?: City;
  city_reference?: City;
  user?: User;
}

export class OwnershipDTO extends ModelBase implements IOwnership {
  [keys: string]: number | string | boolean | City | User | undefined;

  name = "";
  car_code?: string | undefined = undefined;
  polygon?: string | undefined = undefined;
  deal_soybean_reference = "03";
  deal_corn_reference = "09";
  deal_cotton_reference = "12";
  is_deleted = false;
  deleted_date?: string | undefined = undefined;
  city_id = 0;
  city_reference_id?: number | undefined = undefined;
  user_id = 0;

  city = new City();
  city_reference: City | undefined = undefined;
  user = new User();

  constructor(dto?: IOwnership) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.car_code = dto?.car_code;
      this.polygon = dto?.polygon;
      this.deal_soybean_reference = dto.deal_soybean_reference;
      this.deal_corn_reference = dto.deal_corn_reference;
      this.deal_cotton_reference = dto.deal_cotton_reference;
      this.is_deleted = dto.is_deleted;
      this.deleted_date = dto?.deleted_date;
      this.city_id = dto.city_id;
      this.city_reference_id = dto?.city_reference_id;
      this.user_id = dto.user_id;

      if(dto.city)
        this.city = new City(dto.city);
      if(dto.user)
        this.user = new User(dto.user);
      if(dto.city_reference)
        this.city_reference = new City(dto.city_reference);
    }
  }
}

export default class Ownership extends OwnershipDTO {
  private static resource = "ownership";

  constructor();
  constructor(ownership: IOwnership);
  constructor(ownership?: IOwnership) {
    super(ownership);
  }

  public static getResource(): string {
    return this.resource;
  }
}
