import { isEmptyObject, stringToNumber } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface ITradeslipDeliveryPeriod extends IModelBase {
  ref: string;
  ref_period: string;
  start_date: string;
  end_date: string;
  amount: number;
  daily_cadence?: number;
  tradeslip_id: number;
}

export class TradeslipDeliveryPeriodDTO extends ModelBase implements ITradeslipDeliveryPeriod {
  ref = "";
  ref_period = "";
  start_date = "";
  end_date = "";
  amount = 0;
  daily_cadence: number | undefined = undefined;
  tradeslip_id = 0;

  constructor(dto?: ITradeslipDeliveryPeriod) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.ref = dto.ref;
      this.ref_period = dto.ref_period;
      this.start_date = dto.start_date;
      this.end_date = dto.end_date;
      this.amount = stringToNumber(dto.amount);
      this.daily_cadence = dto.daily_cadence;
      this.tradeslip_id = dto.tradeslip_id;
    }
  }
}

export default class TradeslipDeliveryPeriod extends TradeslipDeliveryPeriodDTO {
  private static resource = "tradeslip_delivery_period";

  constructor();
  constructor(tradeslipDeliveryPeriod: ITradeslipDeliveryPeriod);
  constructor(tradeslipDeliveryPeriod?: ITradeslipDeliveryPeriod) {
    super(tradeslipDeliveryPeriod);
  }

  public static getResource(): string {
    return this.resource;
  }
}
