import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Unit from "./unit";

export interface ITradeslipPricingComponent extends IModelBase {
  name?: string;
  identifier?: string;
  fixed_in: string;
  allow_negative: boolean;
  unit_id?: number;

  /**Relationship classes */
  unit?: Unit;
}

export class TradeslipPricingComponentDTO
  extends ModelBase
  implements ITradeslipPricingComponent
{
  name?: string | undefined = undefined;
  identifier: string | undefined = undefined;
  fixed_in = "";
  allow_negative = false;
  unit_id: number | undefined = undefined;

  unit: Unit | undefined = undefined;

  constructor(dto?: ITradeslipPricingComponent) {
    super(dto);
    if (dto && !isEmptyObject(dto)) {
      this.name = dto.name;
      this.identifier = dto.identifier;
      this.fixed_in = dto.fixed_in;
      this.allow_negative = dto.allow_negative;
      this.unit_id = dto.unit_id;

      if (dto.unit) this.unit = new Unit(dto.unit);
    }
  }
}

export default class TradeslipPricingComponent extends TradeslipPricingComponentDTO {
  private static resource = "tradeslip_pricing_component";

  constructor();
  constructor(tradeslipPricingComponent: ITradeslipPricingComponent);
  constructor(tradeslipPricingComponent?: ITradeslipPricingComponent) {
    super(tradeslipPricingComponent);
  }

  public static getResource(): string {
    return this.resource;
  }
}
