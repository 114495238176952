import { isEmptyObject, stringToNumber } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";
import Growing from "./growing";
import Harvest from "./harvest";
import Ownership from "./ownership";

export interface ISaleTrigger extends IModelBase {
  margin: number;
  trigger_type: string;
  to_sell: number;
  active: boolean;
  sent: boolean;
  last_sent_date?: string;
  month_code_reference: string;
  ownership_id: number;
  harvest_id?: number;
  growing_id: number;
  cattle_lot_id?: number;

  /**Relationship classes */
  ownership?: Ownership;
  growing?: Growing;
  harvest?: Harvest;
}

export class SaleTriggerDTO extends ModelBase implements ISaleTrigger {
  margin = 0;
  trigger_type = "";
  to_sell = 0;
  active = true;
  sent = false;
  last_sent_date: string | undefined = undefined;
  month_code_reference = "";
  ownership_id = 0;
  harvest_id: number | undefined = undefined;
  growing_id = 0;
  cattle_lot_id: number | undefined = undefined;

  ownership = new Ownership();
  growing = new Growing();
  harvest: Harvest | undefined = undefined;

  constructor(dto?: ISaleTrigger) {
    super(dto);
    if(dto && !isEmptyObject(dto)) {
      this.margin = stringToNumber(dto.margin);
      this.to_sell = stringToNumber(dto.to_sell);
      this.trigger_type = dto.trigger_type;
      this.active = dto.active;
      this.sent = dto.sent;
      this.last_sent_date = dto?.last_sent_date;
      this.month_code_reference = dto.month_code_reference;
      this.ownership_id = dto.ownership_id;
      this.harvest_id = dto?.harvest_id;
      this.growing_id = dto.growing_id;
      this.cattle_lot_id = dto?.cattle_lot_id;

      if(dto.ownership)
        this.ownership = new Ownership(dto.ownership);
      if(dto.growing)
        this.growing = new Growing(dto.growing);
      if(dto.harvest)
        this.harvest = new Harvest(dto.harvest);
    }
  }
}

export default class SaleTrigger extends SaleTriggerDTO {
  private static resource = "sale_trigger";

  constructor();
  constructor(saleTrigger: ISaleTrigger);
  constructor(saleTrigger?: ISaleTrigger){
    super(saleTrigger);
  }

  public static getResource(): string {
    return this.resource;
  }
}

interface ISaleTriggerScreen {
  name: string;
  goal?: string;
  exchange?: string;
  goalFob?: string;
  goalExwCbot?: string;
  goalExwB3?: string;
  goalExw?: string;
  exchangeFob?: string;
  exchangeExw?: string;
}

export class SaleTriggerScreen extends SaleTrigger implements ISaleTriggerScreen {
  name = "";
  goal: string | undefined = undefined;
  exchange: string | undefined = undefined;
  goalFob: string | undefined = undefined;
  goalExwCbot: string | undefined = undefined;
  goalExwB3: string | undefined = undefined;
  goalExw: string | undefined = undefined;
  exchangeFob: string | undefined = undefined;
  exchangeExw: string | undefined = undefined;

  constructor(
    saleTrigger: SaleTrigger,
    name: string,
    goal?: string,
    exchange?: string,
    goalFob?: string,
    goalExwCbot?: string,
    goalExwB3?: string,
    goalExw?: string,
    exchangeFob?: string,
    exchangeExw?: string,
    ) {
    super(saleTrigger);
    this.name = name;
    this.goal = goal;
    this.exchange = exchange;
    this.goalFob = goalFob;
    this.goalExwCbot = goalExwCbot;
    this.goalExwB3 = goalExwB3;
    this.goalExw = goalExw;
    this.exchangeFob = exchangeFob;
    this.exchangeExw = exchangeExw;
  }
}