// ###############
// Date functions
// ###############
import { DateTime } from "luxon";
import i18n from "./plugins/i18n";

/**Func para formatar uma data
 * no formato 00/00/0000
 */
export function formatDate(date: string): string | undefined {
  if (!date) return undefined;

  const [year, month, dayIso] = date.split("-");
  const day = dayIso.substr(0, 2);
  return `${day}/${month}/${year}`;
}

/**Func para voltar a data no formato ISO
 * yyyy:mm:dd 00:00:00
 */
export function unformatDate(formatedDate: string): string | undefined {
  if (!formatedDate) return undefined;

  const [day, month, year] = formatedDate.split("/");
  // mes no javascript começa no 0 --FUCK!!--
  const javascriptMonth = parseInt(month) - 1;
  return new Date(parseInt(year), javascriptMonth, parseInt(day)).toISOString();
}

export function convertMonthName(number: string): string {
  return i18n.t(numberToMonthName(number)).toString();
}

export function getMonth(date: string): string {
  if (date.indexOf("-") > -1) {
    const [, month] = date.split("-");
    date = month;
  }
  return date;
}

export function getDateFromMonth(month: string, year: string): string {
  let nMonth = undefined;
  let nYear = undefined;
  if (month.indexOf("-") > -1) {
    nMonth = parseInt(getMonth(month)) - 1;
  } else {
    nMonth = parseInt(month) - 1;
  }

  if (year) {
    nYear = parseInt(year);
  } else {
    nYear = new Date().getFullYear();
  }

  return new Date(nYear, nMonth, 1).toISOString().substr(0, 10);
}

export function formatDateMonth(date: string): string | undefined {
  if (!date) return undefined;

  if (date.indexOf("-") > -1) {
    date = getMonth(date);
  }

  return numberToMonthName(date);
}

export function addDays(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

export function addMonth(date: string, month = 1): string {
  return DateTime.fromISO(new Date(date).toISOString(), { locale: "pt-br" })
    .plus({ months: month })
    .toString()
    .substr(0, 10);
}

export function dateISONotNull(date: string, time = true): string {
  // Checa se a data é null
  // e retorna no formato ISO para salvar
  let returnDate = undefined;
  if (date) returnDate = new Date(date);
  else returnDate = new Date();

  if (time) return returnDate.toISOString();
  else return returnDate.toISOString().substr(0, 10);
}

export function convertDateToISO(date: string): string {
  const isoDate = new Date(date);
  return isoDate.toISOString();
}

export function numberToMonthName(number: string): string {
  switch (number) {
    case "01":
      return "month1";
    case "02":
      return "month2";
    case "03":
      return "month3";
    case "04":
      return "month4";
    case "05":
      return "month5";
    case "06":
      return "month6";
    case "07":
      return "month7";
    case "08":
      return "month8";
    case "09":
      return "month9";
    case "10":
      return "month10";
    case "11":
      return "month11";
    case "12":
      return "month12";
    default:
      return "nomonth";
  }
}

export function getMonthName(): Array<string> {
  return [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ]
}