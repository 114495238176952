import {
  LOAD_ALL_RESOURCES,
  MAKE_HARVEST_TURN
} from "@/store/actions-types";

import {
  SET_ALL_HARVESTS,
  SET_ALL_GROWINGS,
  SET_ALL_UNITS,
  UPDATE_ADMIN_RESOURCES
} from "@/store/mutations-types";

import { adminService } from "@/services/admin";
import { AdminGetterTypes, IAdminModule, IRootState } from "@/types/store-types";

import Harvest from "@/models/harvest";
import Unit from "@/models/unit";
import Growing from "@/models/growing";
import { ActionTree, GetterTree, MutationTree } from "vuex";

// Shorthands for easier type building
type S = IAdminModule;
type R = IRootState;

type PayloadUpdateAdminResource = {
  item: Harvest | Growing | Unit;
}

class State implements IAdminModule {
  allHarvests: Array<Harvest> = [];
  allGrowings: Array<Growing> = [];
  allUnits: Array<Unit> = [];
}

const mutations: MutationTree<S> = {
  [SET_ALL_HARVESTS]: (state, harvests) => {
    state.allHarvests = harvests;
  },
  [SET_ALL_GROWINGS]: (state, growings) => {
    state.allGrowings = growings;
  },
  [SET_ALL_UNITS]: (state, units) => {
    state.allUnits = units;
  },
  [UPDATE_ADMIN_RESOURCES]: (state, payload: PayloadUpdateAdminResource) => {
    if(payload.item instanceof Harvest) {
      const index = state.allHarvests.findIndex(element => element.id === payload.item.id);
      if(index > -1)
        state.allHarvests.splice(index, 1, payload.item);
      else
        state.allHarvests.push(payload.item);
    }else if(payload.item instanceof  Growing) {
      const index = state.allGrowings.findIndex(element => element.id === payload.item.id);
      if(index > -1)
        state.allGrowings.splice(index, 1, payload.item);
      else
        state.allGrowings.push(payload.item);
      }else {
        const index = state.allUnits.findIndex(element => element.id === payload.item.id);
        if(index > -1)
          state.allUnits.splice(index, 1, payload.item);
        else
          state.allUnits.push(payload.item);
    }
  },
}

const actions: ActionTree<S, R> = {
  [LOAD_ALL_RESOURCES]: async ({commit}) => {
    commit(SET_ALL_HARVESTS, await adminService.getAllHarvests());
    commit(SET_ALL_GROWINGS, await adminService.getAllGrowings());
    commit(SET_ALL_UNITS, await adminService.getAllUnits());
  },
  [MAKE_HARVEST_TURN]: async (_, scenery) => {
    try {
      await adminService.makeHarvestTurn(scenery);
    } catch (error) {
      console.error(error);
    }
  },
}

/* Mapping of our getters */
const getters: GetterTree<S, R> = {
  [AdminGetterTypes.getAllHarvests](state) {
    return state.allHarvests;
  },
  [AdminGetterTypes.getAllGrowings](state) {
    return state.allGrowings
  },
  [AdminGetterTypes.getAllUnits](state) {
    return state.allUnits;
  }
}

export default {
  state: new State(),
  actions,
  mutations,
  getters,
};