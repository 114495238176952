
import Harvest from "@/models/harvest";
import Vue from "vue";

export default Vue.extend({
  props: {
    selected: {
      type: [Harvest, Object],
      default: new Harvest(),
    },
    color: {
      type: String,
      default: "#066f6a",
    },
    desableCard: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    oneBtnEnable: {
      type: Boolean,
      default: false,
    },
    actualName: {
      type: String,
      default: "-/-",
    },
    futureName: {
      type: String,
      default: "-/-",
    },
  },
});
