import { isEmptyObject } from "@/utilsObject";
import { IModelBase, ModelBase } from "./base";

export interface IUser extends IModelBase {
  email: string;
  name: string;
  active: boolean;
  profile_photo_url?: string;
  reload_token: boolean;
  roles: Array<string>;
  is_first_access: boolean;
  isAnonymous: boolean;
  country_code: number;
  phone_number: string;
  send_email: boolean;
  send_whats: boolean;
  organization?: string;
}

export class UserDTO extends ModelBase implements IUser {
  email = "";
  name = "";
  active = true;
  profile_photo_url?: string | undefined = undefined;
  reload_token = false;
  roles: Array<string> = [];
  is_first_access = false;
  isAnonymous = false;
  country_code = 55;
  phone_number = "";
  send_email = false;
  send_whats = false;
  organization?: string | undefined = undefined;

  constructor(dto?: IUser) {
    super(dto);
    if (dto && !isEmptyObject(dto)) {
      this.email = dto.email;
      this.name = dto.name;
      this.active = dto.active;
      this.profile_photo_url = dto?.profile_photo_url;
      this.reload_token = dto.reload_token;
      this.roles = dto.roles;
      this.is_first_access = dto.is_first_access;
      this.isAnonymous = dto.isAnonymous;
      this.country_code = dto.country_code;
      this.phone_number = dto.phone_number;
      this.send_email = dto.send_email;
      this.send_whats = dto.send_whats;
      this.organization = dto?.organization;

    }
  }
}

export default class User extends UserDTO {
  private static resource = "user";

  constructor();
  constructor(user: IUser);
  constructor(user?: IUser) {
    super(user);
  }
  public static getResource(): string {
    return this.resource;
  }
  /* getters */
  get userRoles(): Array<string> {
    return this.roles;
  }
  get userRole(): string {
    return this.roles[0];
  }
  get isN1(): boolean {
    if (this.userRole === "N1") {
      return true;
    }
    return false;
  }
}

interface IUserExtraInfo {
  ownerships?: number;
  sale_triggers?: number;
  selected?: boolean;
}
export class DisplayUsers extends User implements IUserExtraInfo {
  ownerships = 0;
  sale_triggers = 0;
  selected = false;

  constructor(
    user: User,
    _ownerships: number,
    _sale_triggers: number,
    _select: boolean
  ) {
    super(user);
    this.ownerships = _ownerships;
    this.sale_triggers = _sale_triggers;
    this.selected = _select;
  }
}
